import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import UserDetailsTable from './components/UserDetailsTable';
import ApexAccountOverviewTable from './components/ApexAccountOverviewTable';
import AchRelationshipsTable from './components/AchRelationshipsTable';
import ApexAccountEntitlementsTable from './components/ApexAccountEntitlementsTable';
import CustomerProfileTable from './components/CustomerProfileTable';
import SuitabilityInfoTable from './components/SuitabilityInfoTable';
import FinancialProfileTable from './components/FinancialProfileTable';
import TrustedContactTable from './components/TrustedContactTable';
import IdentityDocumentsTable from './components/IdentityDocumentsTable';
import CloseAccountButton from './components/CloseAccountButton';
import CashBalanceUpdateForm from './components/CashBalanceUpdateForm';
import PositionBalanceUpdateForm from './components/PositionBalanceUpdateForm';

export default function AccountDetails() {
  const { accountNumber } = useParams();

  const tables = [
    { Component: UserDetailsTable, id: 'user-details' },
    { Component: CashBalanceUpdateForm, id: 'cash-balance-update' },
    { Component: PositionBalanceUpdateForm, id: 'position-balance-update' },
    { Component: ApexAccountOverviewTable, id: 'apex-account-overview' },
    { Component: AchRelationshipsTable, id: 'ach-relationships' },
    {
      Component: ApexAccountEntitlementsTable,
      id: 'apex-account-entitlements',
    },
    { Component: CustomerProfileTable, id: 'customer-profile' },
    { Component: SuitabilityInfoTable, id: 'suitability-info' },
    { Component: FinancialProfileTable, id: 'financial-profile' },
    { Component: TrustedContactTable, id: 'trusted-contact' },
    { Component: IdentityDocumentsTable, id: 'identity-documents' },
  ];

  return (
    <Container fluid className="content">
      <Row className="justify-content-center">
        <Col md={12}>
          <h2 className="text-center">Account Details</h2>
          <CloseAccountButton accountNumber={accountNumber} />
        </Col>

        {tables.map(({ Component, id }) => (
          <div
            key={id}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              paddingLeft: '5%',
              paddingRight: '5%',
            }}
          >
            <Row className="mt-5">
              <Component accountNumber={accountNumber} />
            </Row>
          </div>
        ))}
      </Row>
    </Container>
  );
}
