/* eslint-disable no-console */
import { APIRequestWithAuth } from './config';


class UserService {
    static async getUserAumByUserId (userId) {
        console.log('getUserAumByUserId called by Admin Dash');
        try {
            const data = await APIRequestWithAuth('GET', `/v1/getUserAumByUserId/${userId}`);
            if (data) {
            return data;
            }
            console.log('getUserAumByUserId returned a null response');
            return null;
        } catch (error) {
            console.error('Error fetching users >> getUserAumByUserId (frontend > UserService.js):', error);
            return null;
        }
    };

    static async getAllUsersWithAccount () {
        console.log('getAllUsersWithAccount called by Admin Dash');
        try {
            const data = await APIRequestWithAuth('GET', '/v1/getAllUsersWithAccount');
            if (data) {
            return data;
            }
            console.log('getAllUsersWithAccount returned a null response');
            return null;
        } catch (error) {
            console.error('Error fetching users >> getAllUsersWithAccount (frontend > UserService.js):', error);
            return null;
        }
    };

    static async getAllUsersWithActiveAccount () {
        try {
            const data = await APIRequestWithAuth('GET', '/v1/getAllUsersWithActiveAccount');
            console.log('getAllUsersWithActiveAccount data:', data);
            if (data) {
            console.log('getAllUsersWithActiveAccount successfully returned the following data to the frontend');
            return data;
            }
            console.log('getAllUsersWithActiveAccount returned a null response');
            return null;
        } catch (error) {
            console.log('Unhandled error fetching users >> getAllUsersWithActiveAccount (frontend > UserService.js)');
            return null;
        }
    };

    static async getAllUserIdsWithActiveAccount () {
        console.log('getAllUserIdsWithActiveAccount called by Admin Dash');
        try {
            const data = await APIRequestWithAuth('GET', '/v1/getAllUserIdsWithActiveAccount');
            if (data) {
            return data;
            }
            console.log('getAllUserIdsWithActiveAccount returned a null response');
            return null;
        } catch (error) {
            console.error('Error fetching users >> getAllUserIdsWithActiveAccount (frontend > UserService.js):', error);
            return null;
        }
    };

    static async getAllAccountsForUser (userId) {
        console.log('getAllAccountsForUser called by Admin Dash with userId:', userId);
        try {
            const data = await APIRequestWithAuth('GET', `/v1/getAllAccountsForUser/${userId}`);
            if (data) {
            return data;
            }
            console.log('getAllAccountsForUser returned a null response');
            return null;
        } catch (error) {
            console.error('Error fetching users >> getAllAccountsForUser (frontend > UserService.js):', error);
            return null;
        }
    };

}
export default UserService;