/* eslint-disable import/no-unresolved */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useMemo } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getAllApexWithSketch } from 'Services/AccountServices';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

function Applications() {
  const navigate = useNavigate();

  const handlePress = useCallback(
    (requestid, accountNum) => {
      navigate(`/applications/${requestid}`, { state: { accountNum } });
    },
    [navigate]
  );

  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getAllApexWithSketch'],
    queryFn: getAllApexWithSketch,
  });

  function handleRefresh() {
    refetch();
  }

  const columns = useMemo(
    () => [
      {
        field: 'account_id',
        headerName: 'Account ID',
        width: 150,
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        width: 150,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        width: 150,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 150,
      },
      {
        field: 'requestid',
        headerName: 'Sketch Request ID',
        width: 150,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 150,
      },
      {
        field: 'accountnumber',
        headerName: 'Account Number',
        width: 150,
      },
      {
        field: 'account_status',
        headerName: 'Overall Status',
        width: 150,
      },
      {
        field: 'originaldatetime',
        headerName: 'Date Submitted',
        width: 150,
        renderCell: params =>
          moment(params.row.originaldatetime).format('MM/DD/YYYY HH:mm:ss A'),
      },
      {
        field: 'view',
        headerName: 'View',
        width: 150,
        renderCell: params => (
          <Button
            onClick={() =>
              handlePress(params.row.requestid, params.row.accountnumber)
            }
            color="#000000"
            size="sm"
          >
            <i className="tim-icons icon-double-right" />
          </Button>
        ),
      },
    ],
    [handlePress]
  );

  return (
    <Container fluid className="content">
      <Col md={12}>
        <h2 className="text-center">Applications</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              <DataGrid
                rows={data}
                loading={isLoading}
                getRowId={row => row.account_id}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Applications;
