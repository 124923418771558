/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Container,
  Alert,
} from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
  getApexInvestigationDetails,
  getInternalInvestigationHistory,
  getUserInfoByApexAccountNumber,
  getIdentityDocsByAccountNumber,
  updateInternalInvestigationState,
  manuallyApproveIndeterminateInvestigation,
} from 'Services/AccountServices';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import SimpleTable from '../Components/SortingTable/SortingTable';
import IdentityTable from 'Screens/Components/IdentityTable/IdentityTable';

const alertBodies = {
  updateInvestigationState: {
    warning: 'Are you sure you want to update the investigation state?',
    success: 'Investigation state updated successfully!',
    loading: 'Please wait...',
  },
  manuallyApproveIndeterminateInvestigation: {
    warning:
      'Are you sure you want to manually approve the indeterminate investigation?',
    success: 'Indeterminate investigation manually approved!',
    loading: 'Please wait...',
  },
};

export default function ApplicationDetails() {
  const { requestid } = useParams();
  const location = useLocation();
  const { accountNum } = location.state;
  const [alert, setAlert] = React.useState(null);
  const [summaryResultsTable, setSummaryResultsTable] = React.useState(null);
  const [identityRequestTable, setIdentityRequestTable] = React.useState(null);
  const [identityResultStateTable, setIdentityResultStateTable] =
    React.useState(null);
  const [identityResultStateDetailsTable, setIdentityResultStateDetailsTable] =
    React.useState(null);
  const [sanctionsNewsProfilesTable, setSanctionsNewsProfilesTable] =
    React.useState(null);
  const [dndbProfilesTable, setDndbProfilesTable] = React.useState(null);
  const [cipCategoriesTable, setCipCategoriesTable] = React.useState(null);
  const [investigationDropDownValue, setInvestigationDropDownValue] =
    React.useState('');

  const navigate = useNavigate();

  const hideAlert = () => {
    setAlert(null);
  };

  const handlePressNewsProfiles = () => {
    navigate(`/applications/${requestid}/sanctionsnewsprofiles`);
  };
  const handlePressDNDBProfiles = () => {
    navigate(`/applications/${requestid}/dndbprofiles`);
  };
  const handlePressCIPCategories = () => {
    navigate(`/applications/${requestid}/cipdetails`, {
      state: { accountNumber: identityRequestTable.account },
    });
  };

  const getApexInvestigationDetailsQuery = useQuery({
    queryKey: ['getApexInvestigationDetails', requestid],
    queryFn: () => getApexInvestigationDetails(requestid),
  });

  useEffect(() => {
    if (getApexInvestigationDetailsQuery.isSuccess) {
      const { data } = getApexInvestigationDetailsQuery;
      const summaryResults = Object.keys(data.summaryResults).map(
        checkType => ({
          checkType,
          status: data.summaryResults[checkType],
        })
      );
      setSummaryResultsTable(summaryResults);

      setIdentityRequestTable(data.identityVerificationDetailsRequest);
      const identityResultState = [
        {
          Field: 'Overall Investigation State',
          Value:
            data.identityVerificationDetailsResultState
              .overallInvestigationState,
        },
        {
          Field: 'Evaluated State',
          Value: data.identityVerificationDetailsResultState.evaluatedState,
        },
        {
          Field: 'Data Sources',
          Value: data.identityVerificationDetailsResultState.dataSources,
        },
        {
          Field: 'Verification State',
          Value: data.identityVerificationDetailsResultState.verificationState,
        },
        {
          Field: 'Verification Reasons',
          Value: Object.entries(
            data.identityVerificationDetailsResultState.verificationReasons
          )
            .map(([key, value]) => `${key}: ${value}`)
            .join('; '),
        },
      ];
      setIdentityResultStateTable(identityResultState);

      const identityResultStateDetails = [
        {
          Field: 'Reject Reasons',
          Value: Object.values(
            data.identityResultStateDetails.rejectReasons
          ).join('; '),
        },
        {
          Field: 'Accept',
          Value:
            Object.keys(data.identityResultStateDetails.accept).length > 0
              ? 'Yes'
              : 'No',
        },
        {
          Field: 'Indeterminate',
          Value:
            Object.keys(data.identityResultStateDetails.indeterminate).length >
            0
              ? 'Yes'
              : 'No',
        },
        {
          Field: 'Error Code',
          Value: data.identityResultStateDetails.errorCode ?? 'N/A',
        },
        {
          Field: 'Error Message',
          Value: data.identityResultStateDetails.errorMessage ?? 'N/A',
        },
      ];
      setIdentityResultStateDetailsTable(identityResultStateDetails);

      setSanctionsNewsProfilesTable(data.sanctionsNewsProfiles);

      setDndbProfilesTable(data.dndbProfiles);

      setCipCategoriesTable(data.cipCategoriesResults);
    }
  }, [getApexInvestigationDetailsQuery]);

  const getInternalInvestigationQuery = useQuery({
    queryKey: ['getInternalInvestigation', accountNum],
    queryFn: () => getInternalInvestigationHistory(accountNum),
  });
  const handleRefreshInternalHistory = () => {
    getInternalInvestigationQuery.refetch();
  };

  const { data: userInfo } = useQuery({
    queryKey: ['getUser', accountNum],
    queryFn: () => getUserInfoByApexAccountNumber(accountNum),
  });

  const { data: allIdentityDocumentsTable } = useQuery({
    queryKey: ['getIdentityDocs', accountNum],
    queryFn: () => getIdentityDocsByAccountNumber(accountNum),
  });

  const updateInvestigationMutation = useMutation({
    mutationFn: updateInternalInvestigationState,
    onSuccess: () => {
      getInternalInvestigationQuery.refetch();
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          {alertBodies.updateInvestigationState.success}
        </ReactBSAlert>
      );
    },
    onError: error => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          {error.message || error}
        </ReactBSAlert>
      );
    },
    onMutate: () => {
      setAlert(
        <ReactBSAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Please Wait"
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          {alertBodies.updateInvestigationState.loading}
        </ReactBSAlert>
      );
    },
  });

  const manuallyApproveIndeterminateInvestigationMutation = useMutation({
    mutationFn: manuallyApproveIndeterminateInvestigation,
    onSuccess: () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          {alertBodies.manuallyApproveIndeterminateInvestigation.success}
        </ReactBSAlert>
      );
      getApexInvestigationDetailsQuery.refetch();
      getInternalInvestigationQuery.refetch();
    },
    onError: error => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          {error.message || error}
        </ReactBSAlert>
      );
    },
    onMutate: () => {
      setAlert(
        <ReactBSAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title="Please Wait"
          onConfirm={() => hideAlert()}
          showConfirm={false}
        >
          {alertBodies.manuallyApproveIndeterminateInvestigation.loading}
        </ReactBSAlert>
      );
    },
  });

  const warningWithConfirmAndCancelMessage = (mutation, body, alertType) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => mutation.mutate(body)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        disabled={mutation.isLoading}
        showCancel
        btnSize=""
      >
        {alertBodies[alertType].warning}
      </ReactBSAlert>
    );
  };

  return (
    <>
      {alert}
      <Container fluid className="content">
        <Row className="justify-content-center mt-4">
          <Col md={8}>
            <h2 className="text-center mb-4">Investigation Details</h2>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle tag="h4">
                  Hedge User Info for Request: {requestid}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {userInfo ? (
                  <SimpleTable
                    thead={[
                      { text: 'First Name' },
                      { text: 'Last Name' },
                      { text: 'Email' },
                      { text: 'Cognito User' },
                    ]}
                    tbody={[
                      {
                        data: [
                          { text: userInfo?.User?.first_name },
                          { text: userInfo?.User?.last_name },
                          { text: userInfo?.User?.email },
                          { text: userInfo?.User?.user_name },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <Alert color="info">Loading user information...</Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle tag="h4">
                  Results Summary for Request: {requestid}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {summaryResultsTable && summaryResultsTable.length > 0 ? (
                  <SimpleTable
                    thead={[{ text: 'Check Type' }, { text: 'Status' }]}
                    tbody={summaryResultsTable.map((row, index) => ({
                      key: index,
                      data: [{ text: row.checkType }, { text: row.status }],
                    }))}
                  />
                ) : (
                  <Alert color="info">Loading summary results...</Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Identity Verification Details */}
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle tag="h4">
                  Identity Verification Details for Request: {requestid}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {identityRequestTable ? (
                  <SimpleTable
                    thead={[{ text: 'Field' }, { text: 'Value' }]}
                    tbody={Object.entries(identityRequestTable).map(
                      ([field, value], index) => ({
                        key: index,
                        data: [{ text: field }, { text: value }],
                      })
                    )}
                  />
                ) : (
                  <Alert color="info">
                    Loading identity verification details...
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Identity Result State */}
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle tag="h4">
                  Identity Result State for Request: {requestid}
                </CardTitle>
                {identityResultStateTable &&
                  identityResultStateTable[0]?.Value === 'INDETERMINATE' && (
                    <div className="mt-3">
                      <Button
                        color="primary"
                        onClick={() =>
                          warningWithConfirmAndCancelMessage(
                            manuallyApproveIndeterminateInvestigationMutation,
                            {
                              accountNum,
                              investigationRequestId: requestid,
                            },
                            'manuallyApproveIndeterminateInvestigation'
                          )
                        }
                      >
                        Manually Approve Indeterminate Investigation
                      </Button>
                    </div>
                  )}
              </CardHeader>
              <CardBody>
                {identityResultStateTable ? (
                  <SimpleTable
                    thead={[{ text: 'Field' }, { text: 'Value' }]}
                    tbody={identityResultStateTable.map((row, index) => ({
                      key: index,
                      data: [{ text: row.Field }, { text: row.Value }],
                    }))}
                  />
                ) : (
                  <Alert color="info">Loading identity result state...</Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Identity Result State Details */}
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle tag="h4">
                  Identity Result State Details for Request: {requestid}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {identityResultStateDetailsTable ? (
                  <SimpleTable
                    thead={[{ text: 'Field' }, { text: 'Value' }]}
                    tbody={identityResultStateDetailsTable.map(
                      (row, index) => ({
                        key: index,
                        data: [{ text: row.Field }, { text: row.Value }],
                      })
                    )}
                  />
                ) : (
                  <Alert color="info">
                    Loading identity result state details...
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Sanctions News Profiles */}
        {sanctionsNewsProfilesTable &&
          sanctionsNewsProfilesTable.length > 0 && (
            <Row className="justify-content-center mb-4">
              <Col md={12}>
                <Card className="mb-4">
                  <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle tag="h4">
                      Sanctions News Profiles for Request: {requestid}
                    </CardTitle>
                    <Button
                      onClick={handlePressNewsProfiles}
                      color="secondary"
                      size="sm"
                    >
                      <i className="tim-icons icon-double-right" />
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <SimpleTable
                      thead={[
                        { text: 'Name' },
                        { text: 'Summary' },
                        { text: 'Certainty' },
                        { text: 'Reasons' },
                        { text: 'Short Summary' },
                        { text: 'Record Name Details' },
                      ]}
                      tbody={sanctionsNewsProfilesTable.map(
                        (profile, index) => ({
                          key: index,
                          data: [
                            { text: profile.Name },
                            { text: profile.Summary },
                            { text: profile.Certainty },
                            { text: profile.Reasons },
                            { text: profile.ShortSummary },
                            { text: profile.RecordNameDetails },
                          ],
                        })
                      )}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

        {/* DNDB Profiles */}
        {dndbProfilesTable && dndbProfilesTable.length > 0 && (
          <Row className="justify-content-center mb-4">
            <Col md={12}>
              <Card className="mb-4">
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <CardTitle tag="h4">
                    DNDB Profiles for Request: {requestid}
                  </CardTitle>
                  <Button
                    onClick={handlePressDNDBProfiles}
                    color="secondary"
                    size="sm"
                  >
                    <i className="tim-icons icon-double-right" />
                  </Button>
                </CardHeader>
                <CardBody>
                  <SimpleTable
                    thead={[
                      { text: 'Name' },
                      { text: 'Certainty' },
                      { text: 'Match Category' },
                    ]}
                    tbody={dndbProfilesTable.map((profile, index) => ({
                      key: index,
                      data: [
                        { text: profile.Name },
                        { text: profile.Certainty },
                        { text: profile.MatchCategory },
                      ],
                    }))}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {/* CIP Categories */}
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader className="d-flex justify-content-between align-items-center">
                <CardTitle tag="h4">
                  CIP Categories for Request: {requestid}
                </CardTitle>
                <Button
                  onClick={handlePressCIPCategories}
                  color="secondary"
                  size="sm"
                >
                  <i className="tim-icons icon-double-right" />
                </Button>
              </CardHeader>
              <CardBody>
                {cipCategoriesTable ? (
                  <SimpleTable
                    thead={[{ text: 'Category' }, { text: 'State' }]}
                    tbody={cipCategoriesTable.map((category, index) => ({
                      key: index,
                      data: [
                        { text: category.cipCategory },
                        { text: category.categoryState },
                      ],
                    }))}
                  />
                ) : (
                  <Alert color="info">Loading CIP categories...</Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Internal Investigation History */}
        <Row className="justify-content-center mb-4">
          <Col md={12}>
            <Card className="mb-4">
              <CardHeader className="d-flex justify-content-between align-items-center flex-wrap">
                <CardTitle tag="h4">
                  Hedge Internal Investigation History for Account: {accountNum}
                </CardTitle>
                <Button
                  onClick={handleRefreshInternalHistory}
                  color="secondary"
                  size="sm"
                >
                  <i className="tim-icons icon-refresh-01" />
                </Button>
              </CardHeader>
              <CardBody>
                <div
                  className="mb-4 p-3"
                  style={{
                    border: '2px solid grey',
                    borderRadius: '10px',
                    backgroundColor: '#f8f9fa',
                  }}
                >
                  <h5>Update Internal Investigation State</h5>
                  <FormGroup row>
                    <Label for="investigationStateSelect" sm={4}>
                      Select Internal Investigation State
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="select"
                        id="investigationStateSelect"
                        value={investigationDropDownValue}
                        onChange={e => {
                          setInvestigationDropDownValue(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select an option...
                        </option>
                        <option value="IDV_REQUESTED">IDV_REQUESTED</option>
                        <option value="FORM_RESUBMIT_REQUESTED">
                          FORM_RESUBMIT_REQUESTED
                        </option>
                        <option value="HEDGE_BACK_OFFICE">
                          HEDGE_BACK_OFFICE
                        </option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <Button
                    color="primary"
                    onClick={() =>
                      warningWithConfirmAndCancelMessage(
                        updateInvestigationMutation,
                        {
                          accountNum,
                          status: investigationDropDownValue,
                          requestId: requestid,
                        },
                        'updateInvestigationState'
                      )
                    }
                    disabled={!investigationDropDownValue}
                  >
                    Update Account: {accountNum} Internal Investigation State
                  </Button>
                </div>
                {getInternalInvestigationQuery.data &&
                getInternalInvestigationQuery.data.length > 0 ? (
                  <ReactTable
                    tableRoute="internalInvestigationHistory"
                    data={getInternalInvestigationQuery.data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Id',
                        accessor: 'id',
                      },
                      {
                        Header: 'Type',
                        accessor: 'type',
                      },
                      {
                        Header: 'Account Number',
                        accessor: 'apexAccountNumber',
                      },
                      {
                        Header: 'Status',
                        accessor: 'status',
                      },
                      {
                        Header: 'Sketch Request Id',
                        accessor: 'sketchRequestId',
                      },
                      {
                        Header: 'Created At',
                        accessor: 'dateTime',
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <Alert color="info">
                    Loading internal investigation history...
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* All Identity Documents */}
        {allIdentityDocumentsTable && allIdentityDocumentsTable.length > 0 && (
          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <Card className="mb-4">
                <CardHeader>
                  <CardTitle tag="h4">
                    All Identity Documents for Account: {accountNum}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <IdentityTable
                    thead={[{ text: 'Document Type' }, { text: 'File' }]}
                    tbody={allIdentityDocumentsTable.map((document, index) => ({
                      key: index,
                      data: [
                        { text: document.documentType },
                        {
                          text: (
                            <a
                              href={document.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {document.fileName}
                            </a>
                          ),
                        },
                      ],
                    }))}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
