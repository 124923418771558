/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { APIRequestWithAuth } from './config';
import AuthenticationService from './Auth.service';
import { openSearchEndpoint } from '../utils.env';

export const getWhitelist = async pageNumber => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/whitelist?pageNumber=${pageNumber}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getApexDiff = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/whitelist/diff/apex');
  if (data) {
    return data;
  }
  return null;
};

export const getPolygonDiff = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/whitelist/diff/polygon');
  if (data) {
    return data;
  }
  return null;
};

export const getCombinedDiff = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/whitelist/diff/combined');
  if (data) {
    return data;
  }
  return null;
};

export const updateWhitelist = async requestObj => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/whitelist/update',
    requestObj
  );
  if (data) {
    return data;
  }
  return null;
};

export const refreshDiffs = async () => {
  const data = await APIRequestWithAuth('POST', '/v1/whitelist/diff');
  if (data) {
    return data;
  }
  return null;
};

export const searchWhitelist = async searchTerm => {
  try {
    const { idToken } = await AuthenticationService.getSession();
    const jwt = idToken.toString();
    if (!jwt) {
      console.error('JWT token not found');
      return null;
    }

    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    const response = await axios.get(
      `${openSearchEndpoint()}/tickers/search?searchTerm=${encodeURIComponent(searchTerm)}`,
      { headers }
    );

    if (response.data) {
      return response.data.map(stock => stock._source);
    }
    return null;
  } catch (error) {
    console.error('Error fetching whitelist:', error);
    return null;
  }
};
