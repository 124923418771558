import React, { useCallback, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { getAllGroupWithdrawals } from 'Services/AccountServices';
import ReactTable from '../Components/ReactTable/ReactTable';

function GroupWithdrawals() {
  const navigate = useNavigate();
  const [groupWithdrawals, setGroupWithdrawals] = React.useState([]);

  const handlePress = useCallback(
    userId => {
      navigate(`/groupWithdrawals/${userId}`);
    },
    [navigate]
  );

  const transformData = useCallback(
    rawData => {
      if (!rawData || !rawData?.items || !rawData?.items?.length) return [];
      return rawData.items.map((prop, key) => ({
        id: key,
        event_time: moment(prop.event_time).format('MM/DD/YYYY HH:mm:ss A'),
        group_name: prop.group_name,
        user_id: prop.user_id,
        user_email: prop.user_email,
        account_num: prop.account_num,
        acct_type: prop.acct_type,
        symbol: prop.symbol,
        source_account: prop.source_account,
        destination_account: prop.destination_account,
        jrl_type: prop.jrl_type,
        journal_status: prop.journal_status,
        event_cash_amount: prop.event_cash_amount,
        event_share_amount: prop.event_share_amount,
        changed_account_balances: prop.changed_account_balances,
        delta_cash: prop.delta_cash,
        new_total_cash: prop.new_total_cash,
        delta_buying_power: prop.delta_buying_power,
        new_buying_power: prop.new_buying_power,
        changed_position_balances: prop.changed_position_balances,
        delta_total_shares: prop.delta_total_shares,
        new_total_shares: prop.new_total_shares,
        txn_grouping_id: prop.txn_grouping_id,
        txn_id: prop.txn_id,
        position_txn_id: prop.position_txn_id,
        hedge_withdrawal_id: prop.hedge_withdrawal_id,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => handlePress(prop.user_id)}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
          </div>
        ),
      }));
    },
    [handlePress]
  );

  const { isLoading, refetch, isSuccess, data } = useQuery({
    queryKey: ['getAllGroupWithdrawals'],
    queryFn: getAllGroupWithdrawals,
  });

  useEffect(() => {
    if (isSuccess) {
      setGroupWithdrawals(transformData(data));
    }
  }, [isSuccess, data, transformData]);

  function handleRefresh() {
    refetch();
  }

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Group Withdrawals</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
                disabled={isLoading}
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {data && data.length > 0 ? (
                <ReactTable
                  tableRoute="acats"
                  data={groupWithdrawals}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Event Time',
                      accessor: 'event_time',
                    },
                    {
                      Header: 'Group Name',
                      accessor: 'group_name',
                    },
                    {
                      Header: 'User ID',
                      accessor: 'user_id',
                    },
                    {
                      Header: 'User Email',
                      accessor: 'user_email',
                    },
                    {
                      Header: 'Account Number',
                      accessor: 'account_num',
                    },
                    {
                      Header: 'Account Type',
                      accessor: 'acct_type',
                    },
                    {
                      Header: 'Symbol',
                      accessor: 'symbol',
                    },
                    {
                      Header: 'Source Account',
                      accessor: 'source_account',
                    },
                    {
                      Header: 'Destination Account',
                      accessor: 'destination_account',
                    },
                    {
                      Header: 'Journal Type',
                      accessor: 'jrl_type',
                    },
                    {
                      Header: 'Journal Status',
                      accessor: 'journal_status',
                    },
                    {
                      Header: 'Event Cash Amount',
                      accessor: 'event_cash_amount',
                    },
                    {
                      Header: 'Event Share Amount',
                      accessor: 'event_share_amount',
                    },
                    {
                      Header: 'Changed Account Balances',
                      accessor: 'changed_account_balances',
                    },
                    {
                      Header: 'Delta Cash',
                      accessor: 'delta_cash',
                    },
                    {
                      Header: 'New Total Cash',
                      accessor: 'new_total_cash',
                    },
                    {
                      Header: 'Delta Buying Power',
                      accessor: 'delta_buying_power',
                    },
                    {
                      Header: 'New Buying Power',
                      accessor: 'new_buying_power',
                    },
                    {
                      Header: 'Changed Position Balances',
                      accessor: 'changed_position_balances',
                    },
                    {
                      Header: 'Delta Total Shares',
                      accessor: 'delta_total_shares',
                    },
                    {
                      Header: 'New Total Shares',
                      accessor: 'new_total_shares',
                    },
                    {
                      Header: 'Transaction Grouping ID',
                      accessor: 'txn_grouping_id',
                    },
                    {
                      Header: 'Transaction ID',
                      accessor: 'txn_id',
                    },
                    {
                      Header: 'Position Transaction ID',
                      accessor: 'position_txn_id',
                    },
                    {
                      Header: 'Hedge Withdrawal ID',
                      accessor: 'hedge_withdrawal_id',
                    },

                    {
                      Header: 'Details',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              ) : (
                <span> loading... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GroupWithdrawals;
