import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { getUserInfoByApexAccountNumber } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function UserDetailsTable({ accountNumber }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['getUser', accountNumber],
    queryFn: () => getUserInfoByApexAccountNumber(accountNumber),
  });

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Hedge User Info for Account: {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            <p>
              Error:
              {error?.message || 'Failed to load user information.'}
            </p>
          ) : (
            data && (
              <SimpleTable
                thead={[
                  { text: 'First Name' },
                  { text: 'Last Name' },
                  { text: 'Email' },
                  { text: 'Cognito User' },
                ]}
                tbody={[
                  {
                    data: [
                      { text: data.User?.first_name || 'N/A' },
                      { text: data.User?.last_name || 'N/A' },
                      { text: data.User?.email || 'N/A' },
                      { text: data.User?.user_name || 'N/A' },
                    ],
                  },
                ]}
              />
            )
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default UserDetailsTable;
