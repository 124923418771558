/* eslint-disable jsx-quotes */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useDispatch, useSelector } from 'react-redux';
import {
  removeStockFromAdditions,
  removeStockFromRemovals,
  removeStockFromEdits,
  clearAllWhitelistState,
} from 'store/Reducers/whitelist';
import { Button, Container, Row, Col } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { updateWhitelist } from 'Services/WhitelistService';

export default function ChangesTable() {
  const additionsGridRef = useRef();
  const removalsGridRef = useRef();
  const editsGridRef = useRef();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const additionsData = useSelector(
    state => state.whitelist.queuedAdditionsObj
  );
  const removalsData = useSelector(state => state.whitelist.queuedRemovalsObj);
  const editsData = useSelector(state => state.whitelist.queuedEditObj);
  const allData = useSelector(state => state.whitelist);

  const [additionsChartData, setAdditionsChartData] = useState(
    additionsData ? Object.values(additionsData) : []
  );
  const [removalsChartData, setRemovalsChartData] = useState(
    removalsData ? Object.values(additionsData) : []
  );
  const [editsChartData, setEditsChartData] = useState(
    editsData ? Object.values(additionsData) : []
  );
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );
  const columnDefs = [
    {
      field: 'ticker',
      filter: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'name', filter: true },
    { field: 'active' },
    { field: 'type' },
    { field: 'locale' },
    { field: 'currency_name' },
    { field: 'cik' },
    { field: 'composite_figi' },
    { field: 'share_class_figi' },
    { field: 'market' },
    { field: 'primary_exchange' },
    { field: 'delisted_utc' },
    { field: 'sic_code' },
  ];

  const editAndRemoveColumnDefs = [
    {
      field: 'symbol',
      filter: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    { field: 'securityName', filter: true },
    { field: 'active' },
    { field: 'type' },
    { field: 'locale' },
    { field: 'currency_name' },
    { field: 'cik' },
    { field: 'composite_figi' },
    { field: 'share_class_figi' },
    { field: 'market' },
    { field: 'primary_exchange' },
    { field: 'delisted_utc' },
    { field: 'sic_code' },
  ];

  useEffect(() => {
    if (additionsGridRef.current && additionsGridRef.current.api) {
      additionsGridRef.current.api.setRowData(Object.values(additionsData));
      setAdditionsChartData(Object.values(additionsData));
    }
    if (removalsGridRef.current && removalsGridRef.current.api) {
      removalsGridRef.current.api.setRowData(Object.values(removalsData));
      setRemovalsChartData(Object.values(removalsData));
    }
    if (editsGridRef.current && editsGridRef.current.api) {
      editsGridRef.current.api.setRowData(Object.values(editsData));
      setEditsChartData(Object.values(editsData));
    }
  }, [additionsData, removalsData, editsData]);

  const onSelectionAdd = e => {
    dispatch(removeStockFromAdditions(e.data));
  };
  const onSelectionRemove = e => {
    dispatch(removeStockFromRemovals(e.data));
  };
  const onSelectionEdit = e => {
    dispatch(removeStockFromEdits(e.data));
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successLoading = useCallback(async () => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title="Please Wait"
        onConfirm={hideAlert}
        showConfirm={false}
      >
        Making changes...
      </ReactBSAlert>
    );
    await updateWhitelist({
      symbolsToAdd: allData.queuedAdditionsObj,
      symbolsToRemove: allData.queuedRemovalsObj,
      symbolsToEdit: allData.queuedEditObj,
    });
    dispatch(clearAllWhitelistState());
    setAlert(
      <ReactBSAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title="Sent!"
        onConfirm={hideAlert}
        onCancel={hideAlert}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Whitelist updates will complete shortly!
      </ReactBSAlert>
    );
  }, [allData, dispatch]);

  const warningWithConfirmAndCancelMessage = useCallback(() => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={successLoading}
        onCancel={hideAlert}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, submit whitelist changes!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Make sure all changes are correct in the Queued Changes table!
      </ReactBSAlert>
    );
  }, [successLoading]);
  return (
    <>
      {alert}
      <Container fluid>
        <h1>Queued Changes</h1>
        <Row className="justify-content-between">
          <Col md="4">
            <h3>Queued Additions</h3>
            <div style={{ height: '400px' }}>
              <AgGridReact
                className="ag-theme-alpine"
                ref={additionsGridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows
                rowSelection="multiple"
                rowData={additionsChartData}
                rowMultiSelectWithClick
                onRowSelected={onSelectionAdd}
              />
            </div>
          </Col>
          <Col md="4">
            <h3>Queued Removals</h3>
            <div style={{ height: '400px' }}>
              <AgGridReact
                className="ag-theme-alpine"
                ref={removalsGridRef}
                columnDefs={editAndRemoveColumnDefs}
                defaultColDef={defaultColDef}
                animateRows
                rowSelection="multiple"
                rowData={removalsChartData}
                rowMultiSelectWithClick
                onRowSelected={onSelectionRemove}
              />
            </div>
          </Col>
          <Col md="4">
            <h3>Queued Edits</h3>
            <div style={{ height: '400px' }}>
              <AgGridReact
                className="ag-theme-alpine"
                ref={editsGridRef}
                columnDefs={editAndRemoveColumnDefs}
                defaultColDef={defaultColDef}
                animateRows
                rowSelection="multiple"
                rowData={editsChartData}
                rowMultiSelectWithClick
                onRowSelected={onSelectionEdit}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4" style={{ marginBottom: '20px' }}>
          <Col md="12">
            <p>** Clicking a Row Removes that Item from the Queue **</p>
          </Col>
          <Button
            className="mt-2"
            color="success"
            size="lg"
            onClick={warningWithConfirmAndCancelMessage}
          >
            Submit Queued Changes
          </Button>
        </Row>
      </Container>
    </>
  );
}
