import React from 'react';
import Applications from 'Screens/Applications/Applications';
import UnmatchedEvents from 'Screens/Reconciliation/UnmatchedEvents';
import ReconEventsList from 'Screens/EventRecon/ReconEvents';
import Transfers from 'Screens/Transfers/Transfers';
import Orders from 'Screens/Orders/Orders';
import GroupOrders from 'Screens/GroupOrders/GroupOrders';
import IncompleteOldOrders from 'Screens/Orders/IncompleteOldOrders';
import AchRelationships from 'Screens/AchRelationships/AchRelationships';
import Acats from 'Screens/Acats/Acats';
import Alerts from 'Screens/Alerts/Alerts';
import Whitelist from 'Screens/Whitelist/Whitelist';
import TickerStatus from 'Screens/TickerStatus/TickerStatus';
import AccountsReconciliation from 'Screens/Reconciliation/AccountsReconciliation';
import GroupsScreen from 'Screens/Groups/GroupsScreen';
import SQSQueues from 'Screens/SQSQueues/SQSQueues';
import PdtDetailUser from 'Screens/PDT/pdtDetailsUser';
import GroupWithdrawals from 'Screens/Groups/GroupWithdrawals';
import Accounts from 'Screens/Accounts/Accounts';
import TransferDetails from 'Screens/Transfers/TransferDetails';
import OrderDetails from 'Screens/Orders/OrderDetails';
import GroupOrderDetailExtApaOrderId from 'Screens/GroupOrders/GroupOrderDetailsByExtApaOrderId';
import AchRelationshipDetails from 'Screens/AchRelationships/AchRelationshipDetails';
import AccountDetails from 'Screens/Accounts/AccountDetails';
import AcatsDetails from 'Screens/Acats/AcatsDetails';
import ApplicationDetails from 'Screens/Applications/ApplicationDetails';
import SanctionsNewsProfilesDetails from 'Screens/Applications/SanctionsNewsProfilesDetails';
import CIPDetails from 'Screens/Applications/CIPDetails';
import DNDBProfilesDetails from 'Screens/Applications/DNDBProfilesDetails';
import PdtDetailExtId from 'Screens/PDT/pdtDetailsByOrder';
import Pdt from 'Screens/PDT/pdt';
import AccountsReconciliationDetails from 'Screens/Reconciliation/AccountsReconciliationDetails';
import AccountsReconciliationSOD from 'Screens/Reconciliation/AccountsReconciliationSOD';
import GroupDetailsScreen from 'Screens/Groups/GroupDetailsScreen';
import GroupProposalDetails from 'Screens/Groups/GroupProposalDetails';
import GroupWithdrawalDetails from 'Screens/Groups/GroupWithdrawalDetails';
import LandingPage from 'Screens/LandingPage/LandingPage';
import PrivateRoute from 'routes/PrivateRoute';
import BlankLayout from 'Layouts/BlankLayout';
import MarginCalls from 'Screens/Margin/MarginCalls';
import { Navigate } from 'react-router-dom';
import Users from 'Screens/Users/Users';
import UserDetailsScreen from 'Screens/Users/UserDetailsScreen';

const routes = [
  // public routes
  {
    path: '/',
    name: 'Landing Page',
    layout: 'blank',
    element: <BlankLayout />,
    children: [
      {
        path: '/login',
        element: <LandingPage />,
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />,
      },
    ],
  },
  // private routes
  {
    path: '/',
    layout: 'admin',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="/accounts" replace />,
      },
      {
        path: 'marginCalls',
        name: 'Margin Calls',
        children: [
          {
            index: true,
            element: <MarginCalls />,
          },
        ],
      },
      {
        path: 'reconevents',
        name: 'Recon Events',
        element: <ReconEventsList />,
      },
      {
        path: 'users',
        name: 'Users',
        element: <Users />,
      },
      {
        path: 'users/aum/:userId',
        name: 'User Details',
        element: <UserDetailsScreen />,
      },
      {
        path: 'applications',
        name: 'Applications',
        children: [
          {
            index: true,
            element: <Applications />,
          },
          {
            path: ':requestid',
            element: <ApplicationDetails />,
          },
          {
            path: ':requestid/sanctionsnewsprofiles',
            element: <SanctionsNewsProfilesDetails />,
          },
          {
            path: ':requestid/cipdetails',
            element: <CIPDetails />,
          },
          {
            path: ':requestid/dndbprofiles',
            element: <DNDBProfilesDetails />,
          },
        ],
      },
      {
        path: 'accounts',
        name: 'Accounts',
        children: [
          {
            index: true,
            element: <Accounts />,
          },
          {
            path: ':accountNumber',
            element: <AccountDetails />,
          },
        ],
      },
      {
        path: 'transfers',
        name: 'Transfers',
        children: [
          {
            index: true,
            element: <Transfers />,
          },
          {
            path: ':externalTransferId',
            element: <TransferDetails />,
          },
        ],
      },
      {
        path: 'orders',
        name: 'Orders',
        children: [
          {
            index: true,
            element: <Orders />,
          },
          {
            path: ':externalOrderId',
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: 'grouporders',
        name: 'Group Orders',
        children: [
          {
            index: true,
            element: <GroupOrders />,
          },
          {
            path: ':orderUuid',
            element: <GroupOrderDetailExtApaOrderId />,
          },
        ],
      },
      {
        path: 'incompleteorders',
        name: 'Incomplete Orders',
        children: [
          {
            index: true,
            element: <IncompleteOldOrders />,
          },
        ],
      },
      {
        path: 'achrelationships',
        name: 'ACH Relationships',
        children: [
          {
            index: true,
            element: <AchRelationships />,
          },
          {
            path: ':achRelationshipId',
            element: <AchRelationshipDetails />,
          },
        ],
      },
      {
        path: 'acats',
        name: 'Acats',
        children: [
          {
            index: true,
            element: <Acats />,
          },
          {
            path: ':tifId',
            element: <AcatsDetails />,
          },
        ],
      },
      {
        path: 'alerts',
        name: 'Alerts',
        children: [
          {
            index: true,
            element: <Alerts />,
          },
        ],
      },
      {
        path: 'whitelist',
        name: 'Whitelist',
        children: [
          {
            index: true,
            element: <Whitelist />,
          },
        ],
      },
      {
        path: 'tickerStatus',
        name: 'Ticker Status',
        children: [
          {
            index: true,
            element: <TickerStatus />,
          },
        ],
      },
      {
        path: 'accountsReconciliation',
        name: 'Accounts Reconciliation',
        children: [
          {
            index: true,
            element: <AccountsReconciliation />,
          },
          {
            path: ':date',
            element: <AccountsReconciliationDetails />,
          },
          {
            path: 'sod/:date',
            element: <AccountsReconciliationSOD />,
          },
        ],
      },
      {
        path: 'events/unmatched',
        name: 'Unmatched Events',
        children: [
          {
            index: true,
            element: <UnmatchedEvents />,
          },
        ],
      },
      {
        path: 'groups',
        name: 'Group Investing',
        children: [
          {
            index: true,
            element: <GroupsScreen />,
          },
          {
            path: ':groupId',
            element: <GroupDetailsScreen />,
          },
          {
            path: ':groupId/proposals/:proposalId',
            element: <GroupProposalDetails />,
          },
          {
            path: ':groupId/withdrawals/:withdrawalId',
            element: <GroupWithdrawalDetails />,
          },
        ],
      },
      {
        path: 'sqsqueues',
        name: 'SQS Queues',
        children: [
          {
            index: true,
            element: <SQSQueues />,
          },
        ],
      },
      {
        path: 'pdt',
        name: 'Pattern Day Trading',
        children: [
          {
            index: true,
            element: <Pdt />,
          },
          {
            path: ':uuid_usersdb',
            element: <PdtDetailUser />,
          },
          {
            path: 'indv/:externalid',
            element: <PdtDetailExtId />,
          },
        ],
      },
      {
        path: 'groupWithdrawals',
        name: 'Group Withdrawals',
        children: [
          {
            index: true,
            element: <GroupWithdrawals />,
          },
        ],
      },
    ],
  },
];

export { routes };
