/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';

import { Nav, Collapse } from 'reactstrap';

import { sidebarRoutes } from 'routes/sidebarRoutes';

let ps;

// Utility function to detect Windows OS
const isWindows = () => /Win/.test(navigator.userAgent);

export default function Sidebar(props) {
  const [state, setState] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  const activeRoute = useCallback(
    routeName => {
      if (location.pathname.startsWith(routeName)) {
        if (routeName === '/') {
          return 'active';
        }
        return 'active';
      }
      return '';
    },
    [location.pathname]
  );

  React.useEffect(() => {
    if (isWindows()) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (isWindows() && ps) {
        ps.destroy();
      }
    };
  }, []);
  const getCollapseInitialState = useCallback(routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }, []);
  const getCollapseStates = useCallback(
    routes => {
      let initialState = {};
      routes.map(prop => {
        if (prop.collapse) {
          initialState = {
            [prop.state]: getCollapseInitialState(prop.views),
            ...getCollapseStates(prop.views),
            ...initialState,
          };
        }
        return null;
      });
      return initialState;
    },
    [getCollapseInitialState]
  );
  React.useEffect(() => {
    setState(getCollapseStates(sidebarRoutes));
  }, [getCollapseStates]);

  const createLinks = routes => {
    const { rtlActive } = props;
    return routes.map(prop => {
      // Skip routes that should not be shown in the sidebar
      if (prop.showInSidebar === false) {
        return null;
      }

      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !state[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? 'active' : ''}
            key={prop.path}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              onClick={e => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                  <span className="sidebar-normal">
                    {rtlActive ? prop.rtlName : prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.path)} key={prop.path}>
          <NavLink to={prop.path} onClick={props.closeSidebar}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{rtlActive ? prop.rtlName : prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
                <span className="sidebar-normal">
                  {rtlActive ? prop.rtlName : prop.name}
                </span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.closeSidebar}
          rel="noreferrer"
        >
          <img src={logo.imgSrc} alt="react-logo" />
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.closeSidebar}
          rel="noreferrer"
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </NavLink>
      );
    }
  }
  return (
    <div className="sidebar" data={activeColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        {logoImg !== null || logoText !== null ? (
          <div className="logo">
            {logoImg}
            {logoText}
          </div>
        ) : null}
        <Nav>{createLinks(sidebarRoutes)}</Nav>
      </div>
    </div>
  );
}
