/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function GroupSearch({ onSearch }) {
  const [filterType, setFilterType] = useState('email');
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    onSearch(filterType, query);
  };

  const getLabelText = () => {
    switch (filterType) {
      case 'email':
        return 'Email';
      case 'accountNum':
        return 'Account Number';
      case 'cognitoId':
        return 'Cognito ID';
      case 'groupId':
        return 'Group ID';
      case 'groupName':
        return 'Group Name';
      default:
        return 'Query';
    }
  };

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-4 items-center p-4 w-full">
      <div className="mb-4 lg:mb-0">
        <label htmlFor="filterType" className="block text-white mb-2 mr-3">
          Select Filter Type:
        </label>
        <select
          id="filterType"
          value={filterType}
          onChange={e => setFilterType(e.target.value)}
          className="p-2 rounded bg-gray-700 text-black"
        >
          <option value="all">All</option>
          <option value="email">Email</option>
          <option value="accountNum">Account Number</option>
          <option value="cognitoId">Cognito ID</option>
          <option value="groupId">Group ID</option>
          <option value="groupName">Group Name</option>
        </select>
      </div>
      {filterType !== 'all' && (
        <div className="mb-4 flex-grow lg:mb-0">
          <label htmlFor="query" className="block text-white mb-2 mr-3">
            {getLabelText()}:
          </label>
          <input
            id="query"
            type="text"
            value={query}
            onChange={e => setQuery(e.target.value)}
            className="p-2 rounded bg-gray-700 text-black w-full"
            placeholder={`Enter ${getLabelText()} here...`}
          />
        </div>
      )}
      <button
        onClick={handleSearch}
        type="button"
        className="p-2 bg-blue-500 rounded text-black"
      >
        Search
      </button>
    </div>
  );
}

GroupSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
