/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
// ScrollSidebar.js
import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import scrollIntoView from 'scroll-into-view';

export default function ScrollSidebar({ sections }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleScroll = ref => {
    scrollIntoView(ref.current, {
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        top: '20%',
        width: isCollapsed ? '40px' : '200px',
        zIndex: 1000,
        backgroundColor: '#6c757d',
        borderRadius: '8px',
        padding: isCollapsed ? '10px 0' : '10px',
        transition: 'width 0.3s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isCollapsed ? 'center' : 'flex-start',
      }}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: '#fff',
          cursor: 'pointer',
          position: 'relative',
          fontSize: '18px',
        }}
      >
        {isCollapsed ? '<' : '>'}
      </button>
      {!isCollapsed && (
        <Nav vertical>
          {sections.map(section => (
            <NavItem key={section.id}>
              <NavLink
                href="#"
                onClick={() => handleScroll(section.ref)}
                style={{ color: '#fff', fontSize: '18px', margin: '5px 0' }}
                onMouseEnter={e => (e.currentTarget.style.color = '#007bff')}
                onMouseLeave={e => (e.currentTarget.style.color = '#fff')}
              >
                {section.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      )}
    </div>
  );
}
