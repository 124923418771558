/* eslint-disable no-use-before-define */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams, useLocation } from 'react-router-dom';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import {
  getApexInvestigationDetails,
  uploadApexDocument,
} from 'Services/AccountServices';
import axios from 'axios';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import SimpleTable from '../Components/SortingTable/SortingTable';

export default function CIPDetails() {
  const { requestid } = useParams();
  const location = useLocation();

  const { accountNumber } = location.state;
  const API_ENDPOINT = `${process.env.REACT_APP_S3_UPLOADER_ENDPOINT}`;
  const MAX_IMAGE_SIZE = 1000000;

  const [nameTable, setNameTable] = React.useState(null);
  const [dobTable, setDobTable] = React.useState(null);
  const [addressTable, setAddressTable] = React.useState(null);
  const [taxIDTable, setTaxIDTable] = React.useState(null);
  const [documentType, setDocumentType] = React.useState('');
  const [fileFront, setFileFront] = React.useState(null);
  const [fileBack, setFileBack] = React.useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showFailAlert, setShowFailAlert] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const { data, isSuccess } = useQuery({
    queryKey: ['getApexInvestigationDetails', requestid],
    queryFn: () => getApexInvestigationDetails(requestid),
  });

  useEffect(() => {
    if (isSuccess) {
      const nameData = Object.entries(data.cipDetails[0].NAME).map(
        ([key, value]) => ({
          Category: key,
          State: value,
        })
      );
      setNameTable(nameData);

      const dobData = Object.entries(data.cipDetails[1].DOB).map(
        ([key, value]) => ({
          Category: key,
          State: value,
        })
      );
      setDobTable(dobData);

      const taxData = Object.entries(data.cipDetails[2].TAX_ID).map(
        ([key, value]) => ({
          Category: key,
          State: value,
        })
      );
      setTaxIDTable(taxData);

      const addressData = Object.entries(data.cipDetails[3].ADDRESS).map(
        ([key, value]) => ({
          Category: key,
          State: value,
        })
      );
      setAddressTable(addressData);
    }
  }, [isSuccess, data]);

  const handleDocumentTypeChange = event => {
    setDocumentType(event.target.value);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onFileChangeFront = e => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    createImage(files[0], 'front');
  };

  const onFileChangeBack = e => {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    createImage(files[0], 'back');
  };

  const createImage = (file, side) => {
    const reader = new FileReader();
    // eslint-disable-next-line consistent-return
    reader.onload = e => {
      if (!e.target.result.includes('data:image/jpeg')) {
        return alert('Wrong file type - JPG only.');
      }
      if (e.target.result.length > MAX_IMAGE_SIZE) {
        return alert('Image is too large - 1Mb maximum');
      }
      if (side === 'front') {
        setFileFront(e.target.result);
      } else {
        setFileBack(e.target.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const removeImageFront = () => {
    setFileFront(null);
  };

  const removeImageBack = () => {
    setFileBack(null);
  };

  const uploadImage = async () => {
    setIsLoading(true);

    // if there is just a front, do this once, otherwise do it twice
    const files = [];
    if (fileFront) {
      // Get the presigned URL
      const response = await axios({
        method: 'GET',
        url: API_ENDPOINT,
      });
      const binary = atob(fileFront.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blobData = new Blob([new Uint8Array(array)], {
        type: 'image/jpeg',
      });
      await fetch(response.data.uploadURL, {
        method: 'PUT',
        body: blobData,
      });
      // push the front filename to the files array
      files.push(response.data.photoFilename);
    }

    // do the same for the back, if a second image was uploaded
    if (fileBack) {
      // Get the presigned URL
      const response = await axios({
        method: 'GET',
        url: API_ENDPOINT,
      });
      const binary = atob(fileBack.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blobData = new Blob([new Uint8Array(array)], {
        type: 'image/jpeg',
      });
      await fetch(response.data.uploadURL, {
        method: 'PUT',
        body: blobData,
      });
      // push the back filename to the files array
      files.push(response.data.photoFilename);
    }

    const metadata = {
      account: accountNumber,
      correspondent: 'HEDG',
      tag: 'ID_DOCUMENT',
      documentType:
        documentType === 'Drivers License'
          ? 'DRIVERS_LICENSE'
          : documentType === 'Passport'
            ? 'PASSPORT'
            : 'SSN_CARD',
      description,
    };
    const body = {
      metadata,
      investigationId: requestid,
      s3Url: files,
    };
    const uploadResponse = await uploadApexDocument(body);
    if (uploadResponse) {
      setIsLoading(false);
      setShowSuccessAlert(true);
    } else {
      setIsLoading(false);
      setShowFailAlert(true);
    }
  };

  return (
    <>
      <ReactBSAlert
        show={showSuccessAlert}
        title="Documents Successfully Uploaded"
        onConfirm={() => setShowSuccessAlert(false)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
      />

      <ReactBSAlert
        show={showFailAlert}
        title="Document Upload Failed"
        onConfirm={() => setShowFailAlert(false)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
      >
        Please Contact Support
      </ReactBSAlert>
      <div className="flex h-screen bg-zinc-800">
        <div className="flex justify-center items-start mt-10">
          <Col md={6} className="ml-auto mr-auto">
            <h2 className="text-center">CIP Details For Request {requestid}</h2>
          </Col>
          {/* form with field to upload document */}
          <div style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}>
            <div style={{ marginBottom: '1%' }}>
              <label style={{ marginRight: '1%' }}>Document Type:</label>

              <select value={documentType} onChange={handleDocumentTypeChange}>
                <option value="">Please select a document type</option>
                <option value="Drivers License">Drivers License</option>
                <option value="Passport">Passport</option>
                <option value="Social Security Card">
                  Social Security Card
                </option>
              </select>
            </div>
            <div style={{ marginBottom: '1%' }}>
              <label style={{ marginRight: '1%' }}>Description:</label>

              <input
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                size="80"
              />
            </div>
            <div style={{ marginBottom: '1%' }}>
              <label style={{ marginRight: '1%' }}>Upload Front:</label>
              <input
                type="file"
                accept="image/*"
                onChange={onFileChangeFront}
              />
              {fileFront && (
                <button type="button" onClick={removeImageFront}>
                  Remove
                </button>
              )}
            </div>
            <div style={{ marginBottom: '1%' }}>
              {documentType === 'Drivers License' ||
              documentType === 'Social Security Card' ? (
                <>
                  <label style={{ marginRight: '1%' }}>Upload Back:</label>

                  <input
                    type="file"
                    accept="image/*"
                    onChange={onFileChangeBack}
                  />
                  {fileBack && (
                    <button type="button" onClick={removeImageBack}>
                      Remove
                    </button>
                  )}
                  <br />
                </>
              ) : null}
            </div>
            <button
              type="button"
              disabled={
                !fileFront ||
                ((documentType === 'Drivers License' ||
                  documentType === 'Social Security Card') &&
                  (!fileFront || !fileBack)) ||
                isLoading
              }
              onClick={uploadImage}
            >
              {isLoading ? 'Uploading...' : 'Upload'}
            </button>
          </div>

          {nameTable ? (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">CIP Details For Name</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Category' }, { text: 'State' }]}
                        tbody={nameTable.map(item => ({
                          data: [{ text: item.Category }, { text: item.State }],
                        }))}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <span> loading... </span>
          )}

          {dobTable ? (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">CIP Details For DOB</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Category' }, { text: 'State' }]}
                        tbody={dobTable.map(item => ({
                          data: [{ text: item.Category }, { text: item.State }],
                        }))}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <span> loading... </span>
          )}

          {taxIDTable ? (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">CIP Details For Tax ID</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Category' }, { text: 'State' }]}
                        tbody={taxIDTable.map(item => ({
                          data: [{ text: item.Category }, { text: item.State }],
                        }))}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <span> loading... </span>
          )}

          {addressTable ? (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">CIP Details For Address</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Category' }, { text: 'State' }]}
                        tbody={addressTable.map(item => ({
                          data: [{ text: item.Category }, { text: item.State }],
                        }))}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <span> loading... </span>
          )}
        </div>
      </div>
    </>
  );
}
