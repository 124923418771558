/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';

export default function GroupAumTables({ groupPositions }) {
  const positionsData = groupPositions
    ? Object.keys(groupPositions.positions).map(ticker => ({
        ticker,
        ...groupPositions.positions[ticker],
        marketValue: groupPositions.positions[
          ticker
        ].marketValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        lastPrice: groupPositions.positions[ticker].lastPrice?.toLocaleString(
          'en-US',
          {
            style: 'currency',
            currency: 'USD',
          }
        ),
      }))
    : [];

  const positionBalanceArrayPerMember =
    groupPositions?.positionBalanceArrayPerMember || [];

  return (
    <div>
      <hr className="my-5" style={{ borderColor: 'white' }} />
      <div className="mb-5">
        <h2 className="text-center mb-4">Group Positions</h2>
        {positionsData.length > 0 && (
          <ReactTable
            tableRoute="groups"
            data={positionsData}
            columns={[
              {
                Header: 'Ticker',
                accessor: 'ticker',
              },
              {
                Header: 'Settled',
                accessor: 'settled',
              },
              {
                Header: 'Unsettled',
                accessor: 'unsettled',
              },
              {
                Header: 'Market Value',
                accessor: 'marketValue',
              },
              {
                Header: 'Last Price',
                accessor: 'lastPrice',
              },
              {
                Header: 'Queued',
                accessor: 'queued',
              },
              {
                Header: 'Available Shares To Sell',
                accessor: 'availableSharesToSell',
              },
            ]}
            isSearchActive
          />
        )}
      </div>
      <hr className="my-5" style={{ borderColor: 'white' }} />
      {positionBalanceArrayPerMember.map((positions, index) => (
        <div key={index} className="mb-5">
          <h2 className="text-center mb-4">
            Position Balance for Account {positions[0]?.apexAccount}
          </h2>
          <ReactTable
            tableRoute="groups"
            data={positions.map(position => ({
              ticker: position.tickerSymbol,
              settled: position.settledShareQuantity,
              unsettled: position.unsettledShareQuantity,
              marketValue: position.marketValue?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              lastPrice: position.lastPrice?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              queued: position.queued,
              availableSharesToSell: position.availableSharesToSell,
            }))}
            columns={[
              {
                Header: 'Ticker',
                accessor: 'ticker',
              },
              {
                Header: 'Settled',
                accessor: 'settled',
              },
              {
                Header: 'Unsettled',
                accessor: 'unsettled',
              },
              {
                Header: 'Market Value',
                accessor: 'marketValue',
              },
              {
                Header: 'Last Price',
                accessor: 'lastPrice',
              },
              {
                Header: 'Queued',
                accessor: 'queued',
              },
              {
                Header: 'Available Shares To Sell',
                accessor: 'availableSharesToSell',
              },
            ]}
            isSearchActive
          />
        </div>
      ))}
    </div>
  );
}
