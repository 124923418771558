import React, { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ProtectedLayout from '../Layouts/ProtectedLayout';

function PrivateRoute() {
  const { user, isPending } = useAuthenticator(context => [context.user]);
  const location = useLocation();
  const isLocal = process.env.REACT_APP_BUILD_ENV === 'local'; // Check if the environment is local

  useEffect(() => {
    if (user) {
      localStorage.setItem('lastPath', location.pathname);
    }
  }, [user, location.pathname]);

  // Bypass authentication if in local environment
  if (isLocal) {
    return (
      <ProtectedLayout>
        <Outlet />
      </ProtectedLayout>
    );
  }

  if (isPending) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  // Default behavior for non-local environments
  return (
    <ProtectedLayout>
      <Outlet />
    </ProtectedLayout>
  );
}

export default PrivateRoute;
