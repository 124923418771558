/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getAllPdtTrades } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function Pdt() {
  const navigate = useNavigate();

  const handlePress = useCallback(
    uuid => {
      // eslint-disable-next-line camelcase
      navigate(`/pdt/${uuid}`);
    },
    [navigate]
  );

  const [newData, setNewData] = useState([]);

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['getAllPdtTrades'],
    queryFn: () => getAllPdtTrades(),
  });

  useEffect(() => {
    if (isSuccess) {
      setNewData(
        data.map((prop, key) => ({
          id: key,
          uuid_usersdb: prop.uuid_usersdb,
          email: prop.email,
          account: prop.account,
          prev_side: prop.prev_side,
          day: moment(prop.day).format('MM/DD/YYYY A'),
          datetime: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
          trade_type: prop.trade_type,
          total_day_trades_today: prop.total_day_trades_today,
          day_trade_number_today: prop.day_trade_number_today,
          symbol: prop.symbol,
          side: prop.side,
          sharequantity: prop.sharequantity,
          status: prop.status,
          externalid: prop.externalid,
          triggered_by: prop.triggered_by,
          apaorderid: prop.apaorderid,
          eventUuid: prop.id,
          type: prop.type,
          acct_type: prop.acct_type,
          group_id: prop.group_id,
          group_name: prop.group_name,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  handlePress(prop.uuid_usersdb);
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        }))
      );
    }
  }, [isSuccess, data, setNewData, handlePress]);

  function handleRefresh() {
    refetch();
  }

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Pattern Day Trades</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {data && data.length > 0 ? (
                <ReactTable
                  tableRoute="acats"
                  data={newData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'UserId',
                      accessor: 'uuid_usersdb',
                    },

                    {
                      Header: 'Email',
                      accessor: 'email',
                    },

                    {
                      Header: 'Account',
                      accessor: 'account',
                    },

                    {
                      Header: 'Prev. Side',
                      accessor: 'prev_side',
                    },

                    {
                      Header: 'Trade Day',
                      accessor: 'day',
                    },

                    {
                      Header: 'Txn time',
                      accessor: 'datetime',
                    },

                    {
                      Header: 'Day Trade?',
                      accessor: 'trade_type',
                    },

                    {
                      Header: 'Total PDTs (Daily)',
                      accessor: 'total_day_trades_today',
                    },

                    {
                      Header: 'PDT # (Daily)',
                      accessor: 'day_trade_number_today',
                    },

                    {
                      Header: 'Symbol',
                      accessor: 'symbol',
                    },

                    {
                      Header: 'Side',
                      accessor: 'side',
                    },

                    {
                      Header: 'Share Qty',
                      accessor: 'sharequantity',
                    },

                    {
                      Header: 'Status',
                      accessor: 'status',
                    },

                    {
                      Header: 'External ID',
                      accessor: 'externalid',
                    },

                    {
                      Header: 'Triggered By',
                      accessor: 'triggered_by',
                    },

                    {
                      Header: 'APA Order ID',
                      accessor: 'apaorderid',
                    },

                    {
                      Header: 'ID',
                      accessor: 'eventUuid',
                    },

                    {
                      Header: 'Type',
                      accessor: 'type',
                    },

                    {
                      Header: 'Acct Type',
                      accessor: 'acct_type',
                    },

                    {
                      Header: 'Group ID',
                      accessor: 'group_id',
                    },

                    {
                      Header: 'Group Name',
                      accessor: 'group_name',
                    },

                    {
                      Header: 'Details',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              ) : (
                <span> working on it... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Pdt;
