/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo, useState } from 'react';
import GroupInvestingService from 'Services/GroupInvestingService';
import { useNavigate } from 'react-router-dom';
import GroupSearch from './components/GroupSearch';
import { Button, Container } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export default function GroupsScreen() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [searchQuery, setSearchQuery] = useState(
    `page=${page + 1}&pageSize=${pageSize}`
  );
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSearchActive, setIsSearchActive] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['groups', searchQuery, page + 1, pageSize],
    queryFn: () => GroupInvestingService.searchInvestingGroups(searchQuery),
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  const onSearch = (filterType, query) => {
    if (query === '' || filterType === 'all') {
      setSearchQuery(`page=${page + 1}&pageSize=${pageSize}`);
      setIsSearchActive(false);
      refetch();
      return;
    }
    setSearchQuery(`${filterType}=${query}`);
    setIsSearchActive(true);
    refetch();
  };

  const handlePaginationModelChange = useCallback(
    newPaginationModel => {
      if (newPaginationModel.page !== page) {
        setPage(newPaginationModel.page);
      }
      if (newPaginationModel.pageSize !== pageSize) {
        setPageSize(newPaginationModel.pageSize);
      }

      setSearchQuery(
        `page=${newPaginationModel.page + 1}&pageSize=${newPaginationModel.pageSize}`
      );
    },
    [page, pageSize]
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Group ID',
        width: 150,
      },
      {
        field: 'group_name',
        headerName: 'Group Name',
        width: 150,
      },
      {
        field: 'status',
        headerName: 'Group Status',
        width: 150,
      },
      {
        field: 'voting_threshold',
        headerName: 'Voting Threshold',
        width: 150,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 150,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 150,
      },
      {
        field: 'actions',
        headerName: 'Group Details',
        sortable: false,
        filterable: false,
        width: 150,
        renderCell: params => (
          <Button
            onClick={() => navigate(`/groups/${params.row.id}`)}
            color="secondary"
            size="sm"
          >
            <i className="tim-icons icon-double-right" />
          </Button>
        ),
      },
    ],
    [navigate]
  );

  return (
    <Container fluid className="content">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <h2 className="mb-5">Group Investing</h2>
        </header>
      </div>
      <GroupSearch onSearch={onSearch} />
      <DataGrid
        rows={data?.data || []}
        columns={columns}
        getRowId={row => row.id}
        rowCount={data?.totalCount || 0}
        pageSizeOptions={[50, 100]}
        loading={isLoading || isFetching}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
      />
    </Container>
  );
}
