import { APIRequestWithAuth } from './config';

export const submitTransferEvent = async body => {
  // eslint-disable-next-line no-console
  console.log('submitTransferEvent called from Admin dash');
  try {
    // eslint-disable-next-line no-console
    console.log(
      'submitTransferEvent called from Admin dash with request body:',
      body
    );
    const currentDatetime = new Date().toISOString();
    // eslint-disable-next-line no-console
    console.log('request made at datetime:', currentDatetime);
  } catch {
    // eslint-disable-next-line no-console
    console.warn(
      'submitTransferEvent called from Admin dash. Attempted to log request body & datetime but failed. Continuing with request.'
    );
  }
  // Add new keys with the correct casing that match the event schema and delete the old lowercase keys
  const updatedBody = {
    ...body,
    transferId: body.transferid,
    sourceAccount: body.sourceaccount,
    destinationAccount: body.destinationaccount,
    externalTransferId: body.externaltransferid,
  };
  delete updatedBody.transferid;
  delete updatedBody.sourceaccount;
  delete updatedBody.destinationaccount;
  delete updatedBody.externaltransferid;
  delete updatedBody.datetime;

  // eslint-disable-next-line no-console
  console.log(
    'submitTranferEvent sending Transfer Event with updated body:',
    updatedBody
  );
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/sendTransferEvent',
    updatedBody
  );
  if (data) {
    // eslint-disable-next-line no-console
    console.log(
      'submitTransferEvent successfully returned the following data:',
      data
    );
    return data;
  }
  // eslint-disable-next-line no-console
  console.error('submitTransferEvent was called but failed to return data');
  return null;
};
