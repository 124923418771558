/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { getAcatsByTifId, getAcatsDetails } from 'Services/AcatsServices';
import SimpleTable from '../Components/SortingTable/SortingTable';
import ReactTable from '../Components/ReactTable/ReactTable';

export default function AcatsDetails() {
  const { tifId } = useParams();
  const [acatsTable, setAcatsTable] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);
  const [receiverAssetsTable, setReceiverAssetsTable] = React.useState([]);
  const [delivererAssetsTable, setDelivererAssetsTable] = React.useState([]);
  const [requestedAssetsTable, setRequestedAssetsTable] = React.useState([]);
  const [deliveringAssetsTable, setDeliveringAssetsTable] = React.useState([]);
  const [caseStateTable, setCaseStateTable] = React.useState('');
  const [receiverTable, setReceiverTable] = React.useState('');
  const [delivererTable, setDelivererTable] = React.useState('');

  function handleDeleteClick() {
    setShowAlert(true);
  }

  function handleAlertConfirm() {
    // Handle delete logic here
    setDeletingAccount(true);
    setShowAlert(false);
  }

  function handleAlertCancel() {
    setShowAlert(false);
  }

  const location = useLocation();
  const { acatsControlNumber } = location.state;

  const { isSuccess, data } = useQuery({
    queryKey: ['getAcatsByTif', tifId],
    queryFn: () => getAcatsByTifId(tifId),
  });

  useEffect(() => {
    if (isSuccess) {
      const acatsTableVar = data.map(prop => ({
        id: prop.id,
        acatsControlNumber: prop.acatsControlNumber,
        account: prop.account,
        direction: prop.direction,
        transferType: prop.transferType,
        currentState: prop.currentState,
        previousState: prop.previousState,
        tifId: prop.tifId,
        notes: prop.notes,
        clientReferenceId: prop.clientReferenceId,
        dateSubmitted: moment(prop.originaldatetime).format(
          'MM/DD/YYYY HH:mm:ss A'
        ),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
          // for some reason the table needs this here so it looks normal
          <div> </div>
        ),
      }));
      setAcatsTable(acatsTableVar);
    }
  }, [isSuccess, data]);

  const getAcatsDetailsQuery = useQuery({
    queryKey: ['getAcatsDetails', acatsControlNumber],
    queryFn: () => getAcatsDetails(acatsControlNumber),
  });

  useEffect(() => {
    if (getAcatsDetailsQuery.isSuccess) {
      if (data?.receiver?.assets?.length > 0) {
        const assetsTableVar = data.receiver.assets.map(prop => ({
          symbol:
            prop.identifiers[0].type === 'CASH'
              ? 'CASH'
              : prop.identifiers[2]?.value
                ? prop.identifiers[2].value
                : prop.identifiers[1]?.value,
          description: prop.description,
          quantity: prop?.quantity,
          amount: prop.amount,
          settlingLocation: prop.settlingLocation,
          longShort: prop.longShort,
          actions: (
            // for some reason the table needs this here so it looks normal
            <div className="actions-right"> </div>
          ),
        }));
        setReceiverAssetsTable(assetsTableVar);
      }
      // DELIVERER ASSETS
      if (data?.deliverer?.assets?.length > 0) {
        const assetsTableVar = data.deliverer.assets.map(prop => ({
          symbol:
            prop.identifiers[0].type === 'CASH'
              ? 'CASH'
              : prop.identifiers[2]?.value
                ? prop.identifiers[2].value
                : prop.identifiers[1]?.value,
          description: prop.description,
          quantity: prop?.quantity,
          amount: prop.amount,
          settlingLocation: prop.settlingLocation,
          longShort: prop.longShort,
          actions: (
            // for some reason the table needs this here so it looks normal
            <div className="actions-right"> </div>
          ),
        }));
        setDelivererAssetsTable(assetsTableVar);
      }
      // REQUESTED ASSETS
      if (data?.requestedAssets?.length > 0) {
        const requestedAssetsTableVar = data.requestedAssets.map(prop => ({
          symbol:
            prop.identifiers[0].type === 'CASH'
              ? 'CASH'
              : prop.identifiers[2]?.value
                ? prop.identifiers[2].value
                : prop.identifiers[1]?.value,
          description: prop.description,
          quantity: prop?.quantity,
          amount: prop.amount,
          settlingLocation: prop.settlingLocation,
          longShort: prop.longShort,
          actions: (
            // for some reason the table needs this here so it looks normal
            <div className="actions-right"> </div>
          ),
        }));
        setRequestedAssetsTable(requestedAssetsTableVar);
      }
      // DELIVERING ASSETS
      if (data?.deliveringAssets?.length > 0) {
        const assetsTableVar = data.deliveringAssets.map(prop => ({
          symbol:
            prop.identifiers[0].type === 'CASH'
              ? 'CASH'
              : prop.identifiers[2]?.value
                ? prop.identifiers[2].value
                : prop.identifiers[1]?.value,
          description: prop.description,
          quantity: prop?.quantity,
          amount: prop.amount,
          settlingLocation: prop.settlingLocation,
          longShort: prop.longShort,
          actions: (
            // for some reason the table needs this here so it looks normal
            <div className="actions-right"> </div>
          ),
        }));
        setDeliveringAssetsTable(assetsTableVar);
      }
      // CASE STATE
      if (data?.caseStates?.length > 0) {
        const caseStateVar = data.caseStates.map(prop => ({
          stateType: prop.stateType,
          recommendation: prop.recommendation,
          override: prop.override,
          scheduledAt: prop.scheduledAt,
          scheduledBy: prop.scheduledBy,
          cycleDate: prop.cycleDate,
          reason: prop.reason,
          comment: prop.comment,
          // loop through "scores" and for each score, add a new column "score" with value "score.scoreType"
          score: prop.scores.reduce(
            (acc, score) => `${score.scoreType}: ${score.result}`,
            {}
          ),
          scoreNotes: prop.scores.reduce((acc, score) => `${score.notes}`, {}),

          actions: (
            // for some reason the table needs this here so it looks normal
            <div className="actions-right"> </div>
          ),
        }));
        setCaseStateTable(caseStateVar);
      }

      const receiverObject = [
        {
          label: 'Account Type',
          value:
            data?.receiver?.accountTypes?.length > 0
              ? data?.receiver?.accountTypes[0]
              : 'N/A',
        },
        { label: 'Account Title', value: data?.receiver?.accountTitle },
        { label: 'SSN', value: data?.receiver?.primarySsnOrTaxId },
        {
          label: 'Is Margin',
          value: data?.receiver?.isMarginAccount ? 'Yes' : 'No',
        },
        { label: 'Correspondent', value: data?.receiver?.correspondentName },
        { label: 'Account Number', value: data?.receiver?.accountNumber },
        { label: 'Participant Name', value: data?.receiver?.participantName },
        {
          label: 'Participant Number',
          value: data?.receiver?.participantNumber,
        },
      ];
      setReceiverTable(receiverObject);

      const delivererObject = [
        {
          label: 'Account Type',
          value:
            data?.deliverer?.accountTypes?.length > 0
              ? data?.deliverer?.accountTypes[0]
              : 'N/A',
        },
        { label: 'Account Title', value: data?.deliverer?.accountTitle },
        { label: 'SSN', value: data?.deliverer?.primarySsnOrTaxId },
        {
          label: 'Is Margin',
          value: data?.deliverer?.isMarginAccount ? 'Yes' : 'No',
        },
        { label: 'Correspondent', value: data?.deliverer?.correspondentName },
        { label: 'Account Number', value: data?.deliverer?.accountNumber },
        {
          label: 'Participant Name',
          value: data?.deliverer?.participantName,
        },
        {
          label: 'Participant Number',
          value: data?.deliverer?.participantNumber,
        },
      ];
      setDelivererTable(delivererObject);
    }
  }, [getAcatsDetailsQuery, data]);

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Acats Details for TIF ID {tifId}</h2>
        <Row style={{ justifyContent: 'space-around' }}>
          <h4 className="text-center">Control Number {acatsControlNumber}</h4>
          <h4 className="text-center">
            Processing Case ID {getAcatsDetailsQuery.data?.processingCaseId}
          </h4>
          <h4 className="text-center">
            Client Reference ID {getAcatsDetailsQuery.data?.clientReferenceId}
          </h4>
          <h4 className="text-center">
            Direction {getAcatsDetailsQuery.data?.direction}
          </h4>
          <h4 className="text-center">
            Transfer Type {getAcatsDetailsQuery.data?.transferType}
          </h4>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <h4 className="text-center">
            Next Cycle {getAcatsDetailsQuery.data?.nextCycle}
          </h4>
          <h4 className="text-center">
            Previous Cycle {getAcatsDetailsQuery.data?.previousCycle}
          </h4>
        </Row>

        <Row style={{ justifyContent: 'center' }}>
          <Button
            className="btn-round btn-just-icon"
            color="danger"
            disabled={deletingAccount}
            onClick={handleDeleteClick}
          >
            {deletingAccount ? 'Rejecting ACAT...' : 'Reject ACAT'}
          </Button>
          <ReactBSAlert
            show={showAlert}
            title="Reject ACAT"
            onConfirm={handleAlertConfirm}
            onCancel={handleAlertCancel}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, close it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Are you sure you want to close this account?
          </ReactBSAlert>
        </Row>
      </Col>

      {acatsTable && acatsTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Acats Status Details for {tifId}</CardTitle>
              </CardHeader>
              <CardBody>
                {acatsTable && acatsTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={acatsTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'ID',
                        accessor: 'id',
                      },
                      {
                        Header: 'Acats Control Number',
                        accessor: 'acatsControlNumber',
                      },
                      {
                        Header: 'Account',
                        accessor: 'account',
                      },
                      {
                        Header: 'Direction',
                        accessor: 'direction',
                      },

                      {
                        Header: 'Transfer Type',
                        accessor: 'transferType',
                      },

                      {
                        Header: 'Current State',
                        accessor: 'currentState',
                      },

                      {
                        Header: 'Previous State',
                        accessor: 'previousState',
                      },

                      {
                        Header: 'TIF ID',
                        accessor: 'tifId',
                      },

                      {
                        Header: 'Notes',
                        accessor: 'notes',
                      },

                      {
                        Header: 'Date Submitted',
                        accessor: 'dateSubmitted',
                      },
                      {
                        Header: 'Last Updated',
                        accessor: 'lastUpdated',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {receiverTable && receiverTable.length > 1 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Reciever For {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {receiverTable && receiverTable.length > 1 ? (
                  <SimpleTable
                    thead={[{ text: 'Label' }, { text: 'Value' }]}
                    tbody={receiverTable.map(row => ({
                      data: [{ text: row.label }, { text: row.value }],
                    }))}
                  />
                ) : (
                  <span> loading... </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {receiverAssetsTable && receiverAssetsTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Receiver Assets for {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {receiverAssetsTable && receiverAssetsTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={receiverAssetsTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Symbol/CUSIP',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Description',
                        accessor: 'description',
                      },
                      {
                        Header: 'Quantity',
                        accessor: 'quantity',
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                      },
                      {
                        Header: 'Settling Location',
                        accessor: 'settlingLocation',
                      },
                      {
                        Header: 'Long/Short',
                        accessor: 'longShort',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={25}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {delivererTable && delivererTable.length > 1 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Deliverer For {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {delivererTable && delivererTable.length > 1 ? (
                  <SimpleTable
                    thead={[{ text: 'Label' }, { text: 'Value' }]}
                    tbody={delivererTable.map(row => ({
                      data: [{ text: row.label }, { text: row.value }],
                    }))}
                  />
                ) : (
                  <span> loading... </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {delivererAssetsTable && delivererAssetsTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Deliverer Assets for {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {delivererAssetsTable && delivererAssetsTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={delivererAssetsTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Symbol/CUSIP',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Description',
                        accessor: 'description',
                      },
                      {
                        Header: 'Quantity',
                        accessor: 'quantity',
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                      },
                      {
                        Header: 'Settling Location',
                        accessor: 'settlingLocation',
                      },
                      {
                        Header: 'Long/Short',
                        accessor: 'longShort',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={25}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {requestedAssetsTable && requestedAssetsTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Requested Assets for {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {requestedAssetsTable && requestedAssetsTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={requestedAssetsTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Symbol/CUSIP',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Description',
                        accessor: 'description',
                      },
                      {
                        Header: 'Quantity',
                        accessor: 'quantity',
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                      },
                      {
                        Header: 'Settling Location',
                        accessor: 'settlingLocation',
                      },
                      {
                        Header: 'Long/Short',
                        accessor: 'longShort',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={25}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {deliveringAssetsTable && deliveringAssetsTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Delivering Assets for {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {deliveringAssetsTable && deliveringAssetsTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={deliveringAssetsTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Symbol/CUSIP',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Description',
                        accessor: 'description',
                      },
                      {
                        Header: 'Quantity',
                        accessor: 'quantity',
                      },
                      {
                        Header: 'Amount',
                        accessor: 'amount',
                      },
                      {
                        Header: 'Settling Location',
                        accessor: 'settlingLocation',
                      },
                      {
                        Header: 'Long/Short',
                        accessor: 'longShort',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={25}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {caseStateTable && caseStateTable.length > 0 && (
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Case States for {acatsControlNumber}
                </CardTitle>
              </CardHeader>
              <CardBody>
                {caseStateTable && caseStateTable.length > 0 ? (
                  <ReactTable
                    tableRoute="acats"
                    data={caseStateTable}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'State Type',
                        accessor: 'stateType',
                      },
                      {
                        Header: 'Recommendation',
                        accessor: 'recommendation',
                      },
                      {
                        Header: 'Override',
                        accessor: 'override',
                      },
                      {
                        Header: 'Scheduled At',
                        accessor: 'scheduledAt',
                      },
                      {
                        Header: 'Scheduled By',
                        accessor: 'scheduledBy',
                      },
                      {
                        Header: 'Cycle Date',
                        accessor: 'cycleDate',
                      },
                      {
                        Header: 'Reason',
                        accessor: 'reason',
                      },
                      {
                        Header: 'Comment',
                        accessor: 'comment',
                      },
                      {
                        Header: 'Score',
                        accessor: 'score',
                      },
                      {
                        Header: 'Score Notes',
                        accessor: 'scoreNotes',
                      },
                      {
                        Header: '',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={25}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}
