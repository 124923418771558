/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import { sidebarRoutes } from 'routes/sidebarRoutes';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AdminNavbar from '../components/NavBars/AdminNavbar';
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';

import logo from '../assets/img/hedge-gradient-logo-no-text.png';

let ps;

const isWindows = () => /Win/.test(navigator.userAgent);

export default function ProtectedLayout(props) {
  const [opacity, setOpacity] = React.useState(0);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const { user } = useAuthenticator(context => [context.user]);
  const mainPanelRef = React.useRef(null);
  const location = useLocation();
  const showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop > 50)
    ) {
      setOpacity(1);
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      (mainPanelRef.current && mainPanelRef.current.scrollTop <= 50)
    ) {
      setOpacity(0);
    }
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  React.useEffect(() => {
    const currentMainPanel = mainPanelRef.current;

    if (isWindows()) {
      document.documentElement.classList.add('perfect-scrollbar-on');
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(currentMainPanel);

      if (currentMainPanel) {
        currentMainPanel.addEventListener('ps-scroll-y', showNavbarButton);
      }

      const tables = document.querySelectorAll('.table-responsive');
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }

      return function cleanup() {
        if (isWindows()) {
          ps.destroy();
          document.documentElement.classList.add('perfect-scrollbar-off');
          document.documentElement.classList.remove('perfect-scrollbar-on');

          if (currentMainPanel) {
            currentMainPanel.removeEventListener(
              'ps-scroll-y',
              showNavbarButton
            );
          }
        }
        window.removeEventListener('scroll', showNavbarButton);
      };
    }

    window.addEventListener('scroll', showNavbarButton);

    return function cleanup() {
      if (isWindows()) {
        ps.destroy();
        document.documentElement.classList.add('perfect-scrollbar-off');
        document.documentElement.classList.remove('perfect-scrollbar-on');

        if (currentMainPanel) {
          currentMainPanel.removeEventListener('ps-scroll-y', showNavbarButton);
        }
      }
      window.removeEventListener('scroll', showNavbarButton);
    };
  }, []);

  const getActiveRoute = routes => {
    const { pathname } = window.location;
    const activeRoute = 'Dashboard';

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== 'Dashboard') {
          return collapseActiveRoute;
        }
      } else if (pathname.startsWith(routes[i].path)) {
        if (routes[i].path === '/' && pathname === '/') {
          return routes[i].name;
        }
        if (routes[i].path !== '/') {
          return routes[i].name;
        }
      }
    }

    return activeRoute;
  };
  const handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };
  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle('nav-open');
  };
  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove('nav-open');
  };
  return (
    <div className="wrapper">
      <div className="navbar-minimize-fixed" style={{ opacity }}>
        <button
          type="button"
          className="minimize-sidebar btn btn-link btn-just-icon"
          onClick={handleMiniClick}
          aria-label="Minimize sidebar"
        >
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
        </button>
      </div>
      <Sidebar
        {...props}
        activeColor="blue"
        logo={{
          outterLink: 'https://www.hedge.io/',
          text: user?.signInDetails?.loginId,
          imgSrc: logo,
        }}
        closeSidebar={closeSidebar}
      />
      <div className="main-panel" ref={mainPanelRef} data="blue">
        <AdminNavbar
          {...props}
          handleMiniClick={handleMiniClick}
          brandText={getActiveRoute(sidebarRoutes)}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        {props.children}
        {props?.location?.pathname?.indexOf('full-screen-map') !== -1 ? null : (
          <Footer fluid />
        )}
      </div>
    </div>
  );
}
