import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, Container } from 'reactstrap';
import WithdrawalDetailsCard from './components/WithdrawalDetailsCard';
import WithdrawalItemsTable from './components/WithdrawalItemsTable';
import GroupWithdrawalEventsCard from './components/GroupWithdrawalEventsCard';

export default function GroupWithdrawalDetails() {
  const { withdrawalId } = useParams();
  const location = useLocation();
  const { withdrawal } = location.state || {};
  const [withdrawalData, setWithdrawalData] = useState(withdrawal);
  const [withdrawalItems, setWithdrawalItems] = useState(
    withdrawal.withdrawalItems
  );

  return (
    <Container fluid className="content">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <span className="mb-5 h1">Withdrawal Details {withdrawalId}</span>
        </header>
      </div>
      {withdrawal && (
        <Card className="bg-dark text-white shadow-lg">
          <CardBody>
            <Row className="mb-3">
              <Col>
                <WithdrawalDetailsCard
                  withdrawal={withdrawalData}
                  setWithdrawalData={setWithdrawalData}
                  withdrawalId={withdrawalId}
                />
                <WithdrawalItemsTable
                  items={withdrawalItems}
                  setWithdrawalItems={setWithdrawalItems}
                  withdrawalId={withdrawalId}
                />
                <GroupWithdrawalEventsCard withdrawalId={withdrawalId} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </Container>
  );
}
