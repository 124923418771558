// src/Screens/Reconciliation/UnmatchedEvents.js
/* eslint-disable import/no-unresolved */
/* eslint-disable */

import React, { useState, useMemo, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Popover,
  PopoverHeader,
  PopoverBody,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getUnmatchedEvents, requeueEvent } from '../../Services/ReconciliationServices';
import axios from 'axios';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';
import classnames from 'classnames';

// Import Creative Tim Components
import SweetAlert from 'react-bootstrap-sweetalert';
import { Line } from 'react-chartjs-2';

// Import CSS styles
import '../../assets/css/hedgeStyle.css';
import './components/TableComponent.css';
import '../../assets/css/colors.css';

// Import EventDetailsModal
import EventDetailsModal from './components/EventDetailsModal';
import getApexAccountDetails from '../../Services/AccountServices';

// Define order types and their specific columns
const ORDER_TYPES = {
  ALL: 'all',
  SHARES_TO_BOOK: 'SHARES_TO_BOOK',
  SHARES_BOOKED: 'SHARES_BOOKED',
  NO_SHARES: 'NO_SHARES',
  // Add other order types here
};

// Define columns for each order type
const ORDER_TYPE_COLUMNS = {
  [ORDER_TYPES.ALL]: [
    { Header: 'Event ID', accessor: 'eventId' },
    { Header: 'Event Type', accessor: 'eventType' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Event Date', accessor: 'eventDate' },
    { Header: 'S3 Path', accessor: 's3Path' },
    { Header: 'S3 Last Modified', accessor: 's3LastModified' },
    { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
  ],
  [ORDER_TYPES.SHARES_TO_BOOK]: [
    { Header: 'Event ID', accessor: 'eventId' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Share Quantity', accessor: 'shareQuantity' },
    { Header: 'Avg Price', accessor: 'avgPrice' },
    { Header: 'Notional', accessor: 'notional' },
    { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
  ],
  [ORDER_TYPES.SHARES_BOOKED]: [
    { Header: 'Event ID', accessor: 'eventId' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Booked Quantity', accessor: 'bookedQuantity' },
    { Header: 'Book Price', accessor: 'bookPrice' },
    { Header: 'Total Notional', accessor: 'totalNotional' },
    { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
  ],
  [ORDER_TYPES.NO_SHARES]: [
    { Header: 'Event ID', accessor: 'eventId' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Reason', accessor: 'reason' },
    { Header: 'Reported At', accessor: 'reportedAt' },
    { Header: 'Actions', accessor: 'actions', sortable: false, filterable: false },
  ],
  // Add other order types and their columns here
};

function UnmatchedEvents() {
  // State Variables
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState([]);
  const [helpModal, setHelpModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false); // State for popover
  const [eventDetailsModal, setEventDetailsModal] = useState(false);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState([]);
  const [pauseRequeue, setPauseRequeue] = useState(false);
  const [alert, setAlert] = useState(null); // SweetAlert state
  const [selectedEventType, setSelectedEventType] = useState(null);

  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dateRange, setDateRange] = useState('30'); // Default to 30 days
  const [activeOrderType, setActiveOrderType] = useState(ORDER_TYPES.ALL); // Active order type

  // Function to hide SweetAlert
  const hideAlert = () => {
    setAlert(null);
  };


  // Function to fetch order details (if needed)
  const getOrderDetails = async (tradeDate, externalId) => {
    try {
      const response = await axios.get(`/admin/orders/${tradeDate}/${externalId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching order details:', error);
      throw error;
    }
  };

  // Function to fetch account details (if needed)
  const getAccountDetails = async (accountId) => {
    try {
      const response = await axios.get(`/admin/accounts/${accountId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching account details:', error);
      throw error;
    }
  };

  // Function to get badge color based on status code
  const getStatusColor = (statusCode) => {
    if (!statusCode) {
      return 'secondary'; // Default color if status is undefined or null
    }

    switch (statusCode.toLowerCase()) {
      case 'queued':
      case 'success':
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'failed':
      case 'error':
        return 'danger';
      case 'unknown':
        return 'dark';
      default:
        return 'secondary';
    }
  };

  // Function to process events for the chart based on selected order type and date range
  const getEventsPerDay = (events, range, eventType) => {
    const today = moment().startOf('day');
    const pastDates = [];

    for (let i = range - 1; i >= 0; i--) {
      pastDates.push(today.clone().subtract(i, 'days'));
    }

    const eventsPerDay = pastDates.map((date) => ({
      date: date.format('YYYY-MM-DD'),
      count: 0,
    }));

    events
      .filter((event) => eventType === ORDER_TYPES.ALL || event.eventType === eventType)
      .forEach((event) => {
        const eventDate = moment(event.s3LastModified).format('YYYY-MM-DD');
        const day = eventsPerDay.find((d) => d.date === eventDate);
        if (day) {
          day.count += 1;
        }
      });

    return eventsPerDay;
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 15,
          color: 'var(--primary-color)',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          color: 'var(--primary-color)',
        },
        grid: {
          color: 'var(--border-color)',
        },
      },
    },
  };

  const getChartData = () => {
    const labels = chartData.map((d) => moment(d.date).format('MMM DD'));
    const dataValues = chartData.map((d) => d.count);

    return {
      labels,
      datasets: [
        {
          label: 'Total Events',
          data: dataValues,
          borderColor: 'var(--primary-color)',
          backgroundColor: 'rgba(25, 228, 33, 0.4)',
          fill: true,
          tension: 0.4,
        },
      ],
    };
  };

  // React Query v3 useQuery syntax
  const {
    data: fetchedData,
    isLoading,
    isError,
    error,
    refetch,
    isSuccess
  } = useQuery({
    queryKey: ['getUnmatchedEvents', activeOrderType, dateRange],
    queryFn: () => getUnmatchedEvents(activeOrderType, dateRange),
  });
  
  useEffect(() => {
    if (isSuccess) {
       console.log('Fetched Unmatched Events:', data); // Debugging log

       if (Array.isArray(fetchedData)) {
         if (fetchedData.length === 0) {
           // Handle empty data array
           setData([]); // Set data to an empty array
           setChartData([]); // Clear chart data
         } else {
           // Apply frontend filtering based on dateRange and selectedOrderType
           const filteredData = fetchedData.filter(event => {
             const eventDate = moment(event.s3LastModified);
             const startDate = moment()
               .startOf('day')
               .subtract(parseInt(dateRange) - 1, 'days');
             const matchesDateRange = eventDate.isSameOrAfter(startDate);

             return matchesDateRange;
           });

           setData(
             filteredData.map((event, key) => {
               // Common fields
               const baseData = {
                 id: key,
                 eventId: event.id,
                 status: (
                   <Badge color={getStatusColor(event.status)}>
                     {event.status || 'unknown'}
                   </Badge>
                 ),
                 actions: (
                   <div className="actions-right">
                     <Button
                       color="info"
                       size="sm"
                       className={`btn-icon ${loadingEvents.includes(eventId) ? 'loading' : ''}`}
                       onClick={() => handleRequeue(event.id, event.status)}
                       disabled={loadingEvents.includes(event.id)}
                       aria-label={`Requeue event ${event.id}`}
                     >
                       {loadingEvents.includes(event.id) ? (
                         <i
                           className="fa fa-redo fa-spin"
                           title="Requeueing..."
                         />
                       ) : (
                         <i className="fa fa-redo" title="Requeue Event" />
                       )}
                     </Button>{' '}
                     <Button
                       color="success"
                       size="sm"
                       className="btn-icon"
                       onClick={() => handleViewEventDetails(event)} // Pass the entire event
                       aria-label={`View details for event ${event.id}`}
                     >
                       <i className="fa fa-eye" title="View Event" />
                     </Button>{' '}
                     <Button
                       color="danger"
                       size="sm"
                       className="btn-icon"
                       aria-label={`Delete event ${event.id}`}
                       onClick={() => handleDeleteEvent(event.id)} // Implement delete handler
                     >
                       <i className="fa fa-times" title="Delete Event" />
                     </Button>
                   </div>
                 ),
               };

               // Specific fields based on activeOrderType
               let specificData = {};

               switch (activeOrderType) {
                 case ORDER_TYPES.SHARES_TO_BOOK:
                   specificData = {
                     shareQuantity: event.shareQuantity || 'N/A',
                     avgPrice:
                       event.avgPrice !== undefined && !isNaN(event.avgPrice)
                         ? event.avgPrice.toFixed(2)
                         : 'N/A',
                     notional:
                       event.notional !== undefined && !isNaN(event.notional)
                         ? event.notional.toFixed(2)
                         : 'N/A',
                   };
                   break;
                 case ORDER_TYPES.SHARES_BOOKED:
                   specificData = {
                     bookedQuantity: event.bookedQuantity || 'N/A',
                     bookPrice:
                       event.bookPrice !== undefined && !isNaN(event.bookPrice)
                         ? event.bookPrice.toFixed(2)
                         : 'N/A',
                     totalNotional:
                       event.totalNotional !== undefined &&
                       !isNaN(event.totalNotional)
                         ? event.totalNotional.toFixed(2)
                         : 'N/A',
                   };
                   break;
                 case ORDER_TYPES.NO_SHARES:
                   specificData = {
                     reason: event.reason || 'N/A',
                     reportedAt: moment(event.reportedAt).format(
                       'MM/DD/YYYY HH:mm:ss A'
                     ),
                   };
                   break;
                 case ORDER_TYPES.ALL:
                 default:
                   // Include all or common fields
                   specificData = {
                     eventType: event.eventType || 'unknown',
                     eventDate: moment(event.dateTime).format(
                       'MM/DD/YYYY HH:mm:ss A'
                     ),
                     s3Path: event.s3Path,
                     s3LastModified: moment(event.s3LastModified).format(
                       'MM/DD/YYYY HH:mm:ss A'
                     ),
                     side: event.side || 'N/A',
                     symbol: event.symbol || 'N/A',
                   };
                   break;
               }

               return {
                 ...baseData,
                 ...specificData,
                 // Include other common fields if necessary
               };
             })
           );

           // Update chart data based on filtered events
           const eventsPerDay = getEventsPerDay(
             filteredData,
             parseInt(dateRange),
             activeOrderType
           );
           setChartData(eventsPerDay);
         }
       } else {
         // Handle unexpected data format
         setAlert(
           <SweetAlert
             danger
             title="Error"
             onConfirm={hideAlert}
             confirmBtnText="Close"
             aria-label="Error Alert"
           >
             Received unexpected data format from the server.
           </SweetAlert>
         );
       }
    }
  }, [isSuccess, fetchedData]);

  // Memoize table columns to prevent unnecessary re-renders
  const columns = useMemo(() => {
    return ORDER_TYPE_COLUMNS[activeOrderType] || ORDER_TYPE_COLUMNS[ORDER_TYPES.ALL];
  }, [activeOrderType]);

  // Memoize table data to prevent unnecessary re-renders
  const tableData = useMemo(
    () =>
      data.map((event) => ({
        ...event,
        selection: (
          <input
            type="checkbox"
            onChange={() => handleSelect(event.eventId)}
            checked={selectedEvents.includes(event.eventId)}
            aria-label={`Select event ${event.eventId}`}
          />
        ),
      })),
    [data, selectedEvents]
  );

  /**
   * Handle Requeue Action
   * @param {string} eventId - The ID of the event to requeue
   * @param {string} status - The current status of the event
   */
  const handleRequeue = async (eventId, status) => {
    try {
      setLoadingEvents((prevLoadingEvents) => [...prevLoadingEvents, eventId]);
      setAlert(
        <SweetAlert
          info
          title="Info"
          onConfirm={hideAlert}
          confirmBtnText="OK"
          aria-label="Info Alert"
        >
          {`Requeue request for event ${eventId} has started`}
        </SweetAlert>
      );

      await requeueEvent(eventId, status);

      // Update the event status to "QUEUED"
      setData((prevData) =>
        prevData.map((event) =>
          event.eventId === eventId
            ? {
                ...event,
                status: <Badge color="success">QUEUED</Badge>,
              }
            : event
        )
      );

      setAlert(
        <SweetAlert
          success
          title="Success"
          onConfirm={hideAlert}
          confirmBtnText="OK"
          aria-label="Success Alert"
        >
          {`Event ${eventId} requeued successfully!`}
        </SweetAlert>
      );
    } catch (error) {
      console.error(`Error requeueing event ${eventId}:`, error); // Debugging log
      // Handle any errors that occur during the requeue process
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          confirmBtnText="Retry"
          cancelBtnText="Cancel"
          showCancel
          onCancel={() => hideAlert()}
          onConfirm={() => handleRequeue(eventId, status)} // Retry the requeue
          aria-label="Requeue Error Alert"
        >
          {`Failed to requeue event ${eventId}: ${error.message}`}
        </SweetAlert>
      );
      setErrors((prevErrors) => [...prevErrors, { eventId, error: error.message }]);
      setPauseRequeue(true);
    } finally {
      // Ensure the loading state is updated, whether the try block succeeded or failed
      setLoadingEvents((prevLoadingEvents) => prevLoadingEvents.filter((id) => id !== eventId));
    }
  };

  /**
   * Handle Requeue Selected Events
   */
  const handleRequeueSelected = async () => {
    if (selectedEvents.length === 0) return;

    setProgress(0);
    const totalEvents = selectedEvents.length;
    const newErrors = [];

    for (let i = 0; i < totalEvents; i++) {
      if (pauseRequeue) break;

      const eventId = selectedEvents[i];
      const event = data.find((e) => e.eventId === eventId);
      const eventType = event ? (event.eventType || 'unknown') : 'unknown';

      try {
        await handleRequeue(eventId, eventType);
        setProgress(((i + 1) / totalEvents) * 100);
      } catch (error) {
        newErrors.push({ eventId, error: error.message });
        setPauseRequeue(true);
        break;
      }
    }

    setErrors(newErrors);

    if (newErrors.length > 0) {
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          confirmBtnText="Retry"
          cancelBtnText="Cancel"
          showCancel
          onCancel={() => hideAlert()}
          onConfirm={handleRequeueSelected} // Retry the requeue
          aria-label="Batch Requeue Error Alert"
        >
          {`Some events failed to requeue. Failed events: ${newErrors
            .map((e) => e.eventId)
            .join(', ')}`}
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          success
          title="Success"
          onConfirm={hideAlert}
          confirmBtnText="OK"
          aria-label="Batch Requeue Success Alert"
        >
          All selected events requeued successfully.
        </SweetAlert>
      );
      setSelectedEvents([]); // Clear selection after successful requeue
    }
  };

  /**
   * Continue Requeue Process after an Error
   */
  const continueRequeue = () => {
    setPauseRequeue(false);
    handleRequeueSelected();
  };

  /**
   * Handle View Event Details
   * @param {Object} event - The event details
   */
  const handleViewEventDetails = (event) => {
    console.log('Viewing Event Details:', event); // Debugging log
    setSelectedEventDetails(event);
    setEventDetailsModal(true);
  };

  /**
   * Close Event Details Modal
   */
  const closeEventDetailsModal = () => {
    setSelectedEventDetails(null);
    setEventDetailsModal(false);
  };

  /**
   * Handle Select/Deselect an Event
   * @param {string} eventId - The ID of the event to select/deselect
   */
  const handleSelect = (eventId) => {
    setSelectedEvents((prevSelected) => {
      if (prevSelected.includes(eventId)) {
        return prevSelected.filter((id) => id !== eventId);
      }
      return [...prevSelected, eventId];
    });
  };

  /**
   * Toggle Help Modal
   */
  const toggleHelpModal = () => setHelpModal(!helpModal);

  /**
   * Handle Refresh Data
   */
  const handleRefresh = () => {
    refetch();
  };

  /**
   * Handle Delete Event
   * @param {string} eventId - The ID of the event to delete
   */
  const handleDeleteEvent = async (eventId) => {
    // Implement the delete functionality here
    // For example, call an API endpoint to delete the event
    // After deletion, refetch the data
    try {
      // Example API call (implement accordingly)
      await axios.delete(`/admin/unmatched-events/${eventId}`);
      setAlert(
        <SweetAlert
          success
          title="Deleted"
          onConfirm={hideAlert}
          confirmBtnText="OK"
          aria-label="Delete Success Alert"
        >
          {`Event ${eventId} has been deleted successfully.`}
        </SweetAlert>
      );
      handleRefresh();
    } catch (error) {
      console.error(`Error deleting event ${eventId}:`, error); // Debugging log
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          confirmBtnText="Close"
          aria-label="Delete Error Alert"
        >
          {`Failed to delete event ${eventId}: ${error.message}`}
        </SweetAlert>
      );
    }
  };

  return (
    <Container fluid className="content">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center unmatched-events-title">Unmatched Events</h2>
        </Col>
          {/* Order Type Tabs/Pills */}
          <Nav tabs className="mb-3">
            {Object.values(ORDER_TYPES).map((type) => (
              <NavItem key={type}>
                <NavLink
                  className={classnames({ active: activeOrderType === type })}
                  onClick={() => {
                    setActiveOrderType(type);
                    setSelectedEventType(type); // Update selectedEventType to trigger data fetch
                  }}
                  href="#"
                >
                  {type === ORDER_TYPES.ALL ? 'All Events' : type.replace(/_/g, ' ')}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          {/* Date Range Selection */}
          <Row className="mb-3">
            <Col md={3}>
              <Button
                color={dateRange === '7' ? 'primary' : 'secondary'}
                onClick={() => setDateRange('7')}
                className="me-2"
                aria-pressed={dateRange === '7'}
                aria-label="Select 1 Week Date Range"
              >
                1 Week
              </Button>
              <Button
                color={dateRange === '30' ? 'primary' : 'secondary'}
                onClick={() => setDateRange('30')}
                className="me-2"
                aria-pressed={dateRange === '30'}
                aria-label="Select 30 Days Date Range"
              >
                30 Days
              </Button>
              <Button
                color={dateRange === '90' ? 'primary' : 'secondary'}
                onClick={() => setDateRange('90')}
                aria-pressed={dateRange === '90'}
                aria-label="Select 90 Days Date Range"
              >
                90 Days
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={12}>
              {chartData.length > 0 && (
                <Card className="mb-4">
                  <CardBody style={{ height: '350px' }}>
                    <h4 className="chart-title">
                      {activeOrderType === ORDER_TYPES.ALL
                        ? 'All Unmatched Events'
                        : `${activeOrderType.replace(/_/g, ' ')} Events`} 
                      Over the Last{' '}
                      {dateRange === '7' ? '1 Week' : dateRange === '30' ? '30 Days' : '90 Days'}
                    </h4>
                    <div style={{ height: '100%' }}>
                      <Line data={getChartData()} options={chartOptions} />
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xs={12} md={12}>
              <Card className="table-container">
                <CardHeader>
                  <Button
                    onClick={handleRefresh}
                    style={{ borderColor: 'var(--primary-color)' }}
                    size="sm"
                    className="btn-icon"
                    aria-label="Refresh Data"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>{' '}
                  <Button
                    color="info"
                    onClick={toggleHelpModal}
                    size="sm"
                    className="btn-icon"
                    aria-label="Open Help Modal"
                  >
                    <i className="fa fa-question-circle" title="Help" />
                  </Button>
                </CardHeader>
                <CardBody>
                  {alert}

                  {progress > 0 && (
                    <div style={{ marginBottom: '10px' }}>
                      <span>Processing: {progress.toFixed(0)}%</span>
                      <Progress value={progress} className="mb-3" />
                    </div>
                  )}

                  {/* Updated Conditional Rendering Logic */}
                  {isLoading ? (
                    <div style={{ textAlign: 'center', color: 'var(--primary-color)' }}>
                      <Spinner color="primary" /> <span>Loading...</span>
                    </div>
                  ) : isError ? (
                    <div style={{ textAlign: 'center', color: 'var(--primary-color)' }}>
                      <p>Error loading data: {error.message}</p>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={handleRefresh}
                        aria-label="Retry Fetch Data"
                      >
                        Retry
                      </Button>
                    </div>
                  ) : data.length > 0 ? (
                    <ReactTable
                      tableRoute="unmatchedEvents"
                      data={tableData}
                      filterable
                      resizable={false}
                      columns={columns}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <div style={{ textAlign: 'center', color: 'var(--primary-color)' }}>
                      <p>No unmatched events found for the selected filters.</p>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={handleRefresh}
                        aria-label="Refresh Data"
                      >
                        Refresh Data
                      </Button>{' '}
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() => setHelpModal(true)}
                        aria-label="Open Help Modal"
                      >
                        Need Help?
                      </Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {selectedEvents.length > 0 && (
            <Button
              color="success"
              className="fixed-bottom-right"
              onClick={handleRequeueSelected}
              aria-label={`Requeue ${selectedEvents.length} selected event(s)`}
            >
              Requeue {selectedEvents.length} Event(s)
            </Button>
          )}

      {/* Order Details Modal */}
      {selectedEventDetails && (
        <EventDetailsModal
          isOpen={eventDetailsModal}
          toggle={closeEventDetailsModal}
          orderDetails={selectedEventDetails}
        />
      )}

      {/* Help Modal */}
      <Modal
        isOpen={helpModal}
        toggle={toggleHelpModal}
        className="help-modal"
        aria-labelledby="help-modal-title"
      >
        <ModalHeader toggle={toggleHelpModal} id="help-modal-title">
          <h2>Help Information</h2>
          {/* Removed redundant close button as ModalHeader handles it */}
        </ModalHeader>
        <ModalBody>
          <h4>How to Use the Unmatched Events Interface</h4>
          <h5>This interface allows you to view, select, and manage unmatched events in the system.</h5>
          <ul>
            <li>
              <strong>Requeue Events:</strong> Click the requeue icon{' '}
              <Button
                id="PopoverRequeue"
                onClick={() => setPopoverOpen(!popoverOpen)}
                size="sm"
                color="secondary"
                className="btn-sm"
                aria-label="Requeue Events Popover Toggle"
              >
                ?
              </Button>{' '}
              <Popover
                placement="right"
                isOpen={popoverOpen}
                target="PopoverRequeue"
                toggle={() => setPopoverOpen(!popoverOpen)}
              >
                <PopoverHeader>Requeue Events</PopoverHeader>
                <PopoverBody>Reprocess the event by clicking the requeue button.</PopoverBody>
              </Popover>{' '}
              next to an event or select multiple events and click the "Requeue" button to reprocess them.
            </li>
            <li>
              <strong>View Event Details:</strong> Click the eye icon to view detailed information about an event.
            </li>
            <li>
              <strong>Delete Events:</strong> Click the delete icon (X) to remove an event from the system.
            </li>
            <li>
              <strong>Select Multiple Events:</strong> Use the checkboxes to select multiple events and take action on them
              together.{' '}
              <a href="/#" id="selectInfo">
                More Info
              </a>
              <UncontrolledTooltip placement="top" target="selectInfo">
                You can select multiple events by clicking the checkboxes on the left of each event.
              </UncontrolledTooltip>
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleHelpModal} aria-label="Close Help Modal">
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Error Modal for Requeue Process */}
      {pauseRequeue && (
        <Modal isOpen={pauseRequeue} aria-labelledby="requeue-error-modal-title">
          <ModalHeader id="requeue-error-modal-title">Error Occurred</ModalHeader>
          <ModalBody>
            <p>
              An error occurred during the requeue process. Would you like to continue with the remaining
              events?
            </p>
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{`Event ID: ${error.eventId}, Error: ${error.error}`}</li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={continueRequeue} aria-label="Continue Requeue Process">
              Continue
            </Button>
            <Button color="danger" onClick={() => setPauseRequeue(false)} aria-label="Cancel Requeue Process">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
}

export default UnmatchedEvents;
