/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import GroupInvestingService from '../../../Services/GroupInvestingService';

export default function MemberAccountsTable({ groupId }) {
  const memberAccountsQuery = useQuery({
    queryKey: ['memberAccounts', groupId],
    queryFn: () => GroupInvestingService.getMemberAccountsForGroup(groupId),
    placeholderData: keepPreviousData,
  });

  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-center">
        <Col xs={12} md={12}>
          {memberAccountsQuery.isLoading && <Spinner color="primary" />}
          {memberAccountsQuery.isError && (
            <Alert color="danger">Error fetching data</Alert>
          )}
          {memberAccountsQuery.isSuccess && memberAccountsQuery.data && (
            <Card className="mb-4">
              <CardBody>
                <CardTitle tag="h2">Member Accounts</CardTitle>
                <ReactTable
                  isSearchActive
                  tableRoute="groups"
                  data={
                    Array.isArray(memberAccountsQuery.data)
                      ? memberAccountsQuery.data
                      : []
                  }
                  columns={[
                    { Header: 'ID', accessor: 'id' },
                    { Header: 'Account Status', accessor: 'account_status' },
                    { Header: 'DateTime', accessor: 'createdAt' },
                    { Header: 'Account Number', accessor: 'account_num' },
                    { Header: 'User ID', accessor: 'user_id' },
                    { Header: 'Account ID', accessor: 'account_id' },
                    { Header: 'Updated At', accessor: 'updatedAt' },
                    { Header: 'Account Type', accessor: 'account_type' },
                    {
                      Header: 'FPSL',
                      accessor: 'fpsl',
                      Cell: ({ value }) => (value ? 'Yes' : 'No'),
                    },
                    {
                      Header: 'Sweep',
                      accessor: 'sweep',
                      Cell: ({ value }) => (value ? 'Yes' : 'No'),
                    },
                    { Header: 'First Name', accessor: 'first_name' },
                    { Header: 'Last Name', accessor: 'last_name' },
                    { Header: 'Email', accessor: 'email' },
                    { Header: 'Messaging Type', accessor: 'messaging_type' },
                    { Header: 'User Name', accessor: 'user_name' },
                    {
                      Header: 'PDT Protection',
                      accessor: 'pdt_protection',
                      Cell: ({ value }) => (value ? 'Yes' : 'No'),
                    },
                    {
                      Header: 'Checklist Complete',
                      accessor: 'checklist_complete',
                      Cell: ({ value }) => (value ? 'Yes' : 'No'),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}
