/* eslint-disable import/extensions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Card, Row, Col, CardHeader, CardBody, Container } from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import './TableStyles.css';
import { useParams } from 'react-router-dom';
import { getApexSOD } from 'Services/ReconciliationServices';
import { useQuery } from '@tanstack/react-query';
import SODTableComponent from './components/SODTableComponent';

function AccountsReconciliationSOD() {
  const { date } = useParams();

  const { data } = useQuery({
    queryKey: ['getApexSOD', date],
    queryFn: () => getApexSOD(date),
  });

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">{`Account Reconciliation Details For ${date}`}</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader className="table-header">Total Accounts</CardHeader>
            <CardBody>
              {data.length > 0 ? (
                <SODTableComponent sodData={data} />
              ) : (
                <h2>No Accounts Found</h2>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountsReconciliationSOD;
