import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { submitCashAdjustment } from '../../../Services/CashAdjustmentService';

const CASH_ADJUSTMENT_TYPES = Object.freeze({
  NONE: 'none',
  BUYING_POWER: 'buyingpower',
  SETTLED_CASH: 'settledcash',
  UNSETTLED_DEPOSITS: 'unsettleddeposits',
  UNSETTLED_WITHDRAWALS: 'unsettledwithdrawals',
  PENDING_ORDERS: 'pendingorders',
  ESCROW: 'escrow',
  AVAILABLE_MARGIN: 'availablemargin',
  STAGED_FUNDS: 'stagedfunds',
});

function CashBalanceUpdateForm({ accountNumber }) {
  const [amount, setAmount] = useState('');
  const [updateType, setUpdateType] = useState(CASH_ADJUSTMENT_TYPES.NONE);
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const updateBalanceMutation = useMutation({
    mutationFn: async ({
      accountNumber: adjustmentAccountNumber,
      amount: adjustmentAmount,
      type,
    }) => submitCashAdjustment(adjustmentAccountNumber, adjustmentAmount, type),
    onSuccess: () => {
      setAmount('');
      setUpdateType(CASH_ADJUSTMENT_TYPES.NONE);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success!"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          The account balance event has been sent successfully (Please validate
          the event successfully modified the user&apos;s account balance)
        </ReactBSAlert>
      );
    },
    onError: error => {
      setAlert(
        <ReactBSAlert
          error
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          {error?.message || 'Failed to update cash balance. Please try again.'}
        </ReactBSAlert>
      );
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (updateType === CASH_ADJUSTMENT_TYPES.NONE) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Warning"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="warning"
          btnSize=""
        >
          Please select an update type
        </ReactBSAlert>
      );
      return;
    }

    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => {
          hideAlert();
          updateBalanceMutation.mutate({
            accountNumber,
            amount: parseFloat(amount),
            type: updateType,
          });
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, update it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Are you sure you want to update the cash balance?
      </ReactBSAlert>
    );
  };

  return (
    <>
      {alert}
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Update Cash Balance</CardTitle>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="amount">Amount</Label>
                <Input
                  type="number"
                  step="0.01"
                  id="amount"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="updateType">Update Type</Label>
                <Input
                  type="select"
                  id="updateType"
                  value={updateType}
                  onChange={e => setUpdateType(e.target.value)}
                >
                  <option value={CASH_ADJUSTMENT_TYPES.NONE}>None</option>
                  <option value={CASH_ADJUSTMENT_TYPES.UNSETTLED_DEPOSITS}>
                    Unsettled Deposits
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.UNSETTLED_WITHDRAWALS}>
                    Unsettled Withdrawals
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.PENDING_ORDERS}>
                    Pending Orders
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.ESCROW}>Escrow</option>
                  <option value={CASH_ADJUSTMENT_TYPES.AVAILABLE_MARGIN}>
                    Available Margin
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.STAGED_FUNDS}>
                    Staged Funds
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.SETTLED_CASH}>
                    Settled Cash
                  </option>
                  <option value={CASH_ADJUSTMENT_TYPES.BUYING_POWER}>
                    Buying Power
                  </option>
                </Input>
              </FormGroup>
              <Button
                color="primary"
                type="submit"
                disabled={updateBalanceMutation.isLoading}
              >
                {updateBalanceMutation.isLoading
                  ? 'Updating...'
                  : 'Update Balance'}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default CashBalanceUpdateForm;
