import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { getReconEvents } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';

function ReconEventsList() {
  const navigate = useNavigate();
  const [nextToken, setNextToken] = useState(null);
  const [queryExecutionId, setQueryExecutionId] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [filterAccount, setFilterAccount] = useState('');
  const [filterUserId, setFilterUserId] = useState('');
  const [filterGroupId, setFilterGroupId] = useState('');
  const [filterEventSymbol, setFilterEventSymbol] = useState('');
  const [filterReconciled, setFilterReconciled] = useState('');

  const [tempFilterAccount, setTempFilterAccount] = useState(filterAccount);
  const [tempFilterUserId, setTempFilterUserId] = useState(filterUserId);
  const [tempFilterGroupId, setTempFilterGroupId] = useState(filterGroupId);
  const [tempFilterEventSymbol, setTempFilterEventSymbol] =
    useState(filterEventSymbol);
  const [tempFilterReconciled, setTempFilterReconciled] =
    useState(filterReconciled);

  const handlePaginationModelChange = useCallback(
    newPaginationModel => {
      if (newPaginationModel.page !== page) {
        setPage(newPaginationModel.page);
      }
      if (newPaginationModel.pageSize !== pageSize) {
        setPageSize(newPaginationModel.pageSize);
      }
    },
    [page, pageSize]
  );

  const handlePress = useCallback(() => {
    navigate('/reconciliation/unmatchedevents');
  }, [navigate]);

  const handleConfirmFilters = () => {
    setNextToken(null);
    setQueryExecutionId(null);
    setFilterAccount(tempFilterAccount);
    setFilterUserId(tempFilterUserId);
    setFilterGroupId(tempFilterGroupId);
    setFilterEventSymbol(tempFilterEventSymbol);
    setFilterReconciled(tempFilterReconciled);
    setPage(0);
  };

  const dgColumns = useMemo(
    () => [
      {
        field: 'reconciled',
        headerName: 'Reconciled',
        width: 150,
        renderCell: ({ value }) => (
          <span style={{ color: value ? 'green' : 'red' }}>
            {value ? '✅' : '❌'}
          </span>
        ),
      },
      {
        field: 'userid',
        headerName: 'User ID',
        width: 150,
        renderCell: ({ value }) => (
          <a
            href="#!"
            onClick={e => {
              e.preventDefault();
              setFilterUserId(value);
              setTempFilterUserId(value);
              setNextToken(null);
              setQueryExecutionId(null);
              setPage(0);
            }}
            style={{
              color: '#3182ce',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {value}
          </a>
        ),
      },
      {
        field: 'eventtime',
        headerName: 'Event Time',
        width: 150,
        renderCell: ({ value }) =>
          moment(value).format('MM/DD/YYYY HH:mm:ss A'),
      },
      {
        field: 'eventaccount',
        headerName: 'Event Account',
        width: 150,
        renderCell: ({ value }) => (
          <a
            href="#!"
            onClick={e => {
              e.preventDefault();
              setFilterAccount(value);
              setTempFilterAccount(value);
              setNextToken(null);
              setQueryExecutionId(null);
              setPage(0);
            }}
          >
            {value}
          </a>
        ),
      },
      {
        field: 'eventamt',
        headerName: 'Event Amount',
        width: 150,
      },
      {
        field: 'e1008amt',
        headerName: 'e1008 Amount',
        width: 150,
      },
      {
        field: '_eventsymbol',
        headerName: 'Event Symbol',
        width: 150,
      },
      {
        field: 'side',
        headerName: 'Side',
        width: 80,
      },
      {
        field: 'hedge_shares',
        headerName: 'Hedge Shares',
        width: 150,
      },
      {
        field: 'e1008_shares',
        headerName: 'e1008 Shares',
        width: 150,
      },
      {
        field: 'etxndrctn',
        headerName: 'eTxn Drctn',
        width: 150,
      },
      {
        field: '_eventtype',
        headerName: 'Event Type',
        width: 150,
      },
      {
        field: '_subtype',
        headerName: 'Subtype',
        width: 150,
      },
      {
        field: '_eventstatus',
        headerName: 'Event Status',
        width: 150,
      },
      {
        field: 'recon_type',
        headerName: 'Recon Type',
        width: 150,
      },
      {
        field: 'delta_cashledger',
        headerName: 'Delta Cash Ledger',
        width: 150,
      },
      {
        field: 'prev_bp',
        headerName: 'Previous BP',
        width: 150,
      },
      {
        field: 'bpupdate',
        headerName: 'BP Update',
        width: 150,
      },
      {
        field: 'new_bp',
        headerName: 'New BP',
        width: 150,
      },
      {
        field: 'prev_cash',
        headerName: 'Previous Cash',
        width: 150,
      },
      {
        field: 'cashupdate',
        headerName: 'Cash Update',
        width: 150,
      },
      {
        field: 'new_cash',
        headerName: 'New Cash',
        width: 150,
      },
      {
        field: 'delta_posledger',
        headerName: 'Delta Pos Ledger',
        width: 150,
      },
      {
        field: 'prev_shares_owned',
        headerName: 'Previous Shares Owned',
        width: 150,
      },
      {
        field: 'delta_sharesowned',
        headerName: 'Delta Shares Owned',
        width: 150,
      },
      {
        field: 'new_shares_owned',
        headerName: 'New Shares Owned',
        width: 150,
      },
      {
        field: 'pendingbuysharesupdate',
        headerName: 'Pending Buy Shares Update',
        width: 150,
      },
      {
        field: 'unsettledsharesupdate',
        headerName: 'Unsettled Shares Update',
        width: 150,
      },
      {
        field: 'settledsharesupdate',
        headerName: 'Settled Shares Update',
        width: 150,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 150,
      },
      {
        field: 'groupid',
        headerName: 'Group ID',
        width: 150,
        renderCell: ({ value }) => (
          <a
            href="#!"
            onClick={e => {
              e.preventDefault();
              setFilterGroupId(value);
              setTempFilterGroupId(value);
              setNextToken(null);
              setQueryExecutionId(null);
              setPage(0);
            }}
          >
            {value}
          </a>
        ),
      },
      {
        field: '_ig_group_name',
        headerName: 'IG Group Name',
        width: 150,
      },
      {
        field: '_eventacct_1008acct',
        headerName: 'Event Acct 1008 Acct',
        width: 150,
      },
      {
        field: '_eventsymbol_1008symbol',
        headerName: 'Event Symbol 1008 Symbol',
        width: 150,
      },
      {
        field: 'etxntime',
        headerName: 'eTxn Time',
        width: 150,
        renderCell: ({ value }) =>
          moment(value).format('MM/DD/YYYY HH:mm:ss A'),
      },
      {
        field: 'etype',
        headerName: 'eType',
        width: 150,
      },
      {
        field: 'esymbol',
        headerName: 'eSymbol',
        width: 150,
      },
      {
        field: 'eside',
        headerName: 'eSide',
        width: 150,
      },
      {
        field: 'eshares',
        headerName: 'eShares',
        width: 150,
      },
      {
        field: 'eprice',
        headerName: 'ePrice',
        width: 150,
      },
      {
        field: 'enetamount',
        headerName: 'eNet Amount',
        width: 150,
      },
      {
        field: 'etradedate',
        headerName: 'eTrade Date',
        width: 150,
        renderCell: ({ value }) => moment(value).format('MM/DD/YYYY'),
      },
      {
        field: 'esettlementdate',
        headerName: 'eSettlement Date',
        width: 150,
        renderCell: ({ value }) => moment(value).format('MM/DD/YYYY'),
      },
      {
        field: 'ebatchcode',
        headerName: 'eBatch Code',
        width: 150,
      },
      {
        field: 'eentrycode',
        headerName: 'eEntry Code',
        width: 150,
      },
      {
        field: 'edept',
        headerName: 'eDept',
        width: 150,
      },
      {
        field: 'ebatchentrydesc',
        headerName: 'eBatch Entry Desc',
        width: 150,
      },
      {
        field: 'efees',
        headerName: 'eFees',
        width: 150,
      },
      {
        field: 'ecommissions',
        headerName: 'eCommissions',
        width: 150,
      },
      {
        field: 'etrailer',
        headerName: 'eTrailer',
        width: 150,
      },
      {
        field: 'edesc1',
        headerName: 'eDesc1',
        width: 150,
      },
      {
        field: 'edesc2',
        headerName: 'eDesc2',
        width: 150,
      },
      {
        field: 'edesc3',
        headerName: 'eDesc3',
        width: 150,
      },
      {
        field: 'edesc4',
        headerName: 'eDesc4',
        width: 150,
      },
      {
        field: 'edesc5',
        headerName: 'eDesc5',
        width: 150,
      },
      {
        field: 'edesc6',
        headerName: 'eDesc6',
        width: 150,
      },
      {
        field: 'edesc7',
        headerName: 'eDesc7',
        width: 150,
      },
      {
        field: 'edesc8',
        headerName: 'eDesc8',
        width: 150,
      },
      {
        field: 'edivrecorddate',
        headerName: 'eDiv Record Date',
        width: 150,
      },
      {
        field: 'edivpaydate',
        headerName: 'eDiv Pay Date',
        width: 150,
      },
      {
        field: 'edivrate',
        headerName: 'eDiv Rate',
        width: 150,
      },
      {
        field: 'e1008_divposition',
        headerName: 'e1008 Div Position',
        width: 150,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: () => (
          <div className="actions-right">
            <Button
              onClick={() => {
                handlePress();
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>
          </div>
        ),
      },
    ],
    [handlePress]
  );

  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: [
      'reconEvents',
      filterAccount,
      filterUserId,
      filterEventSymbol,
      filterReconciled,
      filterGroupId,
      pageSize,
      page,
    ],
    queryFn: () =>
      getReconEvents({
        userid: filterUserId,
        accountNum: filterAccount,
        groupid: filterGroupId,
        eventSymbol: filterEventSymbol,
        reconciled: filterReconciled,
        nextToken,
        queryExecutionId,
        pageSize,
        page,
      }),
    placeholderData: keepPreviousData,
  });
  useEffect(() => {
    if (isSuccess) {
      if (data?.queryExecutionId !== queryExecutionId) {
        setQueryExecutionId(data?.queryExecutionId);
      }
      if (data?.nextToken !== nextToken) {
        setNextToken(data?.nextToken);
      }
    }
  }, [isSuccess, data, queryExecutionId, nextToken]);

  const handleRefresh = () => {
    setNextToken(null);
    setQueryExecutionId(null);
    setPage(0);
    setPageSize(50);
    setFilterAccount('');
    setFilterUserId('');
    setFilterGroupId('');
    setFilterEventSymbol('');
    setFilterReconciled('');
  };

  return (
    <Container fluid className="content">
      <Col
        md={12}
        className="ml-auto mr-auto"
        style={{ margin: '0 auto', width: '100%' }}
      >
        <h2
          className="text-center text-white"
          style={{ textAlign: 'center', color: '#ffffff' }}
        >
          Recon Events
        </h2>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader
                className="d-flex flex-column justify-content-between"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.75rem 1.25rem',
                  backgroundColor: '#1a202c',
                  color: '#ffffff',
                }}
              >
                <Form
                  inline
                  className="d-flex flex-wrap align-items-center mb-2"
                >
                  <FormGroup className="mr-3 mb-2">
                    <Label for="filterAccount" className="mr-2">
                      Account:
                    </Label>
                    <Input
                      type="text"
                      id="filterAccount"
                      value={tempFilterAccount}
                      onChange={e => setTempFilterAccount(e.target.value)}
                      placeholder="Filter by Account"
                      style={{
                        minWidth: '150px',
                        backgroundColor: '#4a5568',
                        color: '#ffffff',
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mr-3 mb-2">
                    <Label for="filterUserId" className="mr-2">
                      User ID:
                    </Label>
                    <Input
                      type="text"
                      id="filterUserId"
                      value={tempFilterUserId}
                      onChange={e => setTempFilterUserId(e.target.value)}
                      placeholder="Filter by User ID"
                      style={{
                        minWidth: '150px',
                        backgroundColor: '#4a5568',
                        color: '#ffffff',
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mr-3 mb-2">
                    <Label for="filterEventSymbol" className="mr-2">
                      Event Symbol:
                    </Label>
                    <Input
                      type="text"
                      id="filterEventSymbol"
                      value={tempFilterEventSymbol}
                      onChange={e => setTempFilterEventSymbol(e.target.value)}
                      placeholder="Filter by Event Symbol"
                      style={{
                        minWidth: '150px',
                        backgroundColor: '#4a5568',
                        color: '#ffffff',
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="mr-3 mb-2">
                    <Label for="filterGroupId" className="mr-2">
                      Group ID:
                    </Label>
                    <Input
                      type="text"
                      id="filterGroupId"
                      value={tempFilterGroupId}
                      onChange={e => setTempFilterGroupId(e.target.value)}
                      placeholder="Filter by Group ID"
                      style={{
                        minWidth: '150px',
                        backgroundColor: '#4a5568',
                        color: '#ffffff',
                      }}
                    />
                  </FormGroup>

                  <FormGroup className="mr-3 mb-2">
                    <Label for="filterReconciled" className="mr-2">
                      Reconciled:
                    </Label>
                    <Input
                      type="select"
                      id="filterReconciled"
                      value={tempFilterReconciled}
                      onChange={e => setTempFilterReconciled(e.target.value)}
                      style={{
                        minWidth: '120px',
                        backgroundColor: '#4a5568',
                        color: '#ffffff',
                      }}
                    >
                      <option value="">All</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Input>
                  </FormGroup>

                  <Button
                    color="primary"
                    size="sm"
                    className="mb-2"
                    onClick={handleConfirmFilters}
                  >
                    Confirm
                  </Button>
                </Form>
                <Button onClick={handleRefresh} color="secondary" size="sm">
                  <i className="tim-icons icon-refresh-01" />
                </Button>
              </CardHeader>
              <CardBody>
                <DataGrid
                  rows={data?.items || []}
                  loading={isLoading || isFetching}
                  getRowId={() => uuidv4()}
                  columns={dgColumns}
                  rowCount={data?.totalCount || 0}
                  pageSizeOptions={[50, 100]}
                  paginationModel={{ page, pageSize }}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationMode="server"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default ReconEventsList;
