/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable function-paren-newline */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getAllOrders } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function Orders() {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    nextToken: null,
    queryExecutionId: null,
  });

  const fetchOrders = async ({ pageSize, nextToken, queryExecutionId }) => {
    const params = { pageSize, nextToken, queryExecutionId };
    try {
      const response = await getAllOrders(params);
      if (!response || !response.items) {
        console.error('Invalid response received:', response);
        return { items: [], nextToken: null, queryExecutionId: null };
      }
      return response;
    } catch (error) {
      console.error('Error fetching orders:', error);
      return { items: [], nextToken: null, queryExecutionId: null };
    }
  };

  const handlePress = useCallback(
    externalId => {
      navigate(`/orders/${externalId}`);
    },
    [navigate]
  );

  const { refetch, isFetching, isSuccess, data } = useQuery({
    queryKey: ['getAllOrders', pagination],
    queryFn: () => fetchOrders(pagination),
    keepPreviousData: true,
  });

  useEffect(() => {
    if (isSuccess) {
      setOrderData(prevData => [
        ...prevData,
        ...data.items.map(item => ({
          type:
            item.type ||
            (item.externalid.slice(0, -9).startsWith('3HD')
              ? 'Member'
              : item.account === '3HD00001'
                ? 'APA'
                : 'Personal'),
          status: item.status,
          account: item.account,
          externalId: item.externalid,
          symbol: item.symbol,
          side: item.side,
          shareQuantity: item.sharequantity,
          avgPrice: item.avgprice,
          notional: item.notional,
          uuid: item.id,
          dateSubmitted: moment(item.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          lastUpdated: moment(item.datetime).format('MM/DD/YYYY HH:mm:ss A'),
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  handlePress(item.externalid);
                }}
                color="secondary"
                size="sm"
                aria-label={`View details for order ${item.externalid}`}
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        })),
      ]);
      setPagination({
        ...pagination,
        nextToken: data.nextToken || null,
        queryExecutionId: data.queryExecutionId || null,
      });
    }
  }, [isSuccess, data, handlePress, pagination]);

  const handleRefresh = () => {
    setOrderData([]);
    setPagination({
      pageSize: pagination.pageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handlePageSizeChange = newPageSize => {
    setOrderData([]);
    setPagination({
      pageSize: newPageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handleLoadMore = () => {
    if (pagination.nextToken) {
      refetch();
    } else {
      console.warn('No more data to load.');
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Kind',
        accessor: 'type',
      },
      {
        Header: 'Overall Status',
        accessor: 'status',
      },
      {
        Header: 'Account',
        accessor: 'account',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Side',
        accessor: 'side',
      },
      {
        Header: 'Share Quantity',
        accessor: 'shareQuantity',
      },
      {
        Header: 'Avg Price',
        accessor: 'avgPrice',
      },
      {
        Header: 'Notional',
        accessor: 'notional',
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
      },
      {
        Header: 'UUID',
        accessor: 'uuid',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
      },
      {
        Header: 'Details',
        accessor: 'actions',
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  return (
    <Container fluid className="content">
      <Row className="justify-content-center">
        <Col md={6}>
          <h2 className="text-center">Orders</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <Form inline className="d-flex align-items-center">
                <FormGroup className="mb-0 mr-3">
                  <Label for="pageSize" className="mr-2">
                    Page Size:
                  </Label>
                  <Input
                    type="select"
                    name="pageSize"
                    id="pageSize"
                    value={pagination.pageSize}
                    onChange={e => handlePageSizeChange(Number(e.target.value))}
                    style={{ width: '100px' }}
                  >
                    <option value={100}>100</option>
                    <option value={50}>50</option>
                    <option value={25}>25</option>
                    <option value={10}>10</option>
                  </Input>
                </FormGroup>
                <Button onClick={handleRefresh} color="secondary" size="sm">
                  <i className="tim-icons icon-refresh-01" />
                </Button>
              </Form>
            </CardHeader>
            <CardBody>
              {orderData.length > 0 ? (
                <>
                  <ReactTable
                    data={orderData}
                    filterable
                    columns={columns}
                    defaultPageSize={pagination.pageSize}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                  {pagination.nextToken && (
                    <div className="text-center mt-3">
                      <Button
                        onClick={handleLoadMore}
                        color="secondary"
                        size="sm"
                        disabled={isFetching}
                      >
                        Load More
                      </Button>
                    </div>
                  )}
                </>
              ) : orderData.length === 0 ? (
                <div className="text-center">Loading...</div>
              ) : (
                <div className="text-center">No data available</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Orders;
