/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getIndvGroupOrdersByExtOrApaId } from 'Services/AccountServices';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function GroupOrderDetailExtApaOrderId() {
  const navigate = useNavigate();
  // eslint-disable-next-line camelcase
  const { orderUuid } = useParams();
  // eslint-disable-next-line no-console
  console.log('Captured orderUuid from useParams:', orderUuid);

  // eslint-disable-next-line camelcase
  const handlePress = useCallback(
    externalid => {
      // eslint-disable-next-line camelcase
      navigate(`/orders/${externalid}`);
    },
    [navigate]
  );

  // eslint-disable-next-line no-unused-vars
  const getAllGroupOrdersByExtOrApaIdsQuery = useQuery({
    queryKey: ['getIndvGroupOrdersByExtOrApaId', orderUuid],
    queryFn: () => getIndvGroupOrdersByExtOrApaId(orderUuid),
  });

  useEffect(() => {
    if (getAllGroupOrdersByExtOrApaIdsQuery.isSuccess) {
      setData(
        getAllGroupOrdersByExtOrApaIdsQuery.data.map((prop, key) => ({
          id: key,
          lastUpdated: prop.datetime
            ? moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A')
            : 'N/A',
          status: prop.status || 'Unknown',
          account: prop.account,
          symbol: prop.symbol,
          side: prop.side,
          shareQuantity: prop.sharequantity,
          avgPrice: prop.avgprice,
          notional: prop.notional,
          uuid: prop.id,
          source: prop.source,
          type: prop.type,
          ordertype: prop.ordertype,
          externalId: prop.externalid,
          apaOrderId: prop.apaorderid,
          dateSubmitted: moment(prop.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  // eslint-disable-next-line no-console
                  handlePress(
                    (prop.apaOrderId &&
                    prop.apaorderid !== '' &&
                    prop.apaorderid !== undefined
                      ? prop.apaorderid
                      : prop.orderUuid) || prop.externalId
                  );
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        }))
      );
    }
  }, [getAllGroupOrdersByExtOrApaIdsQuery, handlePress]);

  const [data, setData] = React.useState(null);

  function handleRefresh() {
    getAllGroupOrdersByExtOrApaIdsQuery.refetch();
  }
  const orderUuidEnd = orderUuid;

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">
          Group Order Details for id: {/* eslint-disable-next-line camelcase */}
          {orderUuidEnd || data.externalId}
        </h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {data && data.length > 0 ? (
                <ReactTable
                  tableRoute="acats"
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Overall Status',
                      accessor: 'status',
                    },
                    {
                      Header: 'Account',
                      accessor: 'account',
                    },
                    {
                      Header: 'Symbol',
                      accessor: 'symbol',
                    },
                    {
                      Header: 'Side',
                      accessor: 'side',
                    },
                    {
                      Header: 'Share Quantity',
                      accessor: 'shareQuantity',
                    },
                    {
                      Header: 'Avg Price',
                      accessor: 'avgPrice',
                    },
                    {
                      Header: 'Notional',
                      accessor: 'notional',
                    },
                    {
                      Header: 'Source',
                      accessor: 'source',
                    },
                    {
                      Header: 'Type',
                      accessor: 'type',
                    },
                    {
                      Header: 'External ID',
                      accessor: 'externalId',
                    },
                    {
                      Header: 'APA Order ID',
                      accessor: 'apaOrderId',
                    },
                    {
                      Header: 'Date Submitted',
                      accessor: 'dateSubmitted',
                    },
                    {
                      Header: 'Details',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              ) : (
                <span> working on it... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GroupOrderDetailExtApaOrderId;
