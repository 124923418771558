/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';

const totalStyle = { paddingBottom: '15px' };

export default function TableStats(props) {
  const [numTickers, setNumTickers] = useState(0);
  const [numCs, setNumCs] = useState(0);
  const [numETFs, setNumETFs] = useState(0);
  const [numFunds, setNumFunds] = useState(0);
  const [numETNs, setNumETNs] = useState(0);
  const [numETS, setNumETS] = useState(0);
  const [numADRC, setNumADRC] = useState(0);

  const updateTotals = useCallback(() => {
    let numTickers = 0;
    let numCs = 0;
    let numETFs = 0;
    let numFunds = 0;
    let numETNs = 0;
    let numETS = 0;
    let numADRC = 0;

    props.api.forEachNode(rowNode => {
      const { data } = rowNode;
      numTickers += 1;
      if (data.type === 'CS') {
        numCs += 1;
      } else if (data.type === 'ETF') {
        numETFs += 1;
      } else if (data.type === 'FUND') {
        numFunds += 1;
      } else if (data.type === 'ETN') {
        numETNs += 1;
      } else if (data.type === 'ETS') {
        numETS += 1;
      } else if (data.type === 'ADRC') {
        numADRC += 1;
      }
    });

    setNumTickers(numTickers);
    setNumCs(numCs);
    setNumETFs(numETFs);
    setNumFunds(numFunds);
    setNumETNs(numETNs);
    setNumETS(numETS);
    setNumADRC(numADRC);
  }, [props.api]);

  useEffect(() => {
    props.api.addEventListener('modelUpdated', updateTotals);

    return () => props.api.removeEventListener('modelUpdated', updateTotals);
  }, [props.api, updateTotals]);

  return (
    <div style={{ textAlign: 'center' }}>
      <span>
        <dl style={{ fontSize: 'large', padding: '30px 40px 10px 30px' }}>
          <dt style={totalStyle}>
            Total Stocks: <b>{numTickers}</b>
          </dt>
          <dt style={totalStyle}>
            Total CS: <b>{numCs}</b>
          </dt>
          <dt style={totalStyle}>
            Total ETFs: <b>{numETFs}</b>
          </dt>
          <dt style={totalStyle}>
            Total ETNs: <b>{numETNs}</b>
          </dt>
          <dt style={totalStyle}>
            Total Funds: <b>{numFunds}</b>
          </dt>
          <dt style={totalStyle}>
            Total ETS: <b>{numETS}</b>
          </dt>
          <dt style={totalStyle}>
            Total ADRC: <b>{numADRC}</b>
          </dt>
        </dl>
      </span>
    </div>
  );
}
