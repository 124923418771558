import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import SimpleTable from '../Components/SortingTable/SortingTable';

export default function DNDBProfilesDetails() {
  const { requestid } = useParams();

  // eslint-disable-next-line no-unused-vars
  const dndbProfilesDetailsTable = null;

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">
            DNDB Profile Details For Request
            {requestid}
          </h2>
        </Col>
        {dndbProfilesDetailsTable ? (
          dndbProfilesDetailsTable.map(item => (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        DNDB Profile Details For
                        {item.Name}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Field' }, { text: 'Value' }]}
                        tbody={[
                          { data: [{ text: 'Name' }, { text: item.Name }] },
                          {
                            data: [
                              { text: 'Certainty' },
                              { text: item.Certainty },
                            ],
                          },
                          {
                            data: [
                              { text: 'Match Category' },
                              { text: item.MatchCategory },
                            ],
                          },
                          {
                            data: [
                              { text: 'Additional Names' },
                              { text: item.AdditionalNames },
                            ],
                          },
                          {
                            data: [
                              { text: 'Social Security Number' },
                              { text: item.SocialSecurityNumber },
                            ],
                          },
                          {
                            data: [
                              { text: 'Date of Birth' },
                              { text: item.DateOfBirth },
                            ],
                          },
                          {
                            data: [
                              { text: 'Telephone' },
                              { text: item.Telephone },
                            ],
                          },
                          { data: [{ text: 'Email' }, { text: item.Email }] },
                          {
                            data: [
                              { text: 'Business Name' },
                              { text: item.BusinessName },
                            ],
                          },
                          { data: [{ text: 'TIN' }, { text: item.TIN }] },
                          { data: [{ text: 'ID' }, { text: item.ID }] },
                          {
                            data: [
                              { text: 'Comments' },
                              { text: item.Comments },
                            ],
                          },
                          {
                            data: [
                              { text: 'Created By' },
                              { text: item.CreatedBy },
                            ],
                          },
                          {
                            data: [
                              { text: 'Created Date' },
                              { text: item.CreatedDate },
                            ],
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <span> loading... </span>
        )}
      </div>
    </div>
  );
}
