import React, { useEffect, useState } from 'react';
import { Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function FinancialProfileTable({ accountNumber }) {
  const [financialProfileTable, setFinancialProfileTable] = useState([]);

  const { isLoading, isError, error, data, isSuccess } = useQuery({
    queryKey: ['getApexAccountDetails', accountNumber],
    queryFn: () => getApexAccountDetails(accountNumber),
  });

  useEffect(() => {
    if (isSuccess) {
      const financialProfile = [
        {
          label: 'Net Worth',
          value: data?.financialProfile?.netWorth || 'N/A',
        },
        {
          label: 'Liquid Net Worth',
          value: data?.financialProfile?.liquidNetWorth || 'N/A',
        },
        {
          label: 'Annual Income',
          value: data?.financialProfile?.annualIncome || 'N/A',
        },
      ];
      setFinancialProfileTable(financialProfile);
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <p>Loading financial profile...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load financial profile.'}
      </p>
    );
  }

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Financial Profile For:
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {financialProfileTable.length > 0 ? (
            <SimpleTable
              thead={[{ text: 'Label' }, { text: 'Value' }]}
              tbody={financialProfileTable.map(row => ({
                data: [{ text: row.label }, { text: row.value }],
              }))}
            />
          ) : (
            <p>No financial profile data available.</p>
          )}
        </CardBody>
      </Card>
    </Col>
  );
}

export default FinancialProfileTable;
