/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function GroupDetailsBox({ groupId }) {
  const { data } = useQuery({
    queryKey: ['groupDetails', groupId],
    queryFn: () =>
      GroupInvestingService.searchInvestingGroups(`groupId=${groupId}`),
  });

  return (
    <Card className="bg-dark text-white shadow-lg">
      {data === null && <CardBody>Loading...</CardBody>}
      {data && (
        <CardBody>
          <CardTitle tag="h2" className="text-center mb-4">
            {data.group_name}
          </CardTitle>
          <Row className="mb-3">
            <Col md="6">
              <CardText className="h4">
                <strong>Description:</strong> {data.description}
              </CardText>
              <CardText className="h4">
                <strong>Voting Threshold:</strong> {data.voting_threshold}
              </CardText>
              <CardText className="h4">
                <strong>Risk Tolerance:</strong> {data.risk_tolerance}
              </CardText>
            </Col>
            <Col md="6">
              <CardText className="h4">
                <strong>Auto Profit or Loss:</strong> {data.auto_profit_or_loss}
              </CardText>
              <CardText className="h4">
                <strong>Status:</strong> {data.status}
              </CardText>
              <CardText className="h4">
                <strong>Horizon:</strong> {data.horizon}
              </CardText>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="6">
              <CardText className="h4">
                <strong>Group Size Min:</strong> {data.group_size_min}
              </CardText>
              <CardText className="h4">
                <strong>Group Size Max:</strong> {data.group_size_max}
              </CardText>
            </Col>
            <Col md="6">
              <CardText className="h4">
                <strong>Created At:</strong>{' '}
                {new Date(data.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  fractionalSecondDigits: 3,
                })}
              </CardText>
              <CardText className="h4">
                <strong>Updated At:</strong>{' '}
                {new Date(data.updatedAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  fractionalSecondDigits: 3,
                })}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}
