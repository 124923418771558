/* eslint-disable import/no-unresolved */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getAllTickersWithAdminStatus } from 'Services/TickerService';
import ReactTable from '../Components/ReactTable/ReactTable';
import UpdateTickerModal from './components/UpdateStatusModal';

function TickerStatus() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState({});

  function handlePress(prop) {
    setSelectedTicker(prop);
    setModalOpen(true);
  }

  const [data, setData] = useState();

  // eslint-disable-next-line no-unused-vars
  const {
    data: tickerData,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['getAllTickersWithAdminStatus'],
    queryFn: () => getAllTickersWithAdminStatus(),
  });

  function handleRefresh() {
    // eslint-disable-next-line no-use-before-define
    refetch();
  }

  useEffect(() => {
    if (isSuccess) {
      setData(
        tickerData.map((prop, key) => ({
          id: key,
          market: prop.market.S,
          messageType: prop.messageType.S,
          symbol: prop.symbol.S,
          timestamp: prop.timestamp.S,
          tradingState: prop.tradingState.S,
          tradingStateReason:
            prop.tradingStateReason.S === ''
              ? 'N/A'
              : prop.tradingStateReason.S,
          internalAvailableForTrade:
            prop?.internalAvailableForTrade?.BOOL !== undefined
              ? prop.internalAvailableForTrade.BOOL
                ? 'True'
                : 'False'
              : '',
          internalSellOnly:
            prop?.internalSellOnly?.BOOL !== undefined
              ? prop.internalSellOnly.BOOL
                ? 'True'
                : 'False'
              : '',
          internalFractionalAllowed:
            prop?.internalFractionalAllowed?.BOOL !== undefined
              ? prop.internalFractionalAllowed.BOOL
                ? 'True'
                : 'False'
              : '',
          latestInternalUpdateTimestamp: prop?.latestInternalUpdateTimestamp?.S,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  handlePress(prop);
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        }))
      );
    }
  }, [isSuccess, tickerData]);

  return (
    <>
      <UpdateTickerModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        ticker={selectedTicker}
        refetch={handleRefresh}
      />
      <Container fluid className="content">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Ticker Statuses</h2>
        </Col>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <Button
                  onClick={() => {
                    handleRefresh();
                  }}
                  color="#000000"
                  size="sm"
                >
                  <i className="tim-icons icon-refresh-01" />
                </Button>{' '}
              </CardHeader>
              <CardBody>
                {data && data.length > 0 ? (
                  <ReactTable
                    tableRoute="tickerStatuses"
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: 'Symbol',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Trading State',
                        accessor: 'tradingState',
                      },
                      {
                        Header: 'Trading State Reason',
                        accessor: 'tradingStateReason',
                      },
                      {
                        Header: 'Market',
                        accessor: 'market',
                      },
                      {
                        Header: 'Message Type',
                        accessor: 'messageType',
                      },
                      {
                        Header: 'Timestamp',
                        accessor: 'timestamp',
                      },
                      {
                        Header: 'Internal Available For Trade',
                        accessor: 'internalAvailableForTrade',
                      },
                      {
                        Header: 'Internal Sell Only',
                        accessor: 'internalSellOnly',
                      },
                      {
                        Header: 'Internal Fractional Allowed',
                        accessor: 'internalFractionalAllowed',
                      },
                      {
                        Header: 'Latest Internal Update Timestamp',
                        accessor: 'latestInternalUpdateTimestamp',
                        canFilter: true,
                      },
                      {
                        Header: 'Update Internal Status',
                        accessor: 'actions',
                        turnFilterOff: true,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                ) : (
                  <span> loading... </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TickerStatus;
