/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useRef, useState, useCallback } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getAllApexWithSketch, searchUsers } from 'Services/AccountServices';
import { AgGridReact } from 'ag-grid-react';
import SearchBar from 'Screens/Whitelist/components/SearchBar';
import { DataGrid } from '@mui/x-data-grid';
import UpdateAccountStateModal from './components/UpdateAccountStateModal';

function Accounts() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const gridRef = useRef();
  const [chartData, setChartData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleRowDoubleClick = event => {
    setSelectedAccount(event.data);
    setModalOpen(true);
  };

  const columnDefs = [
    { field: 'user_name', filter: true, headerName: 'Cognito Id' },
    { field: 'user_id', filter: true, headerName: 'User Id' },
    { field: 'first_name', filter: true, headerName: 'First Name' },
    { field: 'last_name', filter: true, headerName: 'Last Name' },
    { field: 'email', filter: true, headerName: 'Email' },
    { field: 'username', filter: true, headerName: 'Username' },
    { field: 'location', filter: true, headerName: 'Location' },
    { field: 'join_date', filter: true, headerName: 'Join Date' },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const onSearch = async searchText => {
    const res = await searchUsers(searchText);
    setChartData(res);
  };

  const handlePress = useCallback(
    accountNumber => {
      navigate(`/accounts/${accountNumber}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: 'account_id',
        headerName: 'Account ID',
        width: 150,
      },
      {
        field: 'accountnumber',
        headerName: 'Account Number',
        width: 150,
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        width: 150,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        width: 150,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 150,
      },
      {
        field: 'state',
        headerName: 'State',
        width: 150,
      },
      {
        field: 'originaldatetime',
        headerName: 'Date Submitted',
        width: 150,
      },
      {
        field: 'view',
        headerName: 'View',
        width: 150,
        renderCell: params => (
          <Button
            onClick={() => handlePress(params.row.accountnumber)}
            color="secondary"
            size="sm"
          >
            <i className="tim-icons icon-double-right" />
          </Button>
        ),
      },
    ],
    [handlePress]
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getAllApexWithSketch'],
    queryFn: () => getAllApexWithSketch(),
  });

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'customStats',
    }),
    []
  );

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      <UpdateAccountStateModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        account={selectedAccount}
      />
      <Container fluid className="content">
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="text-center">Accounts</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Card>
              <CardHeader className="d-flex justify-content-end">
                <Button onClick={handleRefresh} color="secondary" size="sm">
                  <i className="tim-icons icon-refresh-01" />
                </Button>
              </CardHeader>
              <CardBody>
                <DataGrid
                  rows={data}
                  loading={isLoading}
                  getRowId={row => row.account_id}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mt-4">
          <Col md={8}>
            <h1>User Search ~ Update Internal State</h1>
            <h3>
              (Double click a row to update the internal state of the user)
            </h3>
          </Col>
        </Row>
        <Row className="mt-2 mb-4">
          <Col>
            <SearchBar
              onSearch={onSearch}
              whitelistQuery={{ data: [] }}
              setChartData={setChartData}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="bg-zinc-800" style={{ height: '1500px' }}>
              <CardBody className="p-0">
                <AgGridReact
                  className="ag-theme-alpine"
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows
                  rowSelection="multiple"
                  rowData={chartData}
                  onRowDoubleClicked={handleRowDoubleClick}
                  sideBar={sideBar}
                  style={{ width: '100%', height: '100%' }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Accounts;
