/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';

function formatCurrency(value) {
    // Graceful fallback if value is undefined or null
    const num = Number(value ?? 0);
    return num.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

export default function UserAumTables({ userAumData }) {
    if (!userAumData) {
        return null;
    }

  const { userPositions, userTotalAccountBalances, userAccountBalance } = userAumData;

  const positionsData = userPositions.positions
    ? Object.keys(userPositions.positions).map(ticker => ({
        ticker,
        ...userPositions.positions[ticker],
        marketValue: formatCurrency(userPositions.positions[ticker].marketValue),
        lastPrice: formatCurrency(userPositions.positions[ticker].lastPrice),
      }))
    : [];

  const positionBalanceArrayPerAccount =
    userPositions?.positionBalanceArrayPerAccount || [];
  
    const totalBalancesArray = userTotalAccountBalances
    ? [
        {
          userTotalBuyingPower: formatCurrency(
            userTotalAccountBalances.userTotalBuyingPower
          ),
          userTotalCash: formatCurrency(userTotalAccountBalances.userTotalCash),
          userTotalUnsettledDeposits: formatCurrency(
            userTotalAccountBalances.userTotalUnsettledDeposits
          ),
          userTotalUnsettledWithdrawals: formatCurrency(
            userTotalAccountBalances.userTotalUnsettledWithdrawals
          ),
          userTotalPendingOrderDebits: formatCurrency(
            userTotalAccountBalances.userTotalPendingOrderDebits
          ),
          userTotalPendingOrderCredits: formatCurrency(
            userTotalAccountBalances.userTotalPendingOrderCredits
          ),
          userTotalAvailableMargin: formatCurrency(
            userTotalAccountBalances.userTotalAvailableMargin
          ),
          userTotalEscrow: formatCurrency(
            userTotalAccountBalances.userTotalEscrow
          ),
          userTotalSentForSettlement: formatCurrency(
            userTotalAccountBalances.userTotalSentForSettlement
          ),
        }
      ]
    : [];

    const totalBalancesColumns = [
        { Header: 'Total Buying Power', accessor: 'userTotalBuyingPower' },
        { Header: 'Total Cash', accessor: 'userTotalCash' },
        {
          Header: 'Unsettled Deposits',
          accessor: 'userTotalUnsettledDeposits',
        },
        {
          Header: 'Unsettled Withdrawals',
          accessor: 'userTotalUnsettledWithdrawals',
        },
        {
          Header: 'Pending Order Debits',
          accessor: 'userTotalPendingOrderDebits',
        },
        {
          Header: 'Pending Order Credits',
          accessor: 'userTotalPendingOrderCredits',
        },
        {
            Header: 'Available Margin',
            accessor: 'userTotalAvailableMargin',
        },
        {
            Header: 'Escrow',
            accessor: 'userTotalEscrow',
        },
        {
            Header: 'Sent for settlement',
            accessor: 'userTotalSentForSettlement',
        },
      ];

    
    const accountBalances = userAccountBalance?.accountBalanceArrayPerAccount ?? [];


    
    const accountBalanceColumns = [
      { Header: 'Apex Account', accessor: 'apexAccount' },
      { Header: 'Total Value', accessor: 'totalValue' },
      { Header: 'Total Cash', accessor: 'totalCash' },
      { Header: 'Buying Power', accessor: 'totalBuyingPower' },
      { Header: 'Unsettled Deposits', accessor: 'totalUnsettledDeposits' },
      { Header: 'Unsettled Withdrawals', accessor: 'totalUnsettledWithdrawals' },
      { Header: 'Pending Debits', accessor: 'totalPendingOrderDebits' },
      { Header: 'Pending Credits', accessor: 'totalPendingOrderCredits' },
    ];
    
    const accountPositionColumns = [
        { Header: 'ApexAccount', accessor: 'apexAccount' },
        { Header: 'Ticker', accessor: 'tickerSymbol' },
        { Header: 'Settled', accessor: 'settledShareQuantity' },
        { Header: 'Unsettled', accessor: 'unsettledShareQuantity' },
        {
        Header: 'Market Value',
        accessor: 'marketValue',
        Cell: ({ value }) => formatCurrency(value),
        },
        {
        Header: 'Last Price',
        accessor: 'lastPrice',
        Cell: ({ value }) => formatCurrency(value),
        },
        { Header: 'Queued', accessor: 'queued' },
        { Header: 'Available Shares To Sell', accessor: 'availableSharesToSell' },
        {
        Header: 'Pending Buy Qty Mkt Value',
        accessor: 'pendingBuyMarketValue',
        Cell: ({ value }) => formatCurrency(value),
        },
    ];

  return (
    <div>
      <div>
        {/* 1) Top-Level Totals */}
        {totalBalancesArray.length > 0 && (
            <div className="mb-5">
            <h2 className="text-center mb-4">User Total Account Balances</h2>
            <ReactTable
                tableRoute="users"
                data={totalBalancesArray}
                columns={totalBalancesColumns}
                isSearchActive={false}
            />
            </div>
        )}

        <hr className="my-5" style={{ borderColor: 'white' }} />

        {/* 2) Overall Positions */}
        <div className="mb-5">
            <h2 className="text-center mb-4">User Total Positions</h2>
            {positionsData.length > 0 && (
            <ReactTable
                tableRoute="users"
                data={positionsData}
                columns={[
                { Header: 'Ticker', accessor: 'ticker' },
                { Header: 'Settled', accessor: 'settled' },
                { Header: 'Unsettled', accessor: 'unsettled' },
                { Header: 'Market Value', accessor: 'marketValue' },
                { Header: 'Last Price', accessor: 'lastPrice' },
                { Header: 'Queued', accessor: 'queued' },
                {
                    Header: 'Available Shares To Sell',
                    accessor: 'availableSharesToSell',
                },
                {
                    Header: 'Pending Buy Qty Mkt Value',
                    accessor: 'pendingBuyMarketValue',
                },
                ]}
                isSearchActive
            />
            )}
        </div>
      </div>
      <hr className="my-5" style={{ borderColor: 'white' }} />
      

      {/* 3) Per-Account Balances + Positions */}
      {positionBalanceArrayPerAccount.map((positionsForOneAccount, index) => {
        if (!positionsForOneAccount || positionsForOneAccount.length === 0) {
          return null;
        }
        // Example '3HD05889'
        const apexAccount = positionsForOneAccount[0]?.apexAccount;

        // Find the matching object in accountBalanceArrayPerAccount 
        const matchingBalanceObj = accountBalances.find(
          (bal) => bal.apexAccount === apexAccount
        );

        // We'll create a 1-row array for that single account's balances
        const singleRowBalanceData = matchingBalanceObj
          ? [
              {
                apexAccount: matchingBalanceObj.apexAccount,
                totalValue: formatCurrency(matchingBalanceObj.totalValue),
                totalCash: formatCurrency(matchingBalanceObj.totalCash),
                totalBuyingPower: formatCurrency(matchingBalanceObj.totalBuyingPower),
                totalUnsettledDeposits: formatCurrency(matchingBalanceObj.totalUnsettledDeposits),
                totalUnsettledWithdrawals: formatCurrency(matchingBalanceObj.totalUnsettledWithdrawals),
                totalPendingOrderDebits: formatCurrency(matchingBalanceObj.totalPendingOrderDebits),
                totalPendingOrderCredits: formatCurrency(matchingBalanceObj.totalPendingOrderCredits),

              },
            ]
          : [];

        return (
          <div key={index} className="mb-5">
            <h2 className="text-center mb-4">
              Balances & Positions for Account {apexAccount}
            </h2>

            {/* A) Account-level single-row balance table */}
            {singleRowBalanceData.length > 0 && (
              <ReactTable
                tableRoute="users"
                data={singleRowBalanceData}
                columns={accountBalanceColumns}
                isSearchActive={false}
              />
            )}

            {/* B) Positions for this account */}
            <ReactTable
              tableRoute="users"
              data={positionsForOneAccount}
              columns={accountPositionColumns}
              isSearchActive
            />
          </div>
        );
      })}
    </div>
  );
}

