import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { getOrderHistoryById } from 'Services/AccountServices';
import moment from 'moment';
import SimpleTable from '../Components/SortingTable/SortingTable';

export default function OrderDetails() {
  const { externalOrderId } = useParams();
  const [ordersByAccountTable, setOrdersByAccountTable] = React.useState(null);

  const { data, isSuccess } = useQuery({
    queryKey: ['getOrdersById', externalOrderId],
    queryFn: () => getOrderHistoryById(externalOrderId),
  });

  useEffect(() => {
    if (isSuccess) {
      const ordersTables = data.map(reason => [
        { label: 'Status', value: reason.status },
        { label: 'Account', value: reason.account },
        { label: 'Symbol', value: reason.symbol },
        { label: 'Side', value: reason.side },
        { label: 'Share Quantity', value: reason.shareQuantity },
        { label: 'Avg Price', value: reason.avgPrice },
        { label: 'Notional', value: reason.notional },
        { label: 'External ID', value: reason.externalId },
        { label: 'UUID', value: reason.id },
        {
          label: 'Date Submitted',
          value: moment(reason.dateTime).format('MM/DD/YYYY HH:mm:ss A'),
        },
      ]);
      setOrdersByAccountTable(ordersTables);
    }
  }, [isSuccess, data]);

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Order Details</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Order History for {externalOrderId}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {ordersByAccountTable && ordersByAccountTable.length > 0 ? (
                <SimpleTable
                  thead={[
                    { text: 'Status' },
                    { text: 'Account' },
                    { text: 'Symbol' },
                    { text: 'Side' },
                    { text: 'Share Quantity' },
                    { text: 'Avg Price' },
                    { text: 'Notional' },
                    { text: 'External ID' },
                    { text: 'UUID' },
                    { text: 'Date Submitted' },
                  ]}
                  tbody={ordersByAccountTable.map(rows => ({
                    data: rows.map(row => ({ text: row.value })),
                  }))}
                />
              ) : (
                <span> loading... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
