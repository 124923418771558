/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import UserService from 'Services/UserService';
import UserAumTables from './UserAumTables';

export default function UserAumDetails({ userId }) {
  const userAumQuery = useQuery({
    queryKey: ['getUserAumByUserId', userId],
    queryFn: () => UserService.getUserAumByUserId(userId),
  });

  const handleRefresh = () => {
    userAumQuery.refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg p-2">
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            User Assets Under Management
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {userAumQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </div>
        {userAumQuery.isLoading && <div>Loading...</div>}
        {userAumQuery.isError && <div>Error loading data</div>}
        {userAumQuery.isSuccess && !userAumQuery.data && (
          <div>No data found</div>
        )}
        {userAumQuery.isSuccess && userAumQuery.data && (
          <Row className="mb-2" style={{ justifyContent: 'center' }}>
            <Col md="4">
              <CardText className="h3">
                <strong>Total Value:</strong>{' '}
                {userAumQuery.data.userTotalValue?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </CardText>
            </Col>
            <Col md="4">
              <CardText className="h3">
                <strong>Total Buying Power:</strong>{' '}
                {userAumQuery.data.userTotalBuyingPower?.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  }
                )}
              </CardText>
            </Col>
            <Col md="4">
              <CardText className="h3">
                <strong>Total Cash:</strong>{' '}
                {userAumQuery.data.userTotalCash?.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  }
                )}
              </CardText>
            </Col>
            <Col md="4">
              <CardText className="h3">
                <strong>Positions Mkt Value:</strong>{' '}
                {userAumQuery.data.userPositionsMarketValue?.toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  }
                )}
              </CardText>
            </Col>
          </Row>
        )}
      </CardBody>
      <hr className="my-5" style={{ borderColor: 'white', paddingBottom: '10px', }} />
      <UserAumTables userAumData={userAumQuery.data} />
    </Card>
  );
}