/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const maxVisiblePages = 5;
  const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

  const startPage = Math.max(1, currentPage - halfMaxVisiblePages);
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  const pageButtons = [];

  for (let page = startPage; page <= endPage; page++) {
    pageButtons.push(
      <Button
        key={page}
        color={currentPage === page ? 'primary' : 'secondary'}
        onClick={() => onPageChange(page)}
      >
        {page}
      </Button>
    );
  }

  return (
    <ButtonGroup>
      <Button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        color="primary"
      >
        Previous
      </Button>
      {pageButtons}
      <Button
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
        color="primary"
      >
        Next
      </Button>
    </ButtonGroup>
  );
}
