import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { submitPositionAdjustment } from '../../../Services/PositionAdjustmentService';

const POSITION_ADJUSTMENT_TYPES = Object.freeze({
  NONE: 'none',
  POSITION_SUBMITTED: 'positionsubmitted',
  POSITION_UNSETTLED: 'positionunsettled',
  POSITION_SETTLED: 'positionsettled',
});

function PositionBalanceUpdateForm({ accountNumber }) {
  const [amount, setAmount] = useState('');
  const [ticker, setTicker] = useState('');
  const [updateType, setUpdateType] = useState(POSITION_ADJUSTMENT_TYPES.NONE);
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const updateBalanceMutation = useMutation({
    mutationFn: async ({
      accountNumber: adjustmentAccountNumber,
      ticker: adjustmentTicker,
      amount: adjustmentAmount,
      type,
    }) => {
      const adjustments = {
        [type]: parseFloat(adjustmentAmount),
      };
      return submitPositionAdjustment(
        adjustmentAccountNumber,
        adjustmentTicker,
        adjustments
      );
    },
    onSuccess: () => {
      setAmount('');
      setTicker('');
      setUpdateType(POSITION_ADJUSTMENT_TYPES.NONE);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success!"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          The Position balance event has been sent successfully (Please validate
          the event successfully modified the user position)
        </ReactBSAlert>
      );
    },
    onError: error => {
      setAlert(
        <ReactBSAlert
          error
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          {error?.message ||
            'Failed to update position balance. Please try again.'}
        </ReactBSAlert>
      );
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (updateType === POSITION_ADJUSTMENT_TYPES.NONE) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Warning"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="warning"
          btnSize=""
        >
          Please select an update type
        </ReactBSAlert>
      );
      return;
    }

    if (!ticker.trim()) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Warning"
          onConfirm={() => hideAlert()}
          confirmBtnBsStyle="warning"
          btnSize=""
        >
          Please enter a ticker symbol
        </ReactBSAlert>
      );
      return;
    }

    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => {
          hideAlert();
          updateBalanceMutation.mutate({
            accountNumber,
            ticker: ticker.toUpperCase(),
            amount: parseFloat(amount),
            type: updateType,
          });
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, update it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        Are you sure you want to update the position balance?
      </ReactBSAlert>
    );
  };

  return (
    <>
      {alert}
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Update Position Balance</CardTitle>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="ticker">Ticker Symbol</Label>
                <Input
                  type="text"
                  id="ticker"
                  value={ticker}
                  onChange={e => setTicker(e.target.value.toUpperCase())}
                  placeholder="Enter ticker symbol"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="amount">Amount</Label>
                <Input
                  type="number"
                  step="0.01"
                  id="amount"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="updateType">Update Type</Label>
                <Input
                  type="select"
                  id="updateType"
                  value={updateType}
                  onChange={e => setUpdateType(e.target.value)}
                >
                  <option value={POSITION_ADJUSTMENT_TYPES.NONE}>None</option>
                  <option value={POSITION_ADJUSTMENT_TYPES.POSITION_SUBMITTED}>
                    Position Submitted
                  </option>
                  <option value={POSITION_ADJUSTMENT_TYPES.POSITION_UNSETTLED}>
                    Position Unsettled
                  </option>
                  <option value={POSITION_ADJUSTMENT_TYPES.POSITION_SETTLED}>
                    Position Settled
                  </option>
                </Input>
              </FormGroup>
              <Button color="primary" type="submit">
                Update Balance
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default PositionBalanceUpdateForm;
