import React, { useEffect, useState } from 'react';
import { Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getApexAccountDetails } from '../../../Services/AccountServices';
import SimpleTable from '../../Components/SortingTable/SortingTable';

function TrustedContactTable({ accountNumber }) {
  const [trustedContactTable, setTrustedContactTable] = useState([]);

  const { isLoading, isError, error, data, isSuccess } = useQuery({
    queryKey: ['getApexAccountDetails', accountNumber],
    queryFn: () => getApexAccountDetails(accountNumber),
  });

  useEffect(() => {
    if (isSuccess) {
      if (data?.trustedContact) {
        const trustedContact = [
          { label: 'Name', value: data?.trustedContact?.name || 'N/A' },
          {
            label: 'Phone Number',
            value: data?.trustedContact?.phone || 'N/A',
          },
          { label: 'Email', value: data?.trustedContact?.email || 'N/A' },
        ];
        setTrustedContactTable(trustedContact);
      }
    }
  }, [isSuccess, data]);

  if (isLoading) {
    return <p>Loading trusted contact information...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load trusted contact information.'}
      </p>
    );
  }

  return (
    <Col xs={12} md={12}>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Trusted Contact For:
            {accountNumber}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <SimpleTable
            thead={[{ text: 'Label' }, { text: 'Value' }]}
            tbody={trustedContactTable.map(row => ({
              data: [{ text: row.label }, { text: row.value }],
            }))}
          />
        </CardBody>
      </Card>
    </Col>
  );
}

export default TrustedContactTable;
