import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getMarginCalls } from 'Services/MarginServices';
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Container } from 'reactstrap';
import ReactTable from '../Components/ReactTable/ReactTable';

export default function MarginCalls() {
  const { data, isSuccess } = useQuery({
    queryKey: ['marginCalls'],
    queryFn: () => getMarginCalls(),
  });

  const [marginTable, setMarginTable] = React.useState(null);

  useEffect(() => {
    if (isSuccess) {
      const marginTableVar = data?.map(prop => ({
        accountnumber: prop.accountnumber,
        accountname: prop.accountname,
        callamount: prop.callamount,
        calltype: prop.calltype,
        tradedate: prop.tradedate,
        duedate: prop.duedate,
        regtdate: prop.regtdate,
        actions: <div> </div>,
            }));

      setMarginTable(marginTableVar);
    }
  }, [isSuccess, data, setMarginTable]);

  return (
    <Container fluid className="content">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <span className="mb-5">Hedge Admin Dashboard </span>
        </header>
      </div>
          <Row className="mt-5" xl={1}>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Margin Calls</CardTitle>
                </CardHeader>
                <CardBody>
                  {marginTable && marginTable.length > 0 ? (
                    <ReactTable
                      tableRoute="acats"
                      data={marginTable}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Account Number',
                          accessor: 'accountnumber',
                        },
                        {
                          Header: 'Account Name',
                          accessor: 'accountname',
                        },
                        {
                          Header: 'Margin Call Amount',
                          accessor: 'callamount',
                        },
                        {
                          Header: 'Margin Call Type',
                          accessor: 'calltype',
                        },
                        {
                          Header: 'Trade Date',
                          accessor: 'tradedate',
                        },
                        {
                          Header: 'Due Date',
                          accessor: 'duedate',
                        },
                        {
                          Header: 'Reg T Date',
                          accessor: 'regtdate',
                        },
                        {
                          Header: '',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      defaultPageSize={25}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span> loading </span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
    </Container>
  );
}
