/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unresolved
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// eslint-disable-next-line import/no-unresolved

import { useQuery } from '@tanstack/react-query';
import {
  getWhitelist,
  searchWhitelist,
  refreshDiffs,
} from 'Services/WhitelistService';
import { useDispatch } from 'react-redux';
import { addStockToEdits } from 'store/Reducers/whitelist';
import { Button, Col, Container, Row } from 'reactstrap';
import Pagination from './components/Pagination';
import SearchBar from './components/SearchBar';
import ApexDiffTable from './components/ApexDiffTable';
import PolygonDiffTable from './components/PolygonDiffTable';
import CombinedDiffTable from './components/CombinedDiffTable';
import ChangesTable from './components/ChangesTable';

export default function Whitelist() {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [searching, setSearching] = useState(false);
  // Each Column Definition results in one Column.
  const columnDefs = [
    { field: 'symbol', filter: true },
    { field: 'securityName', filter: true },
    { field: 'active', editable: true },
    { field: 'type', editable: true },
    { field: 'locale', editable: true },
    { field: 'currency_name', editable: true },
    { field: 'cik', editable: true },
    { field: 'composite_figi', editable: true },
    { field: 'share_class_figi', editable: true },
    { field: 'market', editable: true },
    { field: 'currency_symbol', editable: true },
    { field: 'primary_exchange', editable: true },
    { field: 'base_currency_symbol', editable: true },
    { field: 'base_currency_name', editable: true },
    { field: 'delisted_utc', editable: true },
    { field: 'sic_code', editable: true },
  ];
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['whitelist', page],
    queryFn: () => getWhitelist(page),
  });

  useEffect(() => {
    if (isSuccess && !searching) {
      setChartData(data);
    }
  }, [isSuccess, data, searching]);

  const pageClicked = page => {
    setPage(page);
  };

  const onSearch = async searchText => {
    const res = await searchWhitelist(searchText);
    setChartData(res);
  };

  const onCellValueChanged = useCallback(
    event => {
      // Retrieve the field that was edited and its new value
      const { colDef, newValue, data } = event;
      const updatedField = colDef.field;

      // Create a new updated data object with the edited field updated
      const updatedData = { ...data };
      updatedData[updatedField] = newValue;

      // Dispatch the action with the updated data
      dispatch(addStockToEdits(updatedData));
    },
    [dispatch]
  );

  const handleRefresh = async () => {
    // refreshDiffs();
    await refreshDiffs();
  };

  const handleWhitelistRefresh = async () => {
    await refetch();
  };

  return (
    <Container fluid className="content">
      <Button
        onClick={handleRefresh}
        style={{ marginBottom: '5%' }}
        color="success"
        size="lg"
      >
        Handle Backend Diff Refresh
      </Button>
      <Row className="mt-4">
        <Col md="12">
          <ChangesTable />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          <ApexDiffTable />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          <PolygonDiffTable />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          <CombinedDiffTable />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <h1>Hedge Whitelist</h1>
            <Button
              onClick={handleWhitelistRefresh}
              style={{ marginBottom: '2%', marginLeft: '3%' }}
              size="md"
            >
              Refresh
            </Button>
          </div>
          <SearchBar
            onSearch={onSearch}
            setSearching={setSearching}
            data={data}
            setChartData={setChartData}
          />
          <div className="bg-zinc-800" style={{ width: '100%', height: 1500 }}>
            <AgGridReact
              className="ag-theme-alpine"
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows
              rowSelection="multiple"
              onCellValueChanged={onCellValueChanged}
              rowData={chartData}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              marginTop: 20,
            }}
          >
            <Pagination
              totalPages={90}
              currentPage={page}
              onPageChange={pageClicked}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
