import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { getIndvPdtByExtId } from 'Services/AccountServices';
import moment from 'moment';
import SimpleTable from '../Components/SortingTable/SortingTable';

function PdtDetailExtId() {
  // eslint-disable-next-line camelcase
  const { externalid } = useParams();
  const [pdtTradeSpecificForUser, setSpecificPdtTradeTable] =
    React.useState(null);

  const { data, isSuccess } = useQuery({
    queryKey: ['getIndvPdtByExtId', externalid],
    queryFn: () => getIndvPdtByExtId(externalid),
  });

  useEffect(() => {
    if (isSuccess) {
      const pdtTradeTables = data.map(reason => [
        { label: 'UserId', value: reason.uuid_usersdb },
        { label: 'Email', value: reason.email },
        { label: 'Account', value: reason.account },
        { label: 'Prev. Side', value: reason.prev_side },
        { label: 'Day', value: moment(reason.day).format('MM/DD/YYYY A') },
        {
          label: 'Datetime',
          value: moment(reason.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        },
        { label: 'Trade_type', value: reason.trade_type },
        {
          label: 'Total_day_trades_today',
          value: reason.total_day_trades_today,
        },
        {
          label: 'Day_trade_number_today',
          value: reason.day_trade_number_today,
        },
        { label: 'Symbol', value: reason.symbol },
        { label: 'Side', value: reason.side },
        { label: 'Sharequantity', value: reason.sharequantity },
        { label: 'Status', value: reason.status },
        { label: 'Externalid', value: reason.externalid },
        { label: 'Triggered_by', value: reason.triggered_by },
        { label: 'Apaorderid', value: reason.apaorderid },
        { label: 'Id', value: reason.id },
        { label: 'Type', value: reason.type },
        { label: 'Acct_type', value: reason.acct_type },
        { label: 'Group_id', value: reason.group_id },
        { label: 'Group_name', value: reason.group_name },
      ]);
      setSpecificPdtTradeTable(pdtTradeTables);
    }
  }, [isSuccess, data]);
  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">PDT Trades</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Specific PDT Record: {/* eslint-disable-next-line camelcase */}
                {[externalid]}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {pdtTradeSpecificForUser && pdtTradeSpecificForUser.length > 0 ? (
                <SimpleTable
                  thead={[
                    { text: 'UserId' },
                    { text: 'Email' },
                    { text: 'Account' },
                    { text: 'Prev. Side' },
                    { text: 'Trade Day' },
                    { text: 'Txn time' },
                    { text: 'Day Trade?' },
                    { text: 'Total PDTs (Daily)' },
                    { text: 'PDT # (Daily)' },
                    { text: 'Symbol' },
                    { text: 'Side' },
                    { text: 'Share Qty' },
                    { text: 'Status' },
                    { text: 'External ID' },
                    { text: 'Triggered By' },
                    { text: 'APA Order ID' },
                    { text: 'ID' },
                    { text: 'Type' },
                    { text: 'Acct Type' },
                    { text: 'Group ID' },
                    { text: 'Group Name' },
                  ]}
                  tbody={pdtTradeSpecificForUser.map(rows => ({
                    data: rows.map(row => ({ text: row.value })),
                  }))}
                />
              ) : (
                <span> loading... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default PdtDetailExtId;
