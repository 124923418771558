/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable spaced-comment */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import classnames from 'classnames';
// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';
// reactstrap components
import { FormGroup, Input } from 'reactstrap';

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length - 1;

  return (
    <FormGroup>
      <Input
        type="email"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

// Our table component
function ReactTable({ columns, data }) {
  const [numberOfRows, setNumberOfRows] = React.useState(10);
  const [pageSelect, handlePageSelect] = React.useState(0);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  const totalRows = data.length; // Get the total number of rows

  const pageSelectData = Array.apply(null, Array(pageOptions.length)).map(
    // eslint-disable-next-line array-callback-return
    (_, index) => ({
      value: index,
      label: `Page ${index + 1}`,
    })
  );
  const numberOfRowsData = [5, 10, 20, 25, 50, 100];

  return (
    <div className="ReactTable -striped -highlight">
      {totalRows > 10 && ( // Conditionally render pagination controls
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className="-btn"
              >
                Previous
              </button>
            </div>
            <div className="-center flex-nowrap">
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={pageSelect}
                onChange={value => {
                  gotoPage(value.value);
                  handlePageSelect(value);
                }}
                options={pageSelectData.map((prop, key) => ({
                  value: key,
                  label: `Page ${key + 1}`,
                }))}
                placeholder="Select page"
              />
              <Select
                className="react-select info mx-5 w-100"
                classNamePrefix="react-select"
                name="singleSelect"
                value={numberOfRows}
                onChange={value => {
                  setPageSize(value.value);
                  setNumberOfRows(value);
                }}
                options={numberOfRowsData.map(prop => ({
                  value: prop,
                  label: `${prop} rows`,
                }))}
                placeholder="Select #rows"
              />
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      <table {...getTableProps()} className="rt-table">
        <thead className="rt-thead -header">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classnames('rt-th rt-resizable-header', {
                    '-sort-asc': column.isSorted && !column.isSortedDesc,
                    '-sort-desc': column.isSorted && column.isSortedDesc,
                  })}
                >
                  <div className="rt-resizable-header-content">
                    {column.render('Header')}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>
                    {column.Header === 'Details'
                      ? null
                      : column.turnFilterOff
                        ? null
                        : column.render('Filter')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="rt-tbody">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classnames(
                  'rt-tr',
                  { '-odd': i % 2 === 0 },
                  { '-even': i % 2 === 1 }
                )}
              >
                {row.cells.map(cell => {
                  const isCustomRenderedCell =
                    cell.column.Cell.name !== 'defaultRenderer' &&
                    cell.column.Cell.name !== 'u';
                  const cellContent = cell.render('Cell');
                  const cellValue = cellContent.props.value;

                  return (
                    <td {...cell.getCellProps()} className="rt-td">
                      {cellValue && cellValue.toString().trim() ? (
                        cellContent
                      ) : isCustomRenderedCell ? (
                        cellContent
                      ) : (
                        <span className="empty-cell-placeholder">-</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-bottom" />
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number';

export default ReactTable;
