/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import './TableComponent.css';

function SODTableComponent({ sodData }) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const columnHeaders = sodData.length > 0 ? Object.keys(sodData[0]) : [];

  const handleSearch = event => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = searchTerm
    ? sodData.filter(row =>
        columnHeaders.some(
          header =>
            row[header] &&
            row[header].toString().toLowerCase().includes(searchTerm)
        )
      )
    : sodData;

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {columnHeaders.map(header => (
                <th key={header}>
                  {header.charAt(0).toUpperCase() + header.slice(1)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {columnHeaders.map(header => (
                  <td key={`${header}-${rowIndex}`}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SODTableComponent;
