import { v4 as uuidv4 } from 'uuid';
import { APIRequestWithAuth } from './config';

export const submitPositionAdjustment = async (
  accountNumber,
  ticker,
  adjustments
) => {
  // Format the process date and dateTime for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const processDate = tomorrow.toISOString().split('T')[0];
  const dateTime = tomorrow.toISOString();

  const body = {
    id: uuidv4(),
    account: accountNumber,
    ticker,
    processDate,
    dateTime,
    ...adjustments,
  };

  const data = await APIRequestWithAuth(
    'POST',
    '/v1/adjustments/position',
    body
  );

  if (data) {
    return data;
  }
  return null;
};
