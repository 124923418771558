/* eslint-disable spaced-comment */
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import UserAccountSummaryBox from './components/UserAccountSummaryBox';
//import UserDetailsBox from './components/UserDetailsBox';
import UserAumDetails from './components/UserAumDetails';
import ScrollSidebar from './components/ScrollSidebar';

export default function UserDetailsScreen() {
  const { userId } = useParams();
  //const detailsRef = useRef(null);
  const aumDetailsRef = useRef(null);
  const accountsDetailsRef = useRef(null);

  const sections = [
    //{ id: 'details', label: 'User Details', ref: detailsRef },
    { id: 'accountsDetailsRef', label: 'Account Summary', ref: accountsDetailsRef },
    { id: 'aumDetails', label: 'AUM Details', ref: aumDetailsRef },
  ];

  return (
    <Container fluid className="content">
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <h2 className="mb-5">User {userId} Details</h2>
        </header>
      </div>
      {/* <div ref={detailsRef} className="mb-10">
        <UserDetailsBox userId={userId} />
      </div> */}
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <h2 className="mb-5">User {userId} Accounts</h2>
        </header>
      </div>
      <div ref={accountsDetailsRef} className="mb-10">
        <UserAccountSummaryBox userId={userId} />
      </div>
      <div className="flex justify-center items-start mt-10">
        <header className="App-header justify-self-start mb-3">
          <h2 className="mb-5">User {userId} AUM Details</h2>
        </header>
      </div>
      <div ref={aumDetailsRef} className="mb-10">
        <UserAumDetails userId={userId} />
      </div>
      <ScrollSidebar sections={sections} />
    </Container>
  );
}
