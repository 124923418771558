import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { getApexInvestigationDetails } from 'Services/AccountServices';
import SimpleTable from '../Components/SortingTable/SortingTable';

export default function SanctionsNewsProfilesDetails() {
  const { requestid } = useParams();

  const [
    sanctionsNewsProfilesDetailsTable,
    setSanctionsNewsProfilesDetailsTable,
  ] = React.useState(null);

  const { data, isSuccess } = useQuery({
    queryKey: ['getApexInvestigationDetails', requestid],
    queryFn: () => getApexInvestigationDetails(requestid),
  });

  useEffect(() => {
    if (isSuccess) {
      setSanctionsNewsProfilesDetailsTable(data.sanctionsNewsProfilesDetails);
    }
  }, [isSuccess, data]);

  return (
    <div className="flex h-screen bg-zinc-800">
      <div className="flex justify-center items-start mt-10">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">
            Sanctions And News Details For Request
            {requestid}
          </h2>
        </Col>
        {sanctionsNewsProfilesDetailsTable ? (
          sanctionsNewsProfilesDetailsTable.map(profile => (
            <div
              style={{ width: '80%', marginLeft: '15%', marginRight: '10%' }}
            >
              <Row className="mt-5">
                <Col xs={12} md={12}>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Sanctions & News Details For
                        {profile.Name}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <SimpleTable
                        thead={[{ text: 'Field' }, { text: 'Value' }]}
                        tbody={[
                          {
                            data: [
                              { text: 'Summary' },
                              { text: profile.Summary },
                            ],
                          },
                          {
                            data: [
                              { text: 'Certainty' },
                              { text: profile.Certainty },
                            ],
                          },
                          {
                            data: [
                              { text: 'Reasons' },
                              { text: profile.Reasons },
                            ],
                          },
                          {
                            data: [
                              { text: 'ShortSummary' },
                              { text: profile.ShortSummary },
                            ],
                          },
                          {
                            data: [
                              { text: 'RecordNameDetails' },
                              { text: profile.RecordNameDetails },
                            ],
                          },
                          {
                            data: [
                              { text: 'Gender' },
                              { text: profile.Gender },
                            ],
                          },
                          {
                            data: [
                              { text: 'Deceased' },
                              { text: profile.Deceased },
                            ],
                          },
                          {
                            data: [
                              { text: 'DatesOfBirth' },
                              { text: profile.DatesOfBirth },
                            ],
                          },
                          {
                            data: [
                              { text: 'DatesOfDeath' },
                              { text: profile.DatesOfDeath },
                            ],
                          },
                          {
                            data: [
                              { text: 'Citizenship' },
                              { text: profile.Citizenship },
                            ],
                          },
                          {
                            data: [
                              { text: 'ResidentOf' },
                              { text: profile.ResidentOf },
                            ],
                          },
                          {
                            data: [
                              { text: 'CountryOfReportedAllegation' },
                              { text: profile.CountryOfReportedAllegation },
                            ],
                          },
                          {
                            data: [
                              { text: 'BirthPlace' },
                              { text: profile.BirthPlace },
                            ],
                          },
                          {
                            data: [
                              { text: 'RoleDetails' },
                              { text: profile.RoleDetails },
                            ],
                          },
                          {
                            data: [{ text: 'Notes' }, { text: profile.Notes }],
                          },
                          {
                            data: [
                              { text: 'Sources' },
                              { text: profile.Sources },
                            ],
                          },
                        ]}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <span> loading... </span>
        )}
      </div>
    </div>
  );
}
