import React from 'react';
import IncompleteOldOrders from 'Screens/Orders/IncompleteOldOrders';
import Alerts from 'Screens/Alerts/Alerts';
import Whitelist from 'Screens/Whitelist/Whitelist';
import TickerStatus from 'Screens/TickerStatus/TickerStatus';
import SQSQueues from 'Screens/SQSQueues/SQSQueues';

const sidebarRoutes = [
  {
    path: '/users',
    name: 'Users',
    layout: '/users',
    icon: 'tim-icons icon-single-02',
  },
  {
    path: '/accounts',
    name: 'Accounts',
    layout: '/admin',
    icon: 'tim-icons icon-wallet-43',
  },
  {
    path: '/reconevents',
    name: 'Recon Events',
    layout: '/admin',
    icon: 'tim-icons icon-gift-2',
  },
  {
    path: '/applications',
    name: 'Applications',
    layout: '/admin',
    icon: 'tim-icons icon-single-copy-04',
  },
  {
    path: '/transfers',
    name: 'Transfers',
    layout: '/admin',
    icon: 'tim-icons icon-money-coins',
  },
  {
    path: '/orders',
    name: 'All Orders',
    layout: '/admin',
    icon: 'tim-icons icon-cart',
  },
  {
    path: '/grouporders',
    name: 'Group Orders',
    layout: '/admin',
    icon: 'tim-icons icon-cart',
  },
  {
    path: '/incompleteorders',
    name: 'Incomplete Orders',
    layout: '/admin',
    icon: 'tim-icons icon-alert-circle-exc',
    component: <IncompleteOldOrders />,
  },
  {
    path: '/marginCalls',
    name: 'Margin Calls',
    layout: '/admin',
    icon: 'tim-icons icon-money-coins',
  },
  {
    path: '/achrelationships',
    name: 'ACH Relationships',
    layout: '/admin',
    icon: 'tim-icons icon-bank',
  },
  {
    path: '/acats',
    name: 'Acats',
    layout: '/admin',
    icon: 'tim-icons icon-double-right',
  },
  {
    path: '/alerts',
    name: 'Alerts',
    layout: '/admin',
    icon: 'tim-icons icon-bell-55',
    component: <Alerts />,
  },
  {
    path: '/whitelist',
    name: 'Whitelist',
    layout: '/admin',
    icon: 'tim-icons icon-notes',
    component: <Whitelist />,
  },
  {
    path: '/tickerStatus',
    name: 'Ticker Status',
    layout: '/admin',
    icon: 'tim-icons icon-sound-wave',
    component: <TickerStatus />,
  },
  {
    collapse: true,
    name: 'Reconciliation',
    icon: 'tim-icons icon-support-17',
    state: 'reconciliationCollapse',
    views: [
      {
        path: '/accountsReconciliation',
        name: 'Accounts Reconciliation',
        layout: '/admin',
        icon: 'tim-icons icon-badge',
      },
      {
        path: '/events/unmatched',
        name: 'Unmatched Events',
        layout: '/admin',
        icon: 'tim-icons icon-alert-circle-exc',
      },
    ],
  },
  {
    path: '/groups',
    name: 'Group Investing',
    layout: '/admin',
    icon: 'tim-icons icon-world',
  },
  {
    path: '/sqsqueues',
    name: 'SQS Queues',
    layout: '/admin',
    icon: 'tim-icons icon-send',
    component: <SQSQueues />,
  },
  {
    collapse: true,
    name: 'Admin Data',
    icon: 'tim-icons icon-support-17',
    state: 'admindataCollapse',
    views: [
      {
        path: '/pdt',
        name: 'Pattern Day Trading',
        layout: '/admin',
        icon: 'tim-icons icon-badge',
      },
      {
        path: '/groupWithdrawals',
        name: 'Group Withdrawals',
        layout: '/admin',
        icon: 'tim-icons icon-send',
      },
    ],
  },
];

export { sidebarRoutes };
