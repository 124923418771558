import React, { useCallback, useEffect, useState } from 'react';
import { Col, Card, CardHeader, CardBody, CardTitle, Button } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../../Components/ReactTable/ReactTable';
import { getApexAccountDetails } from '../../../Services/AccountServices';

function AchRelationshipsTable({ accountNumber }) {
  const [achRelationshipTable, setAchRelationshipTable] = useState([]);
  const navigate = useNavigate();

  const [achRelationshipData] = useState(null);

  const handlePressAch = useCallback(
    achRelationshipId => {
      navigate(`/achrelationships/${achRelationshipId}`, {
        state: { achRelationshipData },
      });
    },
    [navigate, achRelationshipData]
  );

  const { isLoading, isError, error, isSuccess, data } = useQuery({
    queryKey: ['getApexAccountDetails', accountNumber],
    queryFn: () => getApexAccountDetails(accountNumber),
  });

  useEffect(() => {
    if (isSuccess) {
      if (data?.achRelationships?.length > 0) {
        const achRelationships = data.achRelationships.map(prop => ({
          nickname: prop.nickname,
          relationshipId: prop.id,
          status: prop.status,
          createdOn: moment(prop.creationTime).format('MM/DD/YYYY HH:mm:ss A'),
          lastUpdated: moment(
            prop.status === 'APPROVED'
              ? prop.approval?.approvalTime
              : prop.status === 'REJECTED'
                ? prop.rejection?.rejectionTime
                : prop.cancellation?.cancellationTime
          ).format('MM/DD/YYYY HH:mm:ss A'),
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => handlePressAch(prop.id)}
                color="primary"
                size="sm"
                disabled={data?.achRelationships?.length === 0}
              >
                <i className="tim-icons icon-double-right" />
              </Button>
            </div>
          ),
        }));
        setAchRelationshipTable(achRelationships);
      }
    }
  }, [isSuccess, data, handlePressAch]);

  if (isLoading) {
    return <p>Loading ACH relationships...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load ACH relationships.'}
      </p>
    );
  }

  return (
    achRelationshipTable.length > 0 && (
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              ACH Relationships For:
              {accountNumber}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={achRelationshipTable}
              filterable
              resizable={false}
              columns={[
                { Header: 'Nickname', accessor: 'nickname' },
                { Header: 'Relationship Id', accessor: 'relationshipId' },
                { Header: 'Status', accessor: 'status' },
                { Header: 'Created On', accessor: 'createdOn' },
                { Header: 'Last Updated', accessor: 'lastUpdated' },
                {
                  Header: 'Details',
                  accessor: 'actions',
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </Col>
    )
  );
}

export default AchRelationshipsTable;
