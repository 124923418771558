/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getAllAcats } from 'Services/AcatsServices';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function Acats() {
  const navigate = useNavigate();

  const handlePress = useCallback(
    (tifId, acatsControlNumber) => {
      // acatsControlNumber passed in as a param to be used in the next page
      navigate(`/acats/${tifId}`, { state: { acatsControlNumber } });
    },
    [navigate]
  );

  // eslint-disable-next-line no-unused-vars
  const getAllApexAccountsQuery = useQuery({
    queryKey: ['allAcats'],
    queryFn: () => getAllAcats(),
  });

  useEffect(() => {
    if (getAllApexAccountsQuery.isSuccess) {
      setData(
        getAllApexAccountsQuery.data.map(prop => ({
          id: prop.id,
          acatsControlNumber: prop.acatsControlNumber,
          account: prop.account,
          direction: prop.direction,
          transferType: prop.transferType,
          currentState: prop.currentState,
          previousState: prop.previousState,
          tifId: prop.tifId,
          notes: prop.notes,
          clientReferenceId: prop.clientReferenceId,
          dateSubmitted: moment(prop.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  handlePress(prop.tifId, prop.acatsControlNumber);
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        }))
      );
    }
  }, [
    getAllApexAccountsQuery.isSuccess,
    handlePress,
    getAllApexAccountsQuery.data,
  ]);

  const [data, setData] = React.useState(
    getAllApexAccountsQuery.data &&
      getAllApexAccountsQuery.data.map(prop => ({
        id: prop.id,
        acatsControlNumber: prop.acatsControlNumber,
        account: prop.account,
        direction: prop.direction,
        transferType: prop.transferType,
        currentState: prop.currentState,
        previousState: prop.previousState,
        tifId: prop.tifId,
        notes: prop.notes,
        clientReferenceId: prop.clientReferenceId,
        dateSubmitted: moment(prop.originaldatetime).format(
          'MM/DD/YYYY HH:mm:ss A'
        ),
        lastUpdated: moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A'),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                handlePress(prop.tifId, prop.acatsControlNumber);
              }}
              color="#000000"
              size="sm"
            >
              <i className="tim-icons icon-double-right" />
            </Button>{' '}
          </div>
        ),
      }))
  );

  function handleRefresh() {
    getAllApexAccountsQuery.refetch();
  }

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Acats Transfers</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {data && data.length > 0 ? (
                <ReactTable
                  tableRoute="acats"
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'ID',
                      accessor: 'id',
                    },
                    {
                      Header: 'Acats Control Number',
                      accessor: 'acatsControlNumber',
                    },
                    {
                      Header: 'Account',
                      accessor: 'account',
                    },
                    {
                      Header: 'Direction',
                      accessor: 'direction',
                    },

                    {
                      Header: 'Transfer Type',
                      accessor: 'transferType',
                    },

                    {
                      Header: 'Current State',
                      accessor: 'currentState',
                    },

                    {
                      Header: 'Previous State',
                      accessor: 'previousState',
                    },

                    {
                      Header: 'TIF ID',
                      accessor: 'tifId',
                    },

                    {
                      Header: 'Notes',
                      accessor: 'notes',
                    },

                    {
                      Header: 'Date Submitted',
                      accessor: 'dateSubmitted',
                    },
                    {
                      Header: 'Last Updated',
                      accessor: 'lastUpdated',
                    },
                    {
                      Header: 'Details',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              ) : (
                <span> loading... </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Acats;
