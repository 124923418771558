/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import {
  getTransferHistoryById,
  cancelAchDeposit,
  cancelAchWithdrawal,
} from 'Services/AccountServices';
import moment from 'moment';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import SimpleTable from '../Components/SortingTable/SortingTable';

function CancelTransfer({ transferData }) {
  const nonCancellableStates = [
    'Completed',
    'Returned',
    'RejectedValidationFailed',
    'RejectedUnapproved',
    'RejectedFundsNotPosted',
    'RejectedNotSentToBank',
    'Canceled',
  ];
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showFailAlert, setShowFailAlert] = React.useState(false);
  const [memo, setMemo] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleMemoChange = event => {
    setMemo(event.target.value);
  };

  // make sure that none of the objects in transferData array have a state in unCancellableStates
  const isCancellable = transferData.every(
    data => !nonCancellableStates.some(state => data.state === state)
  );
  const isDeposit = transferData[0].type === 'ACH_DEPOSIT';

  const handleCancelTransfer = async () => {
    setIsLoading(true);
    let cancelResponse = null;
    if (isDeposit) {
      const body = {
        depositId: transferData[0].transferId,
        reason: memo,
      };
      cancelResponse = await cancelAchDeposit(body);
    } else {
      const body = {
        withdrawalId: transferData[0].transferId,
        reason: memo,
      };
      cancelResponse = await cancelAchWithdrawal(body);
    }

    if (cancelResponse) {
      setIsLoading(false);
      setShowSuccessAlert(true);
    } else {
      setIsLoading(false);
      setShowFailAlert(true);
    }
  };

  if (isCancellable && isDeposit) {
    return (
      <div>
        <ReactBSAlert
          show={showSuccessAlert}
          title="Cancel ACH Deposit Successful"
          onConfirm={() => setShowSuccessAlert(false)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        />

        <ReactBSAlert
          show={showFailAlert}
          title="Cancel ACH Deposit Failed"
          onConfirm={() => setShowFailAlert(false)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
        >
          Please Contact Support
        </ReactBSAlert>
        <Form>
          <FormGroup>
            <Label for="memo" style={{ fontSize: '1.25rem' }}>
              To cancel this ACH Deposit, please add a comment and click the
              button below to submit your request. Please note, this action is
              irreversible.
            </Label>
            <Input
              type="textarea"
              name="memo"
              id="memo"
              value={memo}
              onChange={handleMemoChange}
              placeholder="Enter your reason for cancellation"
              disabled={isLoading}
            />
          </FormGroup>
          <Button
            color="danger"
            disabled={!memo || isLoading}
            onClick={handleCancelTransfer}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" /> Cancelling...
              </>
            ) : (
              'Cancel ACH Deposit'
            )}
          </Button>
        </Form>
      </div>
    );
  }
  if (isCancellable && !isDeposit) {
    return (
      <div>
        <ReactBSAlert
          show={showSuccessAlert}
          title="Cancel ACH Withdrawal Successful"
          onConfirm={() => setShowSuccessAlert(false)}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
        />

        <ReactBSAlert
          show={showFailAlert}
          title="Cancel ACH Withdrawal Failed"
          onConfirm={() => setShowFailAlert(false)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Ok"
        >
          Please Contact Support
        </ReactBSAlert>
        <Form>
          <FormGroup>
            <Label for="memo" style={{ fontSize: '1.25rem' }}>
              To cancel this ACH Withdrawal, please add a comment and click the
              button below to submit your request. Please note, this action is
              irreversible.
            </Label>
            <Input
              type="textarea"
              name="memo"
              id="memo"
              value={memo}
              onChange={handleMemoChange}
              placeholder="Enter your reason for cancellation"
              disabled={isLoading}
            />
          </FormGroup>
          <Button
            color="warning"
            disabled={!memo || isLoading}
            onClick={handleCancelTransfer}
          >
            {isLoading ? (
              <>
                <Spinner size="sm" /> Cancelling...
              </>
            ) : (
              'Cancel ACH Withdrawal'
            )}
          </Button>
        </Form>
      </div>
    );
  }
  return null;
}

export default function TransferDetails() {
  const { externalTransferId } = useParams();
  const [transfersByAccountTable, setTransfersByAccountTable] =
    React.useState(null);
  const [transferData, setTransferData] = React.useState(null);

  const { data, isSuccess } = useQuery({
    queryKey: ['getTransfersById'],
    queryFn: () => getTransferHistoryById(externalTransferId),
  });

  useEffect(() => {
    if (isSuccess) {
      setTransferData(data);
      const transfersTables = data.map(reason => [
        { label: 'Amount', value: reason.amount },
        { label: 'Account', value: reason.account },
        { label: 'Status', value: reason.state },
        { label: 'Reason', value: reason.reason },
        { label: 'Transfer ID', value: reason.transferId },
        { label: 'External Transfer ID', value: reason.externalTransferId },
        { label: 'Type', value: reason.type },
        { label: 'UUID', value: reason.id },
        {
          label: 'Date Submitted',
          value: moment(reason.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
        },
      ]);
      setTransfersByAccountTable(transfersTables);
    }
  }, [isSuccess, data]);

  return (
    <Container fluid className="content">
      <Row>
        <Col md={6} className="mx-auto">
          <h2 className="text-center">Transfer Details</h2>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '2rem' }}>
        <Col md={10}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Transfer History for {externalTransferId}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {transfersByAccountTable && transfersByAccountTable.length > 0 ? (
                <SimpleTable
                  thead={[
                    { text: 'Amount' },
                    { text: 'Account' },
                    { text: 'Status' },
                    { text: 'Reason' },
                    { text: 'Transfer ID' },
                    { text: 'External Transfer ID' },
                    { text: 'Type' },
                    { text: 'UUID' },
                    { text: 'Date Submitted' },
                  ]}
                  tbody={transfersByAccountTable.map(rows => ({
                    data: rows.map(row => ({ text: row.value })),
                  }))}
                />
              ) : (
                <div className="text-center">
                  <Spinner color="primary" /> Loading...
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '2rem' }}>
        <Col md={10}>
          {transfersByAccountTable && transfersByAccountTable.length > 0 && (
            <CancelTransfer transferData={transferData} />
          )}
        </Col>
      </Row>
    </Container>
  );
}
