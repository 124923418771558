/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable spaced-comment */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useMemo, useCallback } from 'react';
import {
  Card,
  CardBody,
  Row,
  Button,
  Col,
  CardHeader,
  Container,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DataGrid } from '@mui/x-data-grid';
import UserService from 'Services/UserService';

function Users() {
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getAllUsersWithActiveAccount'],
    queryFn: () => UserService.getAllUsersWithActiveAccount(),
    staleTime: 5000,
  });

  const rows = useMemo(() => {
    if (!data) return [];

    // data is already an array of records (NOT nestedAccounts)
    return data.map((record) => {
      // Combine fields to form a guaranteed unique ID, e.g. account_id
      const rowId = record.account_id || record.account_num || record.user_id;

      return {
        id: rowId,
        userId: record.user_id,
        accountNum: record.account_num,
        accountType: record.account_type,
        accountStatus: record.account_status,
        apexCount: record.userApexCount,
        createdAt: record.createdAt,
        username: record.User?.user_name ?? '',
      };
    });
  }, [data]);

  const handlePress = useCallback(
    userId => {
      navigate(`/users/aum/${userId}`);
    },
    [navigate]
  );
    
  const columns = useMemo(
    () => [
        { field: 'userId', headerName: 'User ID', width: 150 },
        { field: 'accountNum', headerName: 'Account Number', width: 200 },
        { field: 'accountType', headerName: 'Account Type', width: 200 },
        { field: 'accountStatus', headerName: 'Account Status', width: 200 },
        { field: 'apexCount', headerName: 'Apex Count', width: 150 },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
        { field: 'id', headerName: 'AccountId', width: 200 },
        {
            field: 'view',
            headerName: 'View',
            width: 150,
            renderCell: params => (
            <Button
                onClick={() => handlePress(params.row.userId)}
                color="secondary"
                size="sm"
            >
                <i className="tim-icons icon-double-right" />
            </Button>
            ),
        },
    ],
    [handlePress]
  );

  const handleRefresh = () => {
    refetch();
  };

  return (
      <Container fluid className="content">
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="text-center">Active Users</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Card>
              <CardHeader className="d-flex justify-content-end">
                <Button onClick={handleRefresh} color="secondary" size="sm">
                  <i className="tim-icons icon-refresh-01" />
                </Button>
              </CardHeader>
              <CardBody>
                <div >
                <DataGrid
                  rows={rows || []}
                  loading={isLoading}
                  getRowId={row => row.id}
                  columns={columns}
                  pageSizeOptions={[10, 50, 100]}
                  autoPageHeight
                />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
  );
}

export default Users;