/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Container,
} from 'reactstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { getAllTransfers, getAllAdjustments } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';
import SimpleTable from '../Components/SortingTable/SortingTable';

function Transfers() {
  const navigate = useNavigate();
  const [transferData, setTransferData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    nextToken: null,
    queryExecutionId: null,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTransferData = transferData.filter(item =>
    (
      item.account ||
      item.sourceaccount ||
      item.destinationaccount ||
      item.amount ||
      item.externaltransferid ||
      ''
    )
      .toString()
      .includes(searchTerm.toString())
  );

  const fetchTransfers = async ({ pageSize, nextToken, queryExecutionId }) => {
    const params = { pageSize, nextToken, queryExecutionId };
    try {
      const response = await getAllTransfers(params);
      if (!response || !response.items) {
        // eslint-disable-next-line no-console
        console.error('Invalid response received:', response);
        return { items: [], nextToken: null, queryExecutionId: null };
      }
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching transfers:', error);
      return { items: [], nextToken: null, queryExecutionId: null };
    }
  };

  const fetchAdjustments = async () => {
    try {
      const response = await getAllAdjustments();
      if (!response) {
        // eslint-disable-next-line no-console
        console.error('Invalid response received for adjustments:', response);
        return [];
      }
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching adjustments:', error);
      return [];
    }
  };

  const { refetch, isFetching, data, isSuccess } = useQuery({
    queryKey: ['getAllTransfers', pagination],
    queryFn: () => fetchTransfers(pagination),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (isSuccess) {
      setTransferData(prevData => [
        ...prevData,
        ...data.items.map(item => ({
          amount: item.amount,
          account: item.account,
          // sourceAccount: item.sourceaccount,
          // destinationAccount: item.destinationaccount,
          status: item.state,
          reason: item.reason,
          transferId: item.transferid,
          externalTransferId: item.externaltransferid,
          type: item.type,
          uuid: item.id,
          withdrawalitemid: item.withdrawalitemid || '',
          withdrawalid: item.withdrawalid || '',
          dateSubmitted: moment(item.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          lastUpdated: moment(item.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
          actions: (
            <div className="actions-right">
              <Button
                onClick={() =>
                  navigate(`/transfers/${item.externaltransferid}`)
                }
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>
            </div>
          ),
        })),
      ]);
      setPagination({
        ...pagination,
        nextToken: data.nextToken || null,
        queryExecutionId: data.queryExecutionId || null,
      });
    }
  }, [isSuccess, data, navigate, pagination]);

  const { data: adjustmentData } = useQuery({
    queryKey: ['getAllAdjustments'],
    queryFn: fetchAdjustments,
  });

  const handleRefresh = () => {
    setTransferData([]);
    setPagination({
      pageSize: pagination.pageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handlePageSizeChange = newPageSize => {
    setTransferData([]);
    setPagination({
      pageSize: newPageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleLoadMore = () => {
    if (pagination.nextToken) {
      refetch();
    } else {
      // eslint-disable-next-line no-console
      console.warn('No more data to load.');
    }
  };

  return (
    <Container fluid className="content">
      <Col md={12}>
        <h2 className="text-center">Transfers</h2>
      </Col>
      <Col md={12} className="ml-auto mr-auto">
        <Row className="mt-5">
          <Col md={12}>
            <Card>
              <CardHeader>
                <div>
                  <label htmlFor="pageSize" style={{ marginRight: '10px' }}>
                    Page Size:
                  </label>
                  <select
                    id="pageSize"
                    value={pagination.pageSize}
                    onChange={e => handlePageSizeChange(Number(e.target.value))}
                    style={{ marginRight: '20px' }}
                  >
                    <option value={100}>100</option>
                    <option value={50}>50</option>
                    <option value={25}>25</option>
                    <option value={10}>10</option>
                  </select>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search by account..."
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{
                      marginTop: '10px',
                      padding: '5px',
                      width: '250px',
                    }}
                  />
                </div>
                <Button
                  onClick={handleRefresh}
                  color="#000000"
                  size="sm"
                  style={{ marginLeft: '20px' }}
                >
                  Refresh
                </Button>
              </CardHeader>
              <CardBody>
                {filteredTransferData && filteredTransferData?.length > 0 ? (
                  <>
                    <ReactTable
                      data={filteredTransferData}
                      filterable
                      columns={[
                        {
                          Header: 'Details',
                          accessor: 'actions',
                          sortable: false,
                          filterable: false,
                        },
                        { Header: 'Amount', accessor: 'amount' },
                        { Header: 'Account', accessor: 'account' },
                        //   { Header: 'Source Account', accessor: 'sourceaccount' },
                        //   { Header: 'Destination Account', accessor: 'destinationaccount' },
                        { Header: 'Overall Status', accessor: 'status' },
                        //  { Header: 'Date Submitted', accessor: 'dateSubmitted' }, // Commenting this out, as it still shows current datetime.
                        { Header: 'Last Updated', accessor: 'lastUpdated' }, // this works fine and is generally more useful
                        { Header: 'Reason', accessor: 'reason' },
                        { Header: 'Transfer ID', accessor: 'transferId' },
                        {
                          Header: 'External Transfer ID',
                          accessor: 'externalTransferId',
                        },
                        { Header: 'Type', accessor: 'type' },
                        { Header: 'UUID', accessor: 'uuid' },
                        {
                          Header: 'Withdrawal Item ID',
                          accessor: 'withdrawalitemid',
                        },
                        { Header: 'Withdrawal ID', accessor: 'withdrawalid' },
                      ]}
                      defaultPageSize={pagination.pageSize}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                    {pagination.nextToken && (
                      <Button
                        onClick={handleLoadMore}
                        color="#000000"
                        size="sm"
                        disabled={isFetching}
                        style={{ marginTop: '10px' }}
                      >
                        Load More
                      </Button>
                    )}
                  </>
                ) : filteredTransferData &&
                  filteredTransferData?.length === 0 ? (
                  <span>Loading...</span>
                ) : (
                  <span>No transfers found.</span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="ml-auto mr-auto">
        <h2 className="text-center">Dividends</h2>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <Col md={6} className="ml-auto mr-auto">
                <h2 className="text-center">Dividends</h2>
              </Col>
              <CardBody style={{ maxHeight: '800px', overflow: 'auto' }}>
                {adjustmentData && adjustmentData?.length > 0 ? (
                  <SimpleTable
                    thead={Object.keys(adjustmentData[0]).map(key => ({
                      text: key,
                    }))}
                    tbody={adjustmentData.map(obj => ({
                      data: Object.values(obj).map(value => ({
                        text: value ? String(value) : '',
                      })),
                    }))}
                  />
                ) : (
                  <span>Loading...</span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default Transfers;
