/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { ThemeProvider, createTheme } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import awsmobile from './aws-exports';
import AppRouter from './routes/router';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-tooltip/dist/react-tooltip.css';

const env = process.env.REACT_APP_BUILD_ENV;

let awsconfig;

if (env === 'prod') {
  awsconfig = awsmobile.awsmobileProd;
} else {
  awsconfig = awsmobile.awsmobilePreProd;
}

if (!awsconfig) {
  throw new Error('No AWS config found for environment');
}

Amplify.configure(awsconfig);
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2, refetchOnWindowFocus: true } },
});

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </Authenticator.Provider>
  );
}

export default App;
