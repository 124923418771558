/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import GroupInvestingService from '../../../Services/GroupInvestingService';

export default function GroupAllocationsTable({ proposalId }) {
  const groupAllocationsQuery = useQuery({
    queryKey: ['allocationEvents', proposalId],
    queryFn: () =>
      GroupInvestingService.getAllocationEventsForProposal(proposalId),
    placeholderData: keepPreviousData,
  });

  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-center">
        <Col xs={12} md={12}>
          {groupAllocationsQuery.isLoading && <Spinner color="primary" />}
          {groupAllocationsQuery.isError && (
            <Alert color="danger">
              Error fetching data:
              {groupAllocationsQuery.error.message}
            </Alert>
          )}
          {groupAllocationsQuery.isSuccess && groupAllocationsQuery.data && (
            <Card className="mb-4">
              <CardBody>
                <CardTitle tag="h2">Allocation Events</CardTitle>
                <ReactTable
                  isSearchActive
                  tableRoute="groups"
                  data={groupAllocationsQuery.data}
                  columns={[
                    { Header: 'ID', accessor: 'id' },
                    { Header: 'Status', accessor: 'status' },
                    { Header: 'Correspondent', accessor: 'correspondent' },
                    { Header: 'External ID', accessor: 'externalid' },
                    { Header: 'Account Number', accessor: 'accountnumber' },
                    { Header: 'Timestamp', accessor: 'timestamp' },
                    { Header: 'DateTime', accessor: 'datetime' },
                    {
                      Header: 'Destination Account',
                      accessor: 'destinationaccount',
                    },
                    { Header: 'Source Account', accessor: 'sourceaccount' },
                    { Header: 'Symbol', accessor: 'symbol' },
                    { Header: 'Type', accessor: 'type' },
                    { Header: 'Quantity', accessor: 'quantity' },
                    { Header: 'APA Order ID', accessor: 'apaorderid' },
                  ]}
                />
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}
