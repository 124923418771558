/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
  Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import {
  getAllAchRelationships,
  getAchRelationshipById,
} from 'Services/AccountServices';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ReactTable from '../Components/ReactTable/ReactTable';

function AchRelationships() {
  const [newData, setNewData] = useState([]);
  const navigate = useNavigate();
  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['getAllAchRelationships'],
    queryFn: () => getAllAchRelationships(),
  });
  const handlePressAch = useCallback(
    async achRelationshipId => {
      const achData = await getAchRelationshipById(achRelationshipId);
      navigate(`/achrelationships/${achRelationshipId}`, {
        state: { achRelationshipData: [achData] },
      });
    },
    [navigate]
  );

  useEffect(() => {
    if (isSuccess) {
      setNewData(
        data.map((prop, key) => ({
          id: key,
          relationshipId: prop.relationshipid,
          status: prop?.status,
          reason: prop?.reason,
          account: prop?.account,
          correspondentCode: prop?.correspondentcode,
          approvalMethod: prop?.approvalmethod,
          uuid: prop.id,
          dateSubmitted: moment(prop.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          lastUpdated: moment(prop.timestamp).format('MM/DD/YYYY HH:mm:ss A'),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  handlePressAch(prop?.relationshipid);
                }}
                color="#000000"
                size="sm"
                disabled={data?.length === 0}
              >
                <i className="tim-icons icon-double-right" />
              </Button>{' '}
            </div>
          ),
        }))
      );
    }
  }, [isSuccess, data, handlePressAch]);

  function handleRefresh() {
    refetch();
  }

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">ACH Relationships</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {data && data.length > 0 ? (
                <ReactTable
                  data={newData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Relationship ID',
                      accessor: 'relationshipId',
                    },
                    {
                      Header: 'Overall Status',
                      accessor: 'status',
                    },
                    {
                      Header: 'Reason',
                      accessor: 'reason',
                    },
                    {
                      Header: 'Account',
                      accessor: 'account',
                    },
                    {
                      Header: 'Correspondent Code',
                      accessor: 'correspondentCode',
                    },
                    {
                      Header: 'Approval Method',
                      accessor: 'approvalMethod',
                    },
                    {
                      Header: 'UUID',
                      accessor: 'uuid',
                    },
                    {
                      Header: 'Date Submitted',
                      accessor: 'dateSubmitted',
                    },
                    {
                      Header: 'Last Updated',
                      accessor: 'lastUpdated',
                    },
                    {
                      Header: 'Details',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              ) : (
                <span> loading </span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AchRelationships;
