/* eslint-disable no-console */
import axios from 'axios';
import { APIRequestWithAuth } from './config';
import AuthenticationService from './Auth.service';
import { openSearchEndpoint } from '../utils.env';

export const getApexAccountDetails = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getApexAccountDetails/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const updateAccountStatus = async ({ accountNumber, newStatus }) => {
  const data = await APIRequestWithAuth('PUT', '/v1/account/status', {
    accountNumber,
    accountStatus: newStatus,
  });
  if (data) {
    return data;
  }
  return null;
};

export const getPdtHistory = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getPdtHistory/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAllGroupWithdrawals = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/v1/groupWithdrawal/summary');
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllPdtTrades = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/v1/getPdtTrades');
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPdtByUser = async uuidUsersDb => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getPdtByUserId/${uuidUsersDb}`
  );
  if (data) {
    return data;
  }
  return null;
};

// eslint-disable-next-line camelcase
export const getIndvPdtByExtId = async externalid => {
  // eslint-disable-next-line camelcase
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/indv/getIndvPdtByExternalId/${externalid}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getApexInvestigationDetails = async requestId => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getInvestigationDetails/${requestId}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAllApexWithSketch = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAllApexWithSketch');
  if (data) {
    return data;
  }
  return null;
};

export const getAllTransfers = async () => {
  console.log('getAllTransfers called by Admin Dash');
  try {
    const data = await APIRequestWithAuth('GET', '/v1/getTransfers');
    if (data) {
      console.log(
        'getAllTransfers successfully returned the following data to the frontend:',
        data
      );
      return data;
    }
    return null;
  } catch (error) {
    console.error(
      'Error fetching transfers >> getAllTransfers (frontend > accountServices.js):',
      error
    );
    return null;
  }
};

export const getAllAdjustments = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAdjustments');
  if (data) {
    return data;
  }
  return null;
};

export const getTransferHistoryById = async externalId => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getTransferHistoryByExternalId/${externalId}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAllOrders = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getOrders');
  if (data) {
    return data;
  }
  return null;
};

export const getOrderHistoryById = async externalId => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getOrderHistoryByExternalId/${externalId}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAllOutdatedIncompleteOrders = async () => {
  try {
    const data = await APIRequestWithAuth(
      'GET',
      '/v1/getOutdatedIncompleteOrders'
    );
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllGroupOrders = async () => {
  try {
    const data = await APIRequestWithAuth('GET', '/v1/getGroupOrders');
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getIndvGroupOrdersByExtOrApaId = async orderUuid => {
  try {
    console.log('passing thru orderUuid as:', orderUuid);
    const data = await APIRequestWithAuth(
      'GET',
      `/v1/indv/getIndvGroupOrdersByExternalIdOrApaOrderId/${orderUuid}`
    );
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllOrdersTransfersByAcct = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getOrdersTransfersByAccount/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getReconEvents = async ({
  userid,
  groupid,
  accountNum,
  eventSymbol,
  reconciled,
  nextToken,
  queryExecutionId,
  pageSize,
} = {}) => {
  try {
    // Construct query parameters
    const queryParams = new URLSearchParams();
    if (userid) queryParams.append('userid', userid);
    if (groupid) queryParams.append('groupid', groupid);
    if (accountNum) queryParams.append('accountNum', accountNum);
    if (eventSymbol) queryParams.append('eventSymbol', eventSymbol);
    if (reconciled) queryParams.append('reconciled', reconciled);
    if (nextToken) queryParams.append('nextToken', nextToken);
    if (queryExecutionId)
      queryParams.append('queryExecutionId', queryExecutionId);
    if (pageSize) queryParams.append('pageSize', pageSize);

    // Construct the full URL with query parameters
    const url = `/v1/getReconEvents${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
    const data = await APIRequestWithAuth('GET', url);
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getReconEventsByUser = async userid => {
  try {
    if (!userid) {
      console.error('getReconEventsByUser by userid called with no userid');
      return null;
    }
    console.log('getReconEventsByUser by userid called with userid:', userid);
    const data = await APIRequestWithAuth(
      'GET',
      `/v1/getReconEventsByUser/${userid}`
    );
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getReconEventsByAccount = async eventaccount => {
  try {
    if (!eventaccount) {
      console.error(
        'getReconEvgetReconEventsByAccount by eventaccount called with no eventaccount'
      );
      return null;
    }
    console.log(
      'getReconEventsByAccount by eventaccount called with eventaccount:',
      eventaccount
    );
    const data = await APIRequestWithAuth(
      'GET',
      `/v1/getReconEventsByAccount/${eventaccount}`
    );
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getReconEventsByGroup = async groupid => {
  try {
    const data = await APIRequestWithAuth(
      'GET',
      `/v1/getReconEventsByGroup/${groupid}`
    );
    if (data) {
      return data;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllAchRelationships = async () => {
  const data = await APIRequestWithAuth('GET', '/v1/getAchRelationships');
  if (data) {
    return data;
  }
  return null;
};

export const uploadApexDocument = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/uploadApexInvestigationDocument',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchRelationship = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/cancelAchRelationship',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const createAchWithdrawal = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/createAchWithdrawal',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchWithdrawal = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/cancelAchWithdrawal',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const cancelAchDeposit = async body => {
  const data = await APIRequestWithAuth('POST', '/v1/cancelAchDeposit', body);
  if (data) {
    return data;
  }
  return null;
};

export const getAchRelationshipById = async id => {
  const data = await APIRequestWithAuth('GET', `/v1/getAchRelationship/${id}`);
  if (data) {
    return data;
  }
  return null;
};

export const getInternalInvestigationHistory = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/getInternalInvestigationHistory/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getIdentityDocsByAccountNumber = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/identity/docs/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const getUserInfoByApexAccountNumber = async accountNum => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/identity/user/${accountNum}`
  );
  if (data) {
    return data;
  }
  return null;
};

export const updateInternalInvestigationState = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/identity/internalInvestigationEvent',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const manuallyApproveIndeterminateInvestigation = async body => {
  const data = await APIRequestWithAuth(
    'POST',
    '/v1/identity/autoApproveApexApplication',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const searchUsers = async searchTerm => {
  try {
    const { idToken } = await AuthenticationService.getSession();
    const jwt = idToken.toString();
    if (!jwt) {
      console.error('JWT token not found');
      return null;
    }

    const headers = {
      Authorization: `Bearer ${jwt}`,
    };

    const response = await axios.get(
      `${openSearchEndpoint()}/users/search?searchTerm=${encodeURIComponent(searchTerm)}`,
      { headers }
    );
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error('Error fetching users:', error);
    return null;
  }
};

export const updateAccountInternalStatus = async body => {
  const data = await APIRequestWithAuth(
    'PUT',
    '/v1/accounts/internalState',
    body
  );
  if (data) {
    return data;
  }
  return null;
};

export const getAccountInternalStatus = async cognito => {
  const data = await APIRequestWithAuth(
    'GET',
    `/v1/accounts/internalState/${cognito}`
  );
  if (data) {
    return data;
  }
  return null;
};
