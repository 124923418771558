/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Alert,
} from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import GroupInvestingService from '../../../Services/GroupInvestingService';

export default function GroupOrdersTable({ proposalId }) {
  const groupOrdersQuery = useQuery({
    queryKey: ['orderEvents', proposalId],
    queryFn: () => GroupInvestingService.getOrderEventsForProposal(proposalId),
    placeholderData: keepPreviousData,
  });

  return (
    <Container fluid className="mt-4">
      <Row className="justify-content-center">
        <Col xs={12} md={12}>
          {groupOrdersQuery.isLoading && <Spinner color="primary" />}
          {groupOrdersQuery.isError && (
            <Alert color="danger">Error fetching data</Alert>
          )}
          {groupOrdersQuery.isSuccess && groupOrdersQuery.data && (
            <Card className="mb-4">
              <CardBody>
                <CardTitle tag="h2">Order Events</CardTitle>
                <ReactTable
                  isSearchActive
                  tableRoute="groups"
                  data={groupOrdersQuery.data}
                  columns={[
                    { Header: 'ID', accessor: 'id' },
                    { Header: 'Status', accessor: 'status' },
                    { Header: 'Account', accessor: 'account' },
                    { Header: 'External ID', accessor: 'externalid' },
                    { Header: 'Symbol', accessor: 'symbol' },
                    { Header: 'Side', accessor: 'side' },
                    { Header: 'Share Quantity', accessor: 'sharequantity' },
                    { Header: 'Average Price', accessor: 'avgprice' },
                    { Header: 'Notional', accessor: 'notional' },
                    { Header: 'Source', accessor: 'source' },
                    { Header: 'Type', accessor: 'type' },
                    { Header: 'DateTime', accessor: 'datetime' },
                    { Header: 'APA Order ID', accessor: 'apaorderid' },
                  ]}
                />
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}
