/* eslint-disable */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// reactstrap components
import { Table } from 'reactstrap';

function SortingTable(props) {
  const [bodyData, setBodyData] = useState(props.tbody);
  const [column, setColumn] = useState({
    name: -1,
    order: '',
  });

  useEffect(() => {
    if (Array.isArray(props.tbody)) {
      setBodyData(props.tbody);
    } else {
      setBodyData([]);
    }
    setColumn({
      name: -1,
      order: '',
    }); // Reset sorting when tbody changes
  }, [props.tbody]);

  const sortTable = (key) => {
    if (!Array.isArray(bodyData) || bodyData.length === 0) return;

    let order = 'asc';

    if (column.name === key && column.order === 'asc') {
      order = 'desc';
    }

    const sortedData = [...bodyData].sort((a, b) => {
      const aText = a.data[key]?.text;
      const bText = b.data[key]?.text;

      if (aText > bText) return order === 'asc' ? 1 : -1;
      if (aText < bText) return order === 'asc' ? -1 : 1;
      return 0;
    });

    setBodyData(sortedData);
    setColumn({
      name: key,
      order,
    });
  };

  if (!Array.isArray(props.thead) || props.thead.length === 0) {
    return <p>No table headers provided.</p>;
  }

  if (!Array.isArray(bodyData) || bodyData.length === 0) {
    return <p>No table data available.</p>;
  }

  return (
    <Table className="tablesorter custom-table" responsive bordered hover >
      <thead className="text-primary">
        <tr>
          {props.thead.map((prop, key) => (
            <th
              className={classnames(
                'header',
                {
                  headerSortDown:
                    key === column.name && column.order === 'asc',
                },
                {
                  headerSortUp: key === column.name && column.order === 'desc',
                },
                {
                  [prop.className]: prop.className !== undefined,
                },
              )}
              key={key}
            >
              <button
                type="button"
                onClick={() => sortTable(key)}
                className="btn btn-link p-0"
                aria-sort={
                  key === column.name
                    ? column.order === 'asc'
                      ? 'ascending'
                      : 'descending'
                    : 'none'
                }
                aria-label={`Sort by ${prop.text}`}
              >
                {prop.text}
              </button>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {bodyData.map((prop, key) => (
          <tr
            className={classnames({
              [prop.className]: prop.className !== undefined,
            })}
            key={key}
          >
            {prop.data.map((data, k) => (
              <td
                className={classnames({
                  [data.className]: data.className !== undefined,
                })}
                key={k}
              >
                {data.text !== undefined && data.text !== null ? data.text : 'N/A'}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

SortingTable.propTypes = {
  thead: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tbody: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          className: PropTypes.string,
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default SortingTable;
