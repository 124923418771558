/* eslint-disable quotes */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { getPolygonDiff } from 'Services/WhitelistService';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  addStockToAdditions,
  removeStockFromAdditions,
  addStockToRemovals,
  removeStockFromRemovals,
} from 'store/Reducers/whitelist';
import { Button, Container, Row, Col } from 'reactstrap';
import TableStats from './TableStats';

export default function PolygonDiffTable() {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const [dataType, setDataType] = useState('ADD');
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'ticker',
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    { field: 'name', filter: true },
    { field: 'active' },
    { field: 'type' },
    { field: 'locale' },
    { field: 'currency_name' },
    { field: 'cik' },
    { field: 'composite_figi' },
    { field: 'share_class_figi' },
    { field: 'market' },
    { field: 'primary_exchange' },
    { field: 'delisted_utc' },
    { field: 'sic_code' },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ['polygonDiff'],
    queryFn: () => getPolygonDiff(),
  });

  useEffect(() => {
    if (isSuccess) {
      if (dataType === 'ADD') {
        setChartData(data.symbolsToAdd);
        setColumnDefs(prev => {
          prev[0].field = 'ticker';
          prev[1].field = 'name';
          return prev;
        });
      } else {
        setChartData(data.symbolsToRemove);
        setColumnDefs(prev => {
          prev[0].field = 'symbol';
          prev[1].field = 'securityName';
          return prev;
        });
      }
    }
  }, [isSuccess, data, dataType]);

  const icons = useMemo(
    () => ({
      'custom-stats': '<span class="ag-icon ag-icon-custom-stats"></span>',
    }),
    []
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'customStats',
          labelDefault: 'Stats',
          labelKey: 'customStats',
          iconKey: 'custom-stats',
          toolPanel: TableStats,
        },
      ],
      defaultToolPanel: 'customStats',
    }),
    []
  );

  const handleChangePress = useCallback(() => {
    setDataType(dataType === 'ADD' ? 'REMOVE' : 'ADD');
    refetch();
  }, [dataType, refetch]);

  const handleRowSelected = e => {
    const { data: innerData, node } = e;
    if (dataType === 'ADD') {
      if (node.selected) dispatch(addStockToAdditions(innerData));
      else dispatch(removeStockFromAdditions(innerData));
    } else if (dataType === 'REMOVE') {
      if (node.selected) dispatch(addStockToRemovals(innerData));
      else dispatch(removeStockFromRemovals(innerData));
    }
  };

  const handleRefresh = async () => {
    await refetch();
  };
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col>
          <h1>Polygon Diff</h1>
        </Col>
        <Col>
          <Button
            onClick={handleRefresh}
            style={{ marginBottom: '2%', marginLeft: '3%' }}
            size="md"
          >
            Refresh
          </Button>
        </Col>
      </Row>
      <h2>
        Symbols to
        <span style={{ fontWeight: 'bold' }}> {dataType}</span>
      </h2>
      <Button
        style={{ borderRadius: 20, padding: 10, marginBottom: 5 }}
        onClick={handleChangePress}
      >
        Switch to symbols to {dataType === 'ADD' ? 'REMOVE' : 'ADD'}
      </Button>
      <Row className="mt-4" style={{ minHeight: '500px' }}>
        <Col md="12">
          <AgGridReact
            className="ag-theme-alpine"
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows
            rowSelection="multiple"
            rowData={chartData}
            sideBar={sideBar}
            icons={icons}
            onRowSelected={handleRowSelected}
            rowMultiSelectWithClick
          />
        </Col>
      </Row>
    </Container>
  );
}
