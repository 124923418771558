/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import { useQuery } from '@tanstack/react-query';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function GroupWithdrawals({ groupId }) {
  const withdrawalsQuery = useQuery({
    queryKey: ['withdrawals', groupId],
    queryFn: () => GroupInvestingService.getGroupWithdrawalsByGroupId(groupId),
  });

  const columns = [
    {
      Header: 'Withdrawal ID',
      accessor: 'withdrawalid',
    },
    {
      Header: 'Member Account',
      accessor: 'memberaccount',
    },
    {
      Header: 'Personal Account',
      accessor: 'personalaccount',
    },
    {
      Header: 'User ID',
      accessor: 'userid',
    },
    {
      Header: 'Initiated By',
      accessor: 'initiatedby',
    },
    {
      Header: 'Withdrawal Percentage',
      accessor: 'withdrawalpercentage',
      Cell: ({ value }) => `${(value * 100).toFixed(2)}%`,
    },
    {
      Header: 'Request Date',
      accessor: 'withdrawalrequestdatetime',
      Cell: ({ value }) =>
        new Date(value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          fractionalSecondDigits: 3,
        }),
    },
    {
      Header: 'Last Updated',
      accessor: 'withdrawalrequestlastupdateddatetime',
      Cell: ({ value }) =>
        new Date(value).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          fractionalSecondDigits: 3,
        }),
    },
    {
      Header: 'State',
      accessor: 'withdrawalstate',
    },
    {
      Header: 'Total Value at Initiation',
      accessor: 'totalvalueatinitiation',
      Cell: ({ value }) => `$${parseFloat(value)?.toLocaleString()}`,
    },
    {
      Header: 'Withdrawal Items',
      accessor: 'withdrawalItems',
      Cell: ({ value }) => (
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              {item.type === 'POSITION_JOURNAL'
                ? `${item.ticker} (${item.shares} shares)`
                : `Cash: $${item.amount}`}
            </li>
          ))}
        </ul>
      ),
    },
    {
      Header: 'Withdrawal Details',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="actions-right">
          <Link
            to={`/groups/${groupId}/withdrawals/${row.original.withdrawalid}`}
            state={{ withdrawal: row.original }}
          >
            <i className="tim-icons icon-double-right" />
          </Link>
        </div>
      ),
      sortable: false,
      filterable: false,
      turnFilterOff: true,
    },
  ];

  const handleRefresh = () => {
    withdrawalsQuery.refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg">
      <CardBody>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            Group Withdrawals
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {withdrawalsQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </div>
        <Row className="mb-3">
          <Col>
            {withdrawalsQuery.isLoading && <div>Loading...</div>}
            {withdrawalsQuery.isError && <div>Error loading data</div>}
            {withdrawalsQuery.isSuccess && !withdrawalsQuery.data.length && (
              <div>No Withdrawals Available</div>
            )}
            {withdrawalsQuery.isSuccess &&
              withdrawalsQuery.data &&
              withdrawalsQuery.data.length > 0 && (
                <ReactTable
                  data={withdrawalsQuery.data}
                  columns={columns}
                  isSearchActive
                />
              )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
