/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-closing-tag-location */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  Container,
} from 'reactstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-unresolved
import { getAllOutdatedIncompleteOrders } from 'Services/AccountServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../Components/ReactTable/ReactTable';

function IncompleteOldOrders() {
  const navigate = useNavigate();
  const [incompleteOldOrderData, IncompleteOldOrderData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 100,
    nextToken: null,
    queryExecutionId: null,
  });

  const fetchIncompleteOldOrders = async ({
    pageSize,
    nextToken,
    queryExecutionId,
  }) => {
    const params = { pageSize, nextToken, queryExecutionId };
    try {
      const response = await getAllOutdatedIncompleteOrders(params);
      if (!response || !response.items) {
        // eslint-disable-next-line no-console
        console.error('Invalid response received:', response);
        return { items: [], nextToken: null, queryExecutionId: null };
      }
      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching orders:', error);
      return { items: [], nextToken: null, queryExecutionId: null };
    }
  };

  const handlePress = useCallback(
    prop => {
      const orderUuid =
        prop.orderUuid ||
        prop.apaorderid ||
        prop.externalid.slice(0, -9).startsWith('-3HD')
          ? prop.externalid.slice(0, -9)
          : prop.externalid;
      if (!orderUuid) {
        // eslint-disable-next-line no-console
        console.error('Error: Missing orderUuid. Cannot navigate.', prop);
        return;
      }
      // eslint-disable-next-line no-console
      navigate(`/orders/${orderUuid}`);
    },
    [navigate]
  );

  const {
    // isLoading,
    isError,
    error,
    refetch,
    isFetching,
    isSuccess,
    data,
  } = useQuery({
    queryKey: ['getAllOutdatedIncompleteOrders', pagination],
    queryFn: () => fetchIncompleteOldOrders(pagination),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (isSuccess) {
      IncompleteOldOrderData(prevData => [
        ...prevData,
        ...data.items.map(prop => ({
          lastUpdated: prop.datetime
            ? moment(prop.datetime).format('MM/DD/YYYY HH:mm:ss A')
            : 'N/A',
          status: prop.status || 'Unknown',
          account: prop.account,
          symbol: prop.symbol,
          side: prop.side,
          shareQuantity: prop.sharequantity,
          avgPrice: prop.avgprice,
          notional: prop.notional,
          uuid: prop.id,
          source: prop.source,
          type: prop.type,
          ordertype: prop.ordertype,
          externalId: prop.externalid,
          apaOrderId: prop.apaorderid,
          orderUuid:
            prop.orderUuid ||
            prop.apaorderid ||
            prop.externalid.slice(0, -9).startsWith('-3HD')
              ? prop.externalid.slice(0, -9)
              : prop.externalid,
          dateSubmitted: moment(prop.originaldatetime).format(
            'MM/DD/YYYY HH:mm:ss A'
          ),
          actions: (
            <div className="actions-right">
              <Button
                onClick={() => {
                  handlePress(prop);
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-double-right" />
              </Button>
            </div>
          ),
        })),
      ]);
      setPagination({
        ...pagination,
        nextToken: data.nextToken || null,
        queryExecutionId: data.queryExecutionId || null,
      });
    }
  }, [isSuccess, data, handlePress, pagination]);

  const handleRefresh = () => {
    IncompleteOldOrderData([]);
    setPagination({
      pageSize: pagination.pageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handlePageSizeChange = newPageSize => {
    IncompleteOldOrderData([]);
    setPagination({
      pageSize: newPageSize,
      nextToken: null,
      queryExecutionId: null,
    });
    refetch();
  };

  const handleLoadMore = () => {
    if (pagination.nextToken) {
      refetch();
    } else {
      // eslint-disable-next-line no-console
      console.warn('No more data to load.');
    }
  };

  if (isError) {
    return (
      <div className="flex h-screen bg-zinc-800">
        <div className="flex justify-center items-start mt-10">
          <span>
            Error loading data:
            {error?.message || 'Unknown error'}
          </span>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">Incomplete Orders</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader>
              <div>
                <label htmlFor="pageSize" style={{ marginRight: '10px' }}>
                  Page Size:
                </label>
                <select
                  id="pageSize"
                  value={pagination.pageSize}
                  onChange={e => handlePageSizeChange(Number(e.target.value))}
                  style={{ marginRight: '20px' }}
                >
                  <option value={100}>100</option>
                  <option value={50}>50</option>
                  <option value={25}>25</option>
                  <option value={10}>10</option>
                </select>
              </div>
              <Button
                onClick={() => {
                  handleRefresh();
                }}
                color="#000000"
                size="sm"
              >
                <i className="tim-icons icon-refresh-01" />
              </Button>{' '}
            </CardHeader>
            <CardBody>
              {incompleteOldOrderData.length > 0 ? (
                <>
                  <ReactTable
                    data={incompleteOldOrderData}
                    filterable
                    columns={[
                      {
                        Header: 'Overall Status',
                        accessor: 'status',
                      },
                      {
                        Header: 'Account',
                        accessor: 'account',
                      },
                      {
                        Header: 'Symbol',
                        accessor: 'symbol',
                      },
                      {
                        Header: 'Side',
                        accessor: 'side',
                      },
                      {
                        Header: 'Share Quantity',
                        accessor: 'shareQuantity',
                      },
                      {
                        Header: 'Avg Price',
                        accessor: 'avgPrice',
                      },
                      {
                        Header: 'Notional',
                        accessor: 'notional',
                      },
                      {
                        Header: 'Source',
                        accessor: 'source',
                      },
                      {
                        Header: 'Type',
                        accessor: 'type',
                      },
                      // {
                      //   Header: 'Order Type',
                      //   accessor: 'ordertype',
                      // },
                      // {
                      //   Header: 'External ID',
                      //   accessor: 'externalId',
                      // },
                      // {
                      //   Header: 'APA Order ID',
                      //   accessor: 'apaOrderId',
                      // },
                      {
                        Header: 'Order UUID',
                        accessor: 'orderUuid',
                      },
                      // {
                      //   Header: 'Date Submitted',
                      //   accessor: 'dateSubmitted',
                      // },
                      {
                        Header: 'Last Updated',
                        accessor: 'lastUpdated',
                      },
                      {
                        Header: 'Details',
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={pagination.pageSize}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                  {pagination.nextToken && (
                    <Button
                      onClick={handleLoadMore}
                      color="#000000"
                      size="sm"
                      disabled={isFetching}
                      style={{ marginTop: '10px' }}
                    >
                      Load More
                    </Button>
                  )}
                </>
              ) : incompleteOldOrderData.length === 0 ? (
                <span>Loading...</span>
              ) : (
                <span>No data available</span>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default IncompleteOldOrders;
