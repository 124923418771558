const openSearchEndpoint = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return 'https://preprod-opensearch.hedge.io'; // overwrite here if you want to test against local opensearch
    case 'preprod':
      return 'https://preprod-opensearch.hedge.io';
    case 'prod':
      return 'https://prod-opensearch.hedge.io';
    default:
      throw new Error('Invalid environment for opensearch configuration');
  }
};

export { openSearchEndpoint };
