/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, CardText, Row, Col, Button, Spinner } from 'reactstrap';
import UserService from 'Services/UserService';

export default function UserAccountSummaryBox({ userId }) {
  const userAccountsQuery = useQuery({
    queryKey: ['getAllAccountsForUser', userId],
    queryFn: () =>
      UserService.getAllAccountsForUser(userId),
  });

  const handleRefresh = () => {
    userAccountsQuery.refetch();
  };


  return (
    <Card className="bg-dark text-white shadow-lg">
      {userAccountsQuery.data === null && <CardBody>Loading...</CardBody>}
      {userAccountsQuery.data && (
        <CardBody>
          <CardTitle tag="h2" className="text-left mb-4">
            <strong>Accounts:</strong>
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {userAccountsQuery.isFetching && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
          <Row className="mb-4">
            <Col md="4">
              <CardText className="h3">
                <strong>Total Accounts: </strong> {userAccountsQuery.data.userAccountNumsCount.totalAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Total Active: </strong> {userAccountsQuery.data.userAccountNumsCount.activeAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userActiveAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Total Inactive: </strong> {userAccountsQuery.data.userAccountNumsCount.inactiveAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userInactiveAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
            </Col>
            <Col md="3">
              <CardText className="h3">
                <strong>Personal: </strong> {userAccountsQuery.data.userAccountNumsCount.personalAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userPersonalAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Active PAs: </strong> {userAccountsQuery.data.userAccountNumsCount.activePersonalAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userActivePersonalAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Inactive PAs: </strong> {userAccountsQuery.data.userAccountNumsCount.inactivePersonalAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userInactivePersonalAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
            </Col>
            <Col md="3">
              <CardText className="h3">
                <strong>Total Member: </strong> {userAccountsQuery.data.userAccountNumsCount.memberAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userMemberAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Active MAs: </strong> {userAccountsQuery.data.userAccountNumsCount.activeMemberAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userActiveMemberAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
              <CardText className="h3">
                <strong>Inactive MAs: </strong> {userAccountsQuery.data.userAccountNumsCount.inactiveMemberAccounts}
              </CardText>
              <CardText className="h4">
                {JSON.stringify(userAccountsQuery.data.userAccountsObjects.userInactiveMemberAccounts.reduce((acc, account) => {acc.push(account.account_num); return acc;}, [])).split(',').join(', ').replace(/"/g, '').replace('[', '').replace(']', '')}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}