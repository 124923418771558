/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useMemo } from 'react';
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
  Button,
  Container,
} from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import './TableStyles.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactTable from '../Components/ReactTable/ReactTable';

function AccountsReconciliationDetails() {
  const { date } = useParams();
  const navigate = useNavigate();
  const { allAccountsReconciliation } = useLocation().state;

  const handlePress = useCallback(
    accountNumber => {
      navigate(`/accounts/${accountNumber}`);
    },
    [navigate]
  );

  const tableData = useMemo(() => {
    if (!allAccountsReconciliation || !allAccountsReconciliation.length)
      return [];
    return allAccountsReconciliation.map(prop => ({
      account_number_action: (
        <Button
          onClick={() => {
            handlePress(prop.account_number);
          }}
          color="#000000"
          size="sm"
        >
          {prop.account_number} Details
        </Button>
      ),
      account_number: prop.account_number,
      account_id: prop.account_id,
      account_type: prop.account_type,
      // if account_group is NaN or null, display 'N/A
      account_group:
        prop.account_group.toString() === 'NaN' || prop.account_group === null
          ? 'N/A'
          : prop.account_group,
      hedge_rds: prop.hedge_rds,
      hedge_status: prop.hedge_status,
      apex_status: prop.apex_status,
      needs_attention: prop.needs_attention,
    }));
  }, [allAccountsReconciliation, handlePress]);

  return (
    <Container fluid className="content">
      <Col md={6} className="ml-auto mr-auto">
        <h2 className="text-center">{`Account Reconciliation Details For ${date}`}</h2>
      </Col>
      <Row className="mt-5">
        <Col xs={12} md={12}>
          <Card>
            <CardHeader className="table-header">Total Accounts</CardHeader>
            <CardBody>
              {tableData.length > 0 ? (
                <ReactTable
                  tableRoute="acctReconDetails"
                  data={tableData}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: 'Account Number',
                      accessor: 'account_number',
                    },
                    {
                      Header: 'Account Number Details',
                      accessor: 'account_number_action',
                      filterable: false,
                    },
                    {
                      Header: 'Account ID',
                      accessor: 'account_id',
                    },
                    {
                      Header: 'Account Type',
                      accessor: 'account_type',
                    },
                    {
                      Header: 'Account Group',
                      accessor: 'account_group',
                    },
                    {
                      Header: 'Hedge RDS',
                      accessor: 'hedge_rds',
                    },
                    {
                      Header: 'Hedge Status',
                      accessor: 'hedge_status',
                    },
                    {
                      Header: 'Apex Status',
                      accessor: 'apex_status',
                    },
                    {
                      Header: 'Needs Attention',
                      accessor: 'needs_attention',
                    },
                    {
                      Header: ' ',
                      accessor: '1',
                    },
                  ]}
                />
              ) : (
                <h2>No Accounts Found</h2>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AccountsReconciliationDetails;
