/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import ReactTable from 'Screens/Components/ReactTable/ReactTable';
import GroupInvestingService from 'Services/GroupInvestingService';

export default function WithdrawalItemsTable({
  items,
  setWithdrawalItems,
  withdrawalId,
}) {
  const { data, isSuccess, refetch, isFetching } = useQuery({
    queryKey: ['withdrawalItems', withdrawalId],
    queryFn: () =>
      GroupInvestingService.getWithdrawalItemsByWithdrawalId(withdrawalId),
  });

  useEffect(() => {
    if (isSuccess) {
      setWithdrawalItems(data);
    }
  }, [isSuccess, data, setWithdrawalItems]);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'withdrawalitemid',
      },
      {
        Header: 'Withdrawal ID',
        accessor: 'withdrawalid',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'External ID',
        accessor: 'externalid',
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        Cell: ({ value }) => parseFloat(value).toFixed(4),
      },
      {
        Header: 'State at Initiation',
        accessor: 'stateatinitiation',
      },
      {
        Header: 'Withdrawal Item Status',
        accessor: 'withdrawalitemstatus',
      },
      {
        Header: 'Destination Acct',
        accessor: 'destinationaccount',
      },
      {
        Header: 'Source Acct',
        accessor: 'sourceaccount',
      },
      {
        Header: 'Request Date',
        accessor: 'requestdatetime',
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
          }),
      },
      {
        Header: 'Last Updated',
        accessor: 'lastupdateddatetime',
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            fractionalSecondDigits: 3,
          }),
      },
    ],
    []
  );

  const handleRefresh = () => {
    refetch();
  };

  return (
    <Card className="bg-dark text-white shadow-lg">
      <CardBody>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <CardTitle tag="h2" className="text-center mb-4">
            Withdrawal Items
          </CardTitle>
          <Button
            onClick={handleRefresh}
            color="primary"
            size="md"
            style={{
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i className="tim-icons icon-refresh-01" />
            <p className="mt-2 ml-2">Refresh</p>
            {isFetching && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
        </div>
        <ReactTable data={items} columns={columns} />
      </CardBody>
    </Card>
  );
}
