// src/Screens/Reconciliation/UnmatchedEvents.js
/* eslint-disable import/no-unresolved */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Row, Button, Col, CardHeader, Container,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query'; 
import { getAllDeadLetterQueues } from '../../Services/SqsQueueService';
import ReactTable from '../Components/ReactTable/ReactTable';

function SQSQueues() {
  const [data, setData] = useState([]);

  const { data: deadLetterQueuesData, isSuccess, refetch } = useQuery({
    queryKey: ['getAllDeadLetterQueues'],
    queryFn: () => getAllDeadLetterQueues(),    
});

  useEffect(() => {
    if (isSuccess) {
      const transformedData = Object.entries(deadLetterQueuesData).map(
        ([queueUrl, queueData], index) => ({
          id: index + 1,
          queueUrl,
          queueName: queueUrl.split('/').pop(),
          messageCount: queueData.count,
          sqsConsoleUrl: queueData.sqsConsoleUrl,
        })
      );
      setData(transformedData);
    }
  }, [isSuccess, deadLetterQueuesData]);

  function handleRefresh() {
    refetch();
  }

  return (
    <Container fluid className="content">
        <Col md={6} className="ml-auto mr-auto">
          <h2 className="text-center">Dead Letter Queue Status</h2>
        </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <Button
                    onClick={handleRefresh}
                    color="#000000"
                    size="sm"
                  >
                    <i className="tim-icons icon-refresh-01" />
                  </Button>
                </CardHeader>
                <CardBody>
                  {data && data.length > 0 ? (
                    <ReactTable
                      tableRoute="deadLetterQueues"
                      data={data}
                      filterable
                      resizable={false}
                      columns={[
                        {
                          Header: 'Queue Name',
                          accessor: 'queueName',
                          Cell: ({ row }) => (
                            <a
                              href={row.original.sqsConsoleUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:text-blue-700"
                            >
                              {row.original.queueName}
                            </a>
                          ),
                        },
                        {
                          Header: 'Message Count',
                          accessor: 'messageCount',
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    />
                  ) : (
                    <span>Loading...</span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
    </Container>
  );
}

export default SQSQueues;
