import AuthenticationService from './Auth.service';

const apiUrl = 'https://preprod-api.hedge.io';

export const sendGlobalAlert = async body => {
  const { idToken } = await AuthenticationService.getSession();
  const jwt = idToken.toString();
  const headers = `Bearer ${jwt}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: headers,
    },
    body: JSON.stringify(body),
  };
  const request = await fetch(`${apiUrl}/v1/admin/alerts/global`, options);
  const response = await request.json();
  if (response) {
    return response;
  }
  return null;
};
