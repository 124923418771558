/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
import {
  signIn,
  signOut,
  fetchAuthSession,
  fetchUserAttributes,
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
} from 'aws-amplify/auth';
import { logout } from 'store/Reducers/session';

class AuthenticationService {
  static getSession = async forceRefresh => {
    const { tokens, identityId, userSub } = await fetchAuthSession({
      forceRefresh: forceRefresh || false,
    });

    const { idToken, accessToken } = tokens;
    return {
      idToken,
      accessToken,
      identityId,
      userSub,
    };
  };

  static getUserAttributes = async () => {
    const attributes = await fetchUserAttributes();
    return attributes;
  };

  static handleSignIn = async ({ email, password }) => {
    const { isSignedIn, nextStep } = await signIn({
      username: email,
      password,
    });
    return { isSignedIn, nextStep };
  };

  static handleSignOut = async dispatch => {
    try {
      await signOut();
      dispatch(logout());
      localStorage.removeItem('session');
      localStorage.removeItem('lastPath');
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  };

  static enableTOTP = async () => {
    try {
      const totpSetupDetails = await setUpTOTP();
      const appName = `${process.env.REACT_APP_BUILD_ENV}-admin-dashboard`;
      const setupUri = totpSetupDetails.getSetupUri(appName);
      return setupUri;
    } catch (error) {
      console.error('Error enabling TOTP: ', error);
      throw error;
    }
  };

  static verifyTOTP = async totpCode => {
    try {
      await verifyTOTPSetup({ code: totpCode });
      await updateMFAPreference({ totp: 'PREFERRED' });
      console.log('TOTP verified and set as preferred MFA method');
    } catch (error) {
      console.error('Error verifying TOTP: ', error);
      throw error;
    }
  };
}

export default AuthenticationService;
